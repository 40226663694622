import React from 'react';
import styled from 'styled-components';
import { Button, StyledHR } from '../general/Common';
import { SHADOW_DARK, MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import { useSelector } from 'react-redux';
import { appConfigSelector } from '../../selectors/entities';
import { Formik } from 'formik';
import { validateSettings } from '../../utils/validators';
import { useConfigUpdateQuery } from '../../hooks/editors';
import deepEqual from 'deep-equal'
import { SettingsSidebar } from './SettingsSidebar';
import { ApplicationQuestionsEditor } from './ApplicationQuestionsEditor';
import { GeneralEditor } from './GeneralEditor';
import { ConfirmationEditor } from './ConfirmationEditor';
import { TermsAndConditionsEditor } from './TermsAndConditionsEditor';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const EditBar = styled.div`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 0px;
  background-color: rgba(245, 245, 245, 0.8);
  box-shadow: ${SHADOW_DARK};
`

export const EditBarContainer = styled.div`
  margin: 0px auto;
  display: flex;
  justify-content: flex-end;
  width: ${({ width }) => width || 800}px;
  width: min(${({ width }) => width || 800}px, 100%);
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 100%;
    width: min(${({ width }) => width || 800}px, 100%);
  } 
`

export const SettingsHeader = styled.h3`
  margin-top: 0px;
  padding-top: 30px;
  font-size: 22px;
`;

export const SettingsEditor = () => {
  const appConfig = useSelector(appConfigSelector);
  const [submit, submitting] = useConfigUpdateQuery();

  return (
    <Formik
        validate={validateSettings}
        initialValues={{...appConfig}}
        onSubmit={submit}
        enableReinitialize={true}>
        {({ handleSubmit, values, errors }) => {
          const fieldsAreTheSame = deepEqual(appConfig, values);
          return (
            <Wrapper>
              <SettingsSidebar />
              <div style={{ paddingBottom: '50px'}}>
                <GeneralEditor />
                <StyledHR margin="30px 0px 10px 0px" />
                <ApplicationQuestionsEditor />
                <StyledHR margin="30px 0px 10px 0px" />
                <ConfirmationEditor />
                <StyledHR margin="30px 0px 10px 0px" />
                <TermsAndConditionsEditor />
              </div>
              <EditBar>
                <EditBarContainer width={1000}>
                  <Button type="submit" onClick={handleSubmit} isLoading={submitting} disabled={fieldsAreTheSame}>
                    Save Changes
                  </Button>
                </EditBarContainer>
              </EditBar>
            </Wrapper>
          )}}
    </Formik>
  )
}