import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../general/Common'
import { Header, Subheader } from '../general/BeigeTile';
import { useExport } from '../../hooks/editors';
import { EXPORT_ALL, EXPORT_NEW, EXPORT_PREV } from '../../constants/general';
import { setExportModal } from '../../actions/modals';
import { toast } from 'react-toastify';

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  > input {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 20px;
  }
  margin-top: 10px;
  max-width: 400px;
`

const Text = styled.p`
  margin-top: 10px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ExportPage = () => {
  const [exportType, setExportType] = useState(EXPORT_NEW);
  const [getApps, getAppsLoading] = useExport(exportType);

  const onRadioButtonChange = (event) => {
    setExportType(event.target.value)
  }

  return (
    <Wrapper>
    <div style={{ width: '1000px'}}>

      <Header>
          Export
      </Header>
      <Text style={{ marginBottom: '15px' }}>
        Download applications and cases in CSV format.
      </Text>

      <RadioWrapper>
        <input
            type="radio"
            value={EXPORT_ALL}
            checked={exportType === EXPORT_ALL}
            onChange={onRadioButtonChange}
          />
        <label>
          All applications and cases
        </label>
      </RadioWrapper>
      <RadioWrapper>
         <input
            type="radio"
            value={EXPORT_PREV}
            checked={exportType === EXPORT_PREV}
            onChange={onRadioButtonChange}
          />
        <label>
          Previously exported applications and cases
        </label>
      </RadioWrapper>
      <RadioWrapper>
        <input
            type="radio"
            value={EXPORT_NEW}
            checked={exportType === EXPORT_NEW}
            onChange={onRadioButtonChange}
          />
        <label>
          New applications and cases
        </label>
      </RadioWrapper>
      <Button isLoading={getAppsLoading} style={{ marginTop: '20px '}} onClick={getApps}>
        Export
      </Button>
    </div>
    </Wrapper>
  )
}