import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { labelById, shortLabelById } from '../../constants/steps';
import { MAX_DESKTOP_WIDTH, PRIMARY, TEXT_PRIMARY } from '../../cssVars';
import { useColors } from '../../hooks/useColors';
import { useSteps } from '../../hooks/useSteps';

const CIRCLE_DIAM = 32;
const BAR_HEIGHT = 6;
const BAR_RADIUS = 3;

const Wrapper = styled.nav`
  display: flex;
  justify-content: center;
  width: 750px;
  width: min(750px, 95%);
  margin: 0px auto;
  margin-top: 30px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 90%;
    width: min(750px, 95%);
    margin-top: 15px;
  } 
`

const Section = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const StepCounter = styled.div`
  position: relative;
  width: 100%;
`

const Circle = styled.div`
  background-color: ${({ filled, primary }) => filled ? primary : '#fff' };
  border: 2px solid ${({ filled, primary, bgDarker }) => filled ? primary : bgDarker };
  box-sizing: border-box;
  color: ${({ filled, primary }) => filled ? '#fff' : primary };
  width: ${CIRCLE_DIAM}px;
  height: ${CIRCLE_DIAM}px;
  border-radius: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${({clickable}) => clickable ? 'pointer' : 'default' };
  z-index: 1;
  font-weight: 800;

  > div {
    position: relative;
    top: -1px;
  }
`

const StepLinkWrapper = styled.div`
  > a {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 800;
    color: ${({ color }) => color || PRIMARY}; 
    text-decoration: none;
    cursor: pointer; 
    &:hover {
      text-decoration: underline;
    } 
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      font-size: 14px;
      text-transform: capitalize;
    } 
  }
  > a:first-child { display: block; }
  > a:last-child { display: none; }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > a:first-child { display: none; }
    > a:last-child { display: block; }
  } 
`

const StepSpanWrapper = styled.div`
  > span {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 800;
    color: ${({ color }) => color}; 
    cursor: default;
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      font-size: 14px;
      text-transform: capitalize;
    } 
  }
  > span:first-child { display: block; }
  > span:last-child { display: none; }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > span:first-child { display: none; }
    > span:last-child { display: block; }
  }`

const Bar = styled.div`
  background-color: ${({ filled, primary, bgDarker }) => filled ? primary : bgDarker };
  position: absolute;
  width: 100%;
  width: calc(100% + 2px);
  left: -1px;
  height: 6px;
  top: calc((${CIRCLE_DIAM}px - ${BAR_HEIGHT}px) / 2);
  border-radius: ${({ index, numSteps }) => index === 0 ? `${BAR_RADIUS}px 0px 0px ${BAR_RADIUS}px` 
                                : index === (numSteps - 1) ? `0px ${BAR_RADIUS}px ${BAR_RADIUS}px 0px` 
                                : '0px'};
`

const Step = ({ label, index, filled, id, root }) => {
  const { stepIds } = useSteps(); 
  const { primary, primaryDark, bgDarker } = useColors();

  return (
    <Section>
      <StepCounter>
        <Circle primary={primary} clickable={false} bgDarker={bgDarker} filled={filled}>
          <div>{index + 1}</div>
        </Circle>
        <Bar numSteps={stepIds.length} primary={primary} bgDarker={bgDarker} filled={filled} index={index} />
      </StepCounter>
      <StepSpanWrapper color={TEXT_PRIMARY}>
        <span>{label}</span>
        <span>{shortLabelById[id]}</span>
      </StepSpanWrapper>
    </Section>
  )
}

// Display bubble step navigation if current path is part of listed ids
export const BubbleNavigation = ({ root }) => {
  const { stepIds } = useSteps(); 
  const { id: curId } = useParams();

  if (!stepIds.includes(curId)) {
    return null;
  }

  return (
    <Wrapper>
      {stepIds.map((id, index) => (
        <Step label={labelById[id]} index={index} root={root} filled={index <= stepIds.indexOf(curId)} id={id} key={id} />
      ))}
    </Wrapper>
  )
}
