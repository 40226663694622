import { createSelector } from "reselect";

export const modalsSelector = (state) => state.modals;

export const selectedCaseSelector = (state) => state.modals.selectedCase;

export const showDisclaimerSelector = (state) => state.modals.showDisclaimer;

export const showEligibilityDisclaimerSelector = (state) => state.modals.showEligibilityDisclaimer;

export const showIneligibleCasesSelector = (state) => state.modals.showIneligibleCases;

export const showEmailResultsSelector = (state) => state.modals.showEmailResults;

export const showEmailKnowYourRightsSelector = (state) => state.modals.showEmailKnowYourRights;

export const showSurveySelector = (state) => state.modals.showSurvey;

export const showVetsReemploymentPrgSelector = (state) => state.modals.showVetsReemploymentPrg;

export const showVetsTreatmentCrtSelector = (state) => state.modals.showVetsTreatmentCrt;

export const showNewUserSelector = (state) => state.modals.showNewUser;

export const selectedFollowupCaseSelector = (state) => state.modals.selectedFollowupCase;

export const showExportSelector = (state) => state.modals.showExport;

export const showHelpSelector = (state) => state.modals.showHelp;

export const anyModalIsActiveSelector = createSelector(
  [modalsSelector],
  (modals) => Object.values(modals).some(value => value === true)
);