import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { PRIMARY } from '../cssVars';
import { appConfigSelector } from '../selectors/entities';

function shadeHexColor(color, percent) {
  if (!color) {
    return null;
  }
  var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
  return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
}


export const useColors = (colorOverride) => {
  const appConfig = useSelector(appConfigSelector);
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const firstRoute = locationArray.length > 2 ? locationArray[2] : null;
  // if it's an admin route or login, use the CTRHC primary color, otherwise use the app's theme color
  const baseColor = firstRoute === 'admin' || firstRoute === 'login' ? PRIMARY : appConfig.themeColor

   // use the color override if provided, otherwise use the app's theme color
  const primaryColor = colorOverride || baseColor;

  return {
    primary: primaryColor,
    primaryDark: shadeHexColor(primaryColor, -0.4),
    bgLighter: shadeHexColor(primaryColor, 0.94),
    bgColor: shadeHexColor(primaryColor, 0.92),
    bgDarker: shadeHexColor(primaryColor, 0.65),
  };
}
