import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { AdminHeader } from '../general/Header';
import { useRequest } from 'redux-query-react';
import { statusIsGood } from '../../utils/helpers';
import { PageLoader } from '../general/Loaders';
import { ConfigEditor } from './ConfigEditor';
import { getConfigQueryAdmin } from '../../actions/queries';
import { SettingsEditor } from './SettingsEditor';
import { ExportPage } from './ExportPage';
import { UsersPage } from './UsersPage';
import { LookupPage } from './LookupPage';
import { LookupBySPNPage } from './LookupBySPNPage';
import SessionExpirationTimer from '../general/SessionExpirationTimer';
import { useSelector } from 'react-redux';
import { sessionExpirationTimeSelector } from '../../selectors/entities';

// Root component for all of the admin pages. 
// Redirects if token is invalid or does not exist
export const AdminRoot = () => {
  const { orgSlug } = useParams();
  const history = useHistory();
  const sessionExpirationTime = useSelector(sessionExpirationTimeSelector)
  const [{ isFinished, status }, refresh] = useRequest(getConfigQueryAdmin(orgSlug));

  useEffect(() => {
    if (status != null && status > 0 && !statusIsGood(status)) {
      if (sessionExpirationTime == null) {
        history.push(`/${orgSlug}/login`);
      } else if (sessionExpirationTime != null) {
        refresh();
      }
    }
    // eslint-disable-next-line
  }, [status, sessionExpirationTime]);

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <div style={{ backgroundColor: "#fff", minHeight: '100vh' }}>
      <Route path="/:orgSlug/admin/:routeId" component={AdminHeader} />
      <div style={{ padding: '20px 40px' }}>
        <Switch>
          <Route path="/:orgSlug/admin/configs" component={ConfigEditor} />
          <Route path="/:orgSlug/admin/settings" component={SettingsEditor} />
          <Route path="/:orgSlug/admin/export" component={ExportPage} />
          <Route path="/:orgSlug/admin/users" component={UsersPage} />
          <Route path="/:orgSlug/admin/quick-lookup-spn" component={LookupBySPNPage} />
          <Route path="/:orgSlug/admin/quick-lookup" component={LookupPage} />
          <Redirect to="/:orgSlug/admin/settings" />
        </Switch>
      </div>
      <SessionExpirationTimer />
    </div>
  )
}
