import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { longLabelById } from '../../constants/steps';
import { BeigeTile, Header } from '../general/BeigeTile';
import { Button } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import { BG_DARKER } from '../../cssVars';
import { appConfigSelector, casesWithFollowupResponsesSelector, demographicInfoSelector, expensesSelector, incomeAndAssetsSelector, otherCriminalHistorySelector, personalInfoSelector } from '../../selectors/entities';
import { getMonthFromIndex } from '../../utils/helpers';
import { useColors } from '../../hooks/useColors';
import { useSteps } from '../../hooks/useSteps';
import { useSubmitApplication } from '../../hooks/editors';
import { clearApplication } from '../../actions/clearStore';

const SpacedUL = styled.ul`
  list-style-type: none;
  padding: 0px;
  border: ${({ bordercolor, bgcolor }) => `2px solid ${bordercolor || bgcolor}`};
  border-radius: 6px;
  > li {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px;
    > div:first-child {
      padding-left: ${({withHeader}) => withHeader ? 20 : 0}px;
    }
    > div:last-child {
      max-width: 60%;
      text-align: right;
    }
  }
  > li:nth-child(odd) {
    background-color: ${({bgcolor}) => bgcolor || BG_DARKER};
  }
  > li:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  > li:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  margin-bottom: 20px;
`;

const EditLink = styled(Link)`
  color: ${({color}) => color};
  font-size: 16px;
  padding-left: ${({paddingLeft}) => paddingLeft}px;
`

const EditBtn = ({stepId, paddingLeft}) => {
  const {primary} = useColors();
  const {orgSlug} = useParams();
  return <EditLink paddingLeft={paddingLeft} color={primary} to={`/${orgSlug}/app/${stepId}`}>(edit)</EditLink>
}

const PersonalInfoList = () => {
  const { bgLighter } = useColors();
  const personalInfo = useSelector(personalInfoSelector);
  const demographicInfo = useSelector(demographicInfoSelector);
  const dob = new Date(personalInfo.dob);

  return (
    <SpacedUL bgcolor={bgLighter}>
      <li>
        <div>Legal Name</div>
        <div>{personalInfo.firstName} {personalInfo.middleName} {personalInfo.lastName} {personalInfo.suffix}</div>
      </li>

      {personalInfo.aliasFirstNames.length > 0 && 
        <li>
          <div>Other first names</div>
          <div>{personalInfo.aliasFirstNames.join(', ')}</div>
        </li>}
      {personalInfo.aliasLastNames.length > 0 && 
        <li>
          <div>Other last names</div>
          <div>{personalInfo.aliasLastNames.join(', ')}</div>
        </li>}
      
      <li>
        <div>Date of Birth</div>
        <div>{`${getMonthFromIndex(dob.getUTCMonth())} ${dob.getUTCDate()}, ${dob.getUTCFullYear()}`}</div>
      </li>

      {personalInfo.phone && <li>
        <div>Phone</div>
        <div>{personalInfo.phone}</div>
      </li>}

      {personalInfo.email && <li>
        <div>Email</div>
        <div>{personalInfo.email}</div>
      </li>}

      {personalInfo.streetAddress && <li>
        <div>Address</div>
        <div>{personalInfo.streetAddress}, {personalInfo.city}, {personalInfo.state} {personalInfo.zipcode}</div>
      </li>}

      {personalInfo.ssnFour && <li>
        <div>Last 4 Digits of Social Security Number</div>
        <div>{personalInfo.ssnFour}</div>
      </li>}

      {personalInfo.idStatus && <li>
        <div>License / ID Status</div>
        <div>{personalInfo.idStatus}</div>
      </li>}

      {personalInfo.idState && <li>
        <div>License / ID State</div>
        <div>{personalInfo.idState}</div>
      </li>}

      {personalInfo.idNumber && <li>
        <div>License / ID Number</div>
        <div>{personalInfo.idNumber}</div>
      </li>}

      {demographicInfo.gender && <li>
        <div>Gender</div>
        <div>{demographicInfo.gender} {demographicInfo.gender === 'Prefer to self-describe' && `(${demographicInfo.genderSelfDescribeInput})`}</div>
      </li>}

      {demographicInfo.race && <li>
        <div>Race/Ethnicity</div>
        <div>{demographicInfo.race} {demographicInfo.race === 'Other' && `(${demographicInfo.otherRaceInput})`}</div>
      </li>}

      {demographicInfo.specialCategories && demographicInfo.specialCategories.length > 0 && <li>
        <div>Special Categories</div>
        <div>{demographicInfo.specialCategories.join(', ')}</div>
      </li>}

      {demographicInfo.citizenship && <li>
        <div>Citizenship</div>
        <div>{demographicInfo.citizenship}</div>
      </li>}

      {demographicInfo.maritalStatus && <li>
        <div>Marital Status</div>
        <div>{demographicInfo.maritalStatus}</div>
      </li>}

      {demographicInfo.isVeteran && <li>
        <div>Veteran</div>
        <div>{demographicInfo.isVeteran === 'true' ? 'Yes' : 'No'}</div>
      </li>}

      {demographicInfo.referralSource && <li>
        <div>How did you hear about us?</div>
        <div>{demographicInfo.referralSource} {demographicInfo.referralSource === 'Other' && `(${demographicInfo.referralSourceOtherInput})`}</div>
      </li>}

      {personalInfo.applicationAssistant && <li>
        <div>Application Assistant</div>
        <div>{personalInfo.applicationAssistant}</div>
      </li>}

      {personalInfo.caseManagerContactName && <li>
        <div>Case Manager Name</div>
        <div>{personalInfo.caseManagerContactName}</div>
      </li>}

      {personalInfo.caseManagerContactPhone && <li>
        <div>Case Manager Phone</div>
        <div>{personalInfo.caseManagerContactPhone}</div>
      </li>}

      {personalInfo.caseManagerContactEmail && <li>
        <div>Case Manager Email</div>
        <div>{personalInfo.caseManagerContactEmail}</div>
      </li>}

    </SpacedUL>
  )
}

const IncomeAndAssetsList = () => {
  const appConfig = useSelector(appConfigSelector);
  const {bgLighter} = useColors();
  const incomeAndAssetsConfig = appConfig.incomeAndAssets;
  const incomeAndAssets = useSelector(incomeAndAssetsSelector);

  const getFieldVisibility = (fieldId) => incomeAndAssetsConfig && incomeAndAssetsConfig.fields[fieldId].visible;

  if (!incomeAndAssets) {
    return <p>This section has not been submitted yet</p>
  }

  return (
    <SpacedUL bgcolor={bgLighter}>
      {getFieldVisibility('dependents') && <li>
        <div>Dependents</div>
        <div>{incomeAndAssets.dependents.length === 0 ? 'None' : incomeAndAssets.dependents.map((dep) => (
          <div>
            {dep.name}, {dep.age}, {dep.relationship}
          </div>
        ))}</div>
      </li>}

      {getFieldVisibility('employmentStatus') && <li>
        <div>Employment Status</div>
        <div>{incomeAndAssets.employmentStatus}</div>
      </li>}

      {getFieldVisibility('wages') && <li>
        <div>Monthly work wages (applicant only)</div>
        <div>{incomeAndAssets.wages}</div>
      </li>}

      {getFieldVisibility('wagesHousehold') && <li>
        <div>Monthly work wages (household)</div>
        <div>{incomeAndAssets.wagesHousehold}</div>
      </li>}

      {getFieldVisibility('unemployment') && <li>
        <div>Monthly unemployment benefits (household)</div>
        <div>{incomeAndAssets.unemployment}</div>
      </li>}

      {getFieldVisibility('snap') && <li>
        <div>Food Stamps/SNAP</div>
        <div>{incomeAndAssets.snap}</div>
      </li>}

      {getFieldVisibility('retirement') && <li>
        <div>Retirement/Pension</div>
        <div>{incomeAndAssets.retirement}</div>
      </li>}

      {getFieldVisibility('publicHousingAssistance') && <li>
        <div>Public Housing Assistance</div>
        <div>{incomeAndAssets.publicHousingAssistance}</div>
      </li>}

      {getFieldVisibility('childSpousalSupport') && <li>
        <div>Child/Spousal Support</div>
        <div>{incomeAndAssets.childSpousalSupport}</div>
      </li>}

      {getFieldVisibility('ssi') && <li>
        <div>SSI/SSDI/VA Disability</div>
        <div>{incomeAndAssets.ssi}</div>
      </li>}

      {getFieldVisibility('ssi') && <li>
        <div>Owns more than one house</div>
        <div>{incomeAndAssets.hasAdditionalHome ? `Yes (${incomeAndAssets.homeValue})` : 'No'}</div>
      </li>}

      {getFieldVisibility('ssi') && <li>
        <div>Owns more than one vehicle</div>
        <div>{incomeAndAssets.hasAdditionalVehicle ? `Yes (${incomeAndAssets.vehicleValue})` : 'No'}</div>
      </li>}

      {getFieldVisibility('ssi') && <li>
        <div>Checking/savings accounts, stocks, bonds, other property </div>
        <div>{incomeAndAssets.hasOtherAssets ? `Yes (${incomeAndAssets.otherAssetsValue})` : 'No'}</div>
      </li>}

    </SpacedUL>
  )
}

const ExpensesList = () => {
  const appConfig = useSelector(appConfigSelector);
  const {bgLighter} = useColors();
  const expenses = useSelector(expensesSelector);
  const expensesConfig = appConfig.expenses;

  const getFieldVisibility = (fieldId) => expensesConfig && expensesConfig.fields[fieldId].visible;

  if (!expenses) {
    return <p>This section has not been submitted yet</p>
  }

  return (
    <SpacedUL bgcolor={bgLighter}>

      {getFieldVisibility('rent') && <li>
        <div>Mortgage/Rent</div>
        <div>{expenses.rent}</div>
      </li>}

      {getFieldVisibility('housingInfo') && <li>
        <div>Note about living situation</div>
        <div>{expenses.housingInfo}</div>
      </li>}

      {getFieldVisibility('utilities') && <li>
        <div>Utilities</div>
        <div>{expenses.utilities}</div>
      </li>}

      {getFieldVisibility('food') && <li>
        <div>Food (including SNAP)</div>
        <div>{expenses.food}</div>
      </li>}

      {getFieldVisibility('childCare') && <li>
        <div>Child Care</div>
        <div>{expenses.childCare}</div>
      </li>}

      {getFieldVisibility('childSpousalSupport') && <li>
        <div>Child/Spousal Support</div>
        <div>{expenses.childSpousalSupport}</div>
      </li>}

      {getFieldVisibility('automobileExpenses') && <li>
        <div>Car Payment/Auto Insurance</div>
        <div>{expenses.automobileExpenses}</div>
      </li>}

      {getFieldVisibility('transportationExpenses') && <li>
        <div>Gas/Bus Fare/Tolls</div>
        <div>{expenses.transportationExpenses}</div>
      </li>}

      {getFieldVisibility('attorneyFees') && <li>
        <div>Attorney's Fees</div>
        <div>{expenses.attorneyFees}</div>
      </li>}

      {getFieldVisibility('insurance') && <li>
        <div>Health/Life Insurance Premiums</div>
        <div>{expenses.insurance}</div>
      </li>}

      {getFieldVisibility('medical') && <li>
        <div>Medical Expenses</div>
        <div>{expenses.medical}</div>
      </li>}

      {getFieldVisibility('laundry') && <li>
        <div>Clothing/Laundry</div>
        <div>{expenses.laundry}</div>
      </li>}

      {getFieldVisibility('misc') && <li>
        <div>Other Misc. Personal Expenses</div>
        <div>{expenses.misc}</div>
      </li>}

      {getFieldVisibility('otherExpensesInfo') && <li>
        <div>Note about expenses</div>
        <div>{expenses.otherExpensesInfo}</div>
      </li>}

    </SpacedUL>
  )
}

const OtherCriminalHistoryList = () => {
  const {bgLighter} = useColors();
  const otherCriminalHistory = useSelector(otherCriminalHistorySelector);

  if (!otherCriminalHistory) {
    return <p>This section has not been submitted yet</p>
  }

  return (
    <SpacedUL bgcolor={bgLighter}>
      <li>
        <div>Required to register as sex offender</div>
        <div>{otherCriminalHistory.sexOffender ? 'Yes' : 'No'}</div>
      </li>
      <li>
        <div>Has criminal offenses outside of Texas</div>
        <div>{otherCriminalHistory.hasCrimeOutsideTexas ? 'Yes' : 'No'}</div>
      </li>
      <li>
        <div>Disqualifying offenses</div>
        <div>{otherCriminalHistory.disqualifyingOffenses.length === 0 
                ? 'None' 
                : otherCriminalHistory.disqualifyingOffenses.join(',')}
        </div>
      </li>
      {otherCriminalHistory.isVeteran != null && <li>
        <div>Is veteran</div>
        <div>{otherCriminalHistory.isVeteran ? 'Yes' : 'No'}</div>
      </li>}
      {otherCriminalHistory.completedVetReemploymentPrg != null && <li>
        <div>Completed a veteran's reemployment program</div>
        <div>{otherCriminalHistory.completedVetReemploymentPrg ? 'Yes' : 'No'}</div>
      </li>}
      {otherCriminalHistory.completedVetTreatmentCrt != null && <li>
        <div>Completed veteran's treatment court</div>
        <div>{otherCriminalHistory.completedVetTreatmentCrt ? 'Yes' : 'No'}</div>
      </li>}
      {otherCriminalHistory.hasBeenVictim != null && <li>
        <div>Has been a victim of trafficking/compelling prostitution</div>
        <div>{otherCriminalHistory.hasBeenVictim ? 'Yes' : 'No'}</div>
      </li>}
      {otherCriminalHistory.hasVictimCaseInHarrisCounty != null && <li>
        <div>Was victim of trafficking/compelling prostition in Harris County</div>
        <div>{otherCriminalHistory.hasVictimCaseInHarrisCounty ? 'Yes' : 'No'}</div>
      </li>}
      {otherCriminalHistory.assistedInvestigation != null && <li>
        <div>Assisted trafficking investigation, if applicable</div>
        <div>{otherCriminalHistory.assistedInvestigation ? 'Yes' : 'No'}</div>
      </li>}

    </SpacedUL>
  )
}

const CaseInformationList = () => {
  const {bgLighter, bgColor} = useColors();
  const casesWithFollowups = useSelector(casesWithFollowupResponsesSelector);
  const casesToShow = casesWithFollowups.filter((currCase) => currCase.final_eligibility != 'ineligible')
  if (casesToShow.length === 0) {
    return null;
  }

  return (
    <>
      <h3>Case Information</h3>
      {casesToShow.map((currCase, idx) => (
        <div key={currCase.casID}>
          <SpacedUL bgcolor={bgLighter} bordercolor={bgColor} withHeader>
            <li style={{ fontWeight: 700, backgroundColor: bgColor}}>
              <div style={{paddingLeft: '0px'}}>Case {idx + 1} <EditBtn paddingLeft={5} stepId={`criminalHistory/followups/${idx}`} /></div>
              <div>{currCase.current_offense_name} ({currCase.current_charge_level}), {currCase.fda}</div>
            </li>
            {currCase.completed_vet_reemployment_prg_for_case != null && <li>
              <div>Completed veteran's reemployment program for case</div>
              <div>{currCase.completed_vet_reemployment_prg_for_case ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.completed_vet_treatment_court_for_case != null && <li>
              <div>Completed veteran's treatment court for case</div>
              <div>{currCase.completed_vet_treatment_court_for_case ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.bac_under_015 != null && <li>
              <div>Blood alcohol content was below 0.15 at time of offense</div>
              <div>{currCase.bac_under_015 ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.had_commercial_license_or_permit != null && <li>
              <div>Held a commercial driver's license or permit at time of offense</div>
              <div>{currCase.had_commercial_license_or_permit ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.used_ignition_interlock_device != null && <li>
              <div>Used ignition interlock device on vehicle for 6+ months</div>
              <div>{currCase.used_ignition_interlock_device ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.used_ignition_interlock_device != null && <li>
              <div>Used ignition interlock device on vehicle for 6+ months</div>
              <div>{currCase.used_ignition_interlock_device ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.committed_solely_as_trafficking_victim != null && <li>
              <div>Committed offense solely as victim of trafficking/compelling prostitution</div>
              <div>{currCase.committed_solely_as_trafficking_victim ? 'Yes' : 'No'}</div>
            </li>}
            {currCase.commited_after_submitting_petition != null && <li>
              <div>Committed offense after submitting a petition for related offense</div>
              <div>{currCase.commited_after_submitting_petition ? 'Yes' : 'No'}</div>
            </li>}
          </SpacedUL>
        </div>
      ))}
    </>
  )
}

const CenteredContent = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Review = () => {
  const history = useHistory();
  const {orgSlug} = useParams();
  const {checkIfStepExists} = useSteps();
  const dispatch = useDispatch();
  const [submit, submitting] = useSubmitApplication(() => {
    dispatch(clearApplication());
    history.push(`/${orgSlug}/confirmation`)
  });

  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push(`/${orgSlug}/app/eligibility`)} />
        {longLabelById.review}
      </Header>
      <h3>Personal Info <EditBtn stepId={'personalInfo'} /></h3>
      <PersonalInfoList />
      {checkIfStepExists('incomeAndAssets') && <>
        <h3>Income and Assets <EditBtn stepId={'incomeAndAssets'} /></h3>
        <IncomeAndAssetsList />
      </>}
      {checkIfStepExists('expenses') && <>
        <h3>Expenses <EditBtn stepId={'expenses'} /></h3>
        <ExpensesList />
      </>}
      <h3>History <EditBtn stepId={'criminalHistory'} /></h3>
      <OtherCriminalHistoryList />
      <CaseInformationList />
      <Button style={{marginTop: '20px'}} isLoading={submitting} onClick={submit}>
        Submit
      </Button>
    </BeigeTile>
  )
}
