import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setVeteransReemploymentPrgModal, setVeteransTreatmentCrtModal } from '../../actions/modals';
import { showVetsReemploymentPrgSelector, showVetsTreatmentCrtSelector } from '../../selectors/modals';
import { Modal } from './Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
`

export const VetsTreatmentCourtModal = () => {
  const showModal = useSelector(showVetsTreatmentCrtSelector);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setVeteransTreatmentCrtModal(false))

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>What is a Veterans Treatment Court?</Title>
      <Text>
        A Veterans Treatment Court program is a rehabilitation program designed to address the unique difficulties of 
        justice-involved veterans who have served in combat and returned with unmet behavioral health needs.  
        If you are unsure if you participated in this type of program, contact the court your case was in.
      </Text>
    </Modal>
  )
}

export const VetsReemploymentPrgModal = () => {
  const showModal = useSelector(showVetsReemploymentPrgSelector);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setVeteransReemploymentPrgModal(false));

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>What is a Veterans Reemployment Program?</Title>
      <Text>
        A Veterans Reemployment Program is a a program that provides education and training to veterans 
        with the goal that the veterans obtain workforce skills and become gainfully employed.  
        If you are unsure if you participated in this type of program, contact the court your case was in.
      </Text>
    </Modal>
  )
}