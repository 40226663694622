import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setHelpModal } from '../../actions/modals';
import { BEACON_LINK, CTR_ISSUE_FORM } from '../../constants/links';
import { showHelpSelector } from '../../selectors/modals';
import { TextLink } from './Common';
import { Modal } from './Modal';
import blLogo from '../../assets/blLogo.png'
import ctrhcLogo from '../../assets/blackSheep/CTRHC.png';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
`

const BeaconLawLogo = styled.img`
  max-width: 220px;
  max-height: 70px;
`

const CTRHCLogo = styled.img`
  max-width: 220px;
  max-height: 80px;
`;

const LogosSection = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
`


// General disclaimer for the website
export const HelpModal = () => {

  const showModal = useSelector(showHelpSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setHelpModal(false));
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>

      <Title>Running into an issue?</Title>
      <Text>Please submit your problem through our <TextLink to={CTR_ISSUE_FORM}>issue reporting form</TextLink>.</Text>
      <Title>About this site</Title>
      <Text>
        This is a <b>Clear Their Record Harris County</b> application, powered by <TextLink to={BEACON_LINK}>The Beacon</TextLink> and 
        other supporting partners. 
      </Text>
      <LogosSection>
        <BeaconLawLogo src={blLogo} alt="Beacon Law logo" />
        <CTRHCLogo src={ctrhcLogo} alt="Clear Their Record Harris County logo" />
      </LogosSection>
    </Modal>
  )
}