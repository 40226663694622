import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getLookupEndDateQuery } from '../actions/queries';
import { LOOKUP_START_DATE } from '../constants/general';
import { lookupEndDateSelector } from '../selectors/entities';

const useDates = () => {
  useRequest(getLookupEndDateQuery());
  const lookupEndDate = useSelector(lookupEndDateSelector)

  return {
    startDate: LOOKUP_START_DATE,
    endDate: lookupEndDate,
  }
}

export default useDates;