import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ResultsEmail } from './ResultsEmail';
import { KnowYourRightsEmail } from './KnowYourRightsEmail';

export const EmailsRoot = () => {
  return (
    <Switch>
      <Route path="/:orgSlug/emails/results/:emailToken" component={ResultsEmail} />
      <Route path="/:orgSlug/emails/know-your-rights" component={KnowYourRightsEmail} />
    </Switch>
  )
}
