import { Parser } from 'json2csv';

// flattened fields and associated field labels used for csv export
// TODO: future improvement - look at organization configuration and remove fields that are set to false
export const appExportColumns = [
  { label: 'id', value: '_id' },
  { label: 'spn', value: 'spn' },
  { label: 'first_name', value: 'personalInfo.firstName' },
  { label: 'middle_name', value: 'personalInfo.middleName' },
  { label: 'last_name', value: 'personalInfo.lastName' },
  { label: 'suffix', value: 'personalInfo.suffix' },
  { label: 'alias_first_names', value: 'personalInfo.aliasFirstNames' },
  { label: 'alias_last_names', value: 'personalInfo.aliasLastNames' },
  { label: 'dob', value: 'personalInfo.dob' },
  { label: 'applicationAssistant', value: 'personalInfo.applicationAssistant' },
  { label: 'phone', value: 'personalInfo.phone' },
  { label: 'email', value: 'personalInfo.email' },
  { label: 'street_address', value: 'personalInfo.streetAddress' },
  { label: 'city', value: 'personalInfo.city' },
  { label: 'state', value: 'personalInfo.state' },
  { label: 'zipcode', value: 'personalInfo.zipcode' },
  { label: 'ssn_four', value: 'personalInfo.ssnFour' },
  { label: 'id_type', value: 'personalInfo.idStatus' },
  { label: 'id_state', value: 'personalInfo.idState' },
  { label: 'id_number', value: 'personalInfo.idNumber' },
  { label: 'case_manager_contact_name', value: 'personalInfo.caseManagerContactName' },
  { label: 'case_manager_contact_email', value: 'personalInfo.caseManagerContactEmail' },
  { label: 'case_manager_contact_phone', value: 'personalInfo.caseManagerContactPhone' },
  { label: 'crime_outside_harris_county', value: 'otherCriminalHistory.hasCrimeOutsideTexas' },
  { label: 'crime_before_1990', value: 'otherCriminalHistory.hasCrimeBefore1990' },
  { label: 'sex_offender', value: 'otherCriminalHistory.sexOffender' },
  { label: 'has_disqualifying_offense', value: 'otherCriminalHistory.hasDisqualifyingOffense' },
  { label: 'disqualifying_offenses', value: 'otherCriminalHistory.disqualifyingOffenses' },
  { label: 'veteran', value: 'otherCriminalHistory.isVeteran' },
  { label: 'completed_veteran_reemployment_program', value: 'otherCriminalHistory.completedVetReemploymentPrg' },
  { label: 'completed_veteran_treatment_court', value: 'otherCriminalHistory.completedVetTreatmentCrt' },
  { label: 'victim_prositution_or_trafficking', value: 'otherCriminalHistory.hasBeenVictim' },
  { label: 'victim_in_harris_county', value: 'otherCriminalHistory.hasVictimCaseInHarrisCounty' },
  { label: 'assisted_investigation_or_prosecution_if_requested', value: 'otherCriminalHistory.assistedInvestigation' },
  { label: 'dependents', value: 'incomeAndAssets.dependents' },
  { label: 'employment_status', value: 'incomeAndAssets.employmentStatus' },
  { label: 'applicant_wages', value: 'incomeAndAssets.wages' },
  { label: 'additional_household_wages', value: 'incomeAndAssets.wagesHousehold' },
  { label: 'unemployment', value: 'incomeAndAssets.unemployment' },
  { label: 'snap', value: 'incomeAndAssets.snap' },
  { label: 'retirement_pension', value: 'incomeAndAssets.retirement' },
  { label: 'public_housing_assistance', value: 'incomeAndAssets.publicHousingAssistance' },
  { label: 'child_or_spousal_support', value: 'incomeAndAssets.childSpousalSupport' },
  { label: 'ssi_ssdi_va_disability', value: 'incomeAndAssets.ssi' },
  { label: 'has_additional_vehicle', value: 'incomeAndAssets.hasAdditionalVehicle' },
  { label: 'additional_vehicle_value', value: 'incomeAndAssets.vehicleValue' },
  { label: 'has_additional_home', value: 'incomeAndAssets.hasAdditionalHome' },
  { label: 'additional_home_value', value: 'incomeAndAssets.homeValue' },
  { label: 'has_other_assets', value: 'incomeAndAssets.hasOtherAssets' },
  { label: 'other_assets_value', value: 'incomeAndAssets.otherAssetsValue' },
  { label: 'mortgage_rent', value: 'expenses.rent' },
  { label: 'housing_other_info', value: 'expenses.housingInfo' },
  { label: 'utilities', value: 'expenses.utilities' },
  { label: 'food', value: 'expenses.food' },
  { label: 'child_care', value: 'expenses.childCare' },
  { label: 'child_or_spousal_support_payments', value: 'expenses.childSpousalSupport' },
  { label: 'car_payment_auto_insurance', value: 'expenses.automobileExpenses' },
  { label: 'gas_bus_far_tolls', value: 'expenses.transportationExpenses' },
  { label: 'attorney_fees', value: 'expenses.attorneyFees' },
  { label: 'health_or_life_insurance', value: 'expenses.insurance' },
  { label: 'medical_expenses', value: 'expenses.medical' },
  { label: 'clothing_laundry', value: 'expenses.laundry' },
  { label: 'misc_expenses', value: 'expenses.misc' },
  { label: 'expenses_other_info', value: 'expenses.otherExpensesInfo' },
  { label: 'age', value: 'demographicInfo.age' },
  { label: 'gender', value: 'demographicInfo.gender' },
  { label: 'gender_other', value: 'demographicInfo.genderSelfDescribeInput' },
  { label: 'race', value: 'demographicInfo.race' },
  { label: 'race_other', value: 'demographicInfo.otherRaceInput' },
  { label: 'citizenship', value: 'demographicInfo.citizenship' },
  { label: 'marital_status', value: 'demographicInfo.maritalStatus' },
  { label: 'veteran_demographic', value: 'demographicInfo.isVeteran' },
  { label: 'referral_source', value: 'demographicInfo.referralSource' },
  { label: 'referral_source_other', value: 'demographicInfo.referralSourceOtherInput' },
  { label: 'special_categories', value: 'demographicInfo.specialCategories' },
  { label: 'agreed_to_terms', value: 'agreedToTerms' },
  { label: 'submitted', value: 'agreedAndSubmitted' },
  { label: 'date_submitted', value: 'dateSubmitted' },
  { label: 'exported', value: 'isExported' },
  { label: 'date_exported', value: 'dateExported' },
  { label: 'case_ids', value: 'hcdcCases' },
]

export const caseExportColumns = [
  { label: 'app_id', value: 'appID' },
  { label: 'matter_id', value: 'matterID' },
  { label: 'case_ids', value: 'caseIDs' },
  { label: 'case_numbers', value: 'caseNumbers' },
  { label: 'eligibility', value: 'eligibility' },
]

export const generateCsv = (fields, data) => {
    const json2csv = new Parser({ fields });
    const csv = json2csv.parse(data)
    return csv
}