export const monthOptions = [
  {value: '', label:'Select month'},
  {value:'1', label:'January'},
  {value:'2', label:'February'},
  {value:'3', label:'March'},
  {value:'4', label:'April'},
  {value:'5', label:'May'},
  {value:'6', label:'June'},
  {value:'7', label:'July'},
  {value:'8', label:'August'},
  {value:'9', label:'September'},
  {value:'10', label:'October'},
  {value:'11', label:'November'},
  {value:'12', label:'December'},
]

// personal info

export const suffixOptions = [
  {value:'', label:''},
  {value:'Jr', label:'Jr'},
  {value:'Sr', label:'Sr'},
  {value:'II', label:'II'},
  {value:'III', label:'III'},
  {value:'IV', label:'IV'} // TELL KELSEY - this was set to value "III"
]

export const ageOptions = [
  {value:"17 years old or younger", label:"17 years old or younger"},
  {value:"18-24", label:"18-24"},
  {value:"25-34", label:"25-34"},
  {value:"35-44", label:"35-44"},
  {value:"45-64", label:"64"},
  {value:"65 years old or older", label:"65 years old or older"},
]

export const genderOptions = [
  {value:'Male', label:'Male'},
  {value:'Female', label:'Female'},
  {value:'Nonbinary', label:'Nonbinary'},
  {value:'Prefer to self-describe', label:'Prefer to self-describe'},
  {value:'Prefer not to say', label:'Prefer not to say'},
]

export const raceOptions = [
  {value:'American Indian or Alaskan Native', label: 'American Indian or Alaskan Native'},
  {value:'Asian', label:'Asian'},
  {value:'Black or African American', label:'Black or African American'},
  {value:'Hispanic or Latino/a/x', label:'Hispanic or Latino/a/x'},
  {value: 'Middle Eastern or Northern African', label: 'Middle Eastern or Northern African'},
  {value:'Non-Hispanic White', label:'Non-Hispanic White'},
  {value:'Pacific Islander', label:'Pacific Islander'},
  {value:'Other', label:'Other'}
]

// Survey options

export const helpfulnessOptions = [
  {value: "Very helpful", label:"Very helpful"},
  {value: "Moderately helpful", label:"Moderately helpful"},
  {value: "Slightly helpful", label:"Slightly helpful"},
  {value: "Not helpful", label:"Not helpful"},
]

export const didYouFindWhatYouNeededOptions = [
  {value: 'Yes, I found all of the information I needed', label: 'Yes, I found all of the information I needed'},
  {value: 'I only found some of the information I needed', label: 'I only found some of the information I needed'},
  {value: 'No, I did not find the information I needed', label: 'No, I did not find the information I needed'},
]

export const howEasyWasInfoToFindOptions = [
  {value: 'Very easy to find', label: 'Very easy to find'},
  {value: 'Easy to find', label: 'Easy to find'},
  {value: 'Neither easy nor hard to find', label: 'Neither easy nor hard to find'},
  {value: 'Hard to find', label: 'Hard to find'},
  {value: 'Very hard to find', label: 'Very hard to find'},
  {value: "I didn't find what I was looking for", label: "I didn't find what I was looking for"},
]

export const whereDidYouAccessWebsiteOptions = [
  {value: "At a friend or family member's house", label: "At a friend or family member's house"},
  {value: "At a self help center", label: "At a self help center"},
  {value: "At home", label: "At home"},
  {value: "At the library", label: "At the library"},
  {value: "At work", label: "At work"},
  {value: "On my phone", label: "On my phone"},
  {value: "Other", label: "Other"},
]

export const additionalInfoOptions = [
  {value: "I have a disability", label: "I have a disability"},
  {value: "I am a full time student", label: "I am a full time student"},
  {value: "I am using this website to help someone else", label: "I am using this website to help someone else"},
  {value: "I am responsible for at least one minor child", label: "I am responsible for at least one minor child"},
  {value: "I get or applied to get food stamps within the last year", label: "I get or applied to get food stamps within the last year"},
  {value: "I have been unable to pay all of my bills in the last year", label: "I have been unable to pay all of my bills in the last year"},
  {value: "If I needed to, I don't think I could afford a lawyer", label: "If I needed to, I don't think I could afford a lawyer"},
  {value: "I am unemployed or have been unemployed in the last year", label: "I am unemployed or have been unemployed in the last year"},
  {value: "I am a victim and/or survivor of domestic violence", label: "I am a victim and/or survivor of domestic violence"},
  {value: "I am a victim and/or survivor of sexual assault", label: "I am a victim and/or survivor of sexual assault"},
  {value: "I am a veteran", label: "I am a veteran"},
  {value: "None of the above", label: "None of the above"},
]

export const didYouCompleteTheFormsOptions = [
  {value:"Yes", label:"Yes"},
  {value:"No", label:"No"},
  {value:"Not yet, but I plan to", label:"Not yet, but I plan to"},
]

export const petitionFormOptions = [
  {value:"411.072", label:"411.072"},
  {value:"411.0725", label:"411.0725"},
  {value:"411.0726", label:"411.0726"},
  {value:"411.0727", label:"411.0727"},
  {value:"411.0728", label:"411.0728"},
  {value:"411.0729", label:"411.0729"},
  {value:"411.03", label:"411.073"},
  {value:"411.031", label:"411.0731"},
  {value:"411.035", label:"411.0735"},
  {value:"411.036", label:"411.0736"},
  {value:"I filled out multiple", label:"I filled out multiple"},
]

export const timeToCompleteFormsOptions = [
  {value:"Less than 30 minutes", label:"Less than 30 minutes"},
  {value:"Between 30 minutes and 1 hour", label:"Between 30 minutes and 1 hour"},
  {value:"1-2 hours", label:"1-2 hours"},
  {value:"2-4 hours", label:"2-4 hours"},
  {value:"Over 4 hours", label:"Over 4 hours"},
]

export const howUserHeardAboutSiteOptions = [
  {value:"Friend", label:"Friend"},
  {value:"Social media", label:"Social media"},
  {value:"The Beacon", label:"The Beacon"},
  {value:"Other", label:"Other"},
]

export const educationLevelOptions = [
  {value:"Less than a high school degree", label: "Less than a high school degree"},
  {value:"High school degree or GED", label: "High school degree or GED"},
  {value:"Some college credit", label: "Some college credit"},
  {value:"Associate's degree", label: "Associate's degree"},
  {value:"Bachelor's degree", label: "Bachelor's degree"},
  {value:"Master's, professional, and/or doctorate degree", label: "Master's, professional, and/or doctorate degree"},
  {value:"Prefer not to say", label: "Prefer not to say"},
]

export const incomeRangeOptions = [
  {value:"Less than $20,000", label: "Less than $20,000"},
  {value:"$20,000 - $44,999", label: "$20,000 - $44,999"},
  {value:"$45,000 - $59,999", label: "$45,000 - $59,999",},
  {value:"$60,000 - $139,999", label: "$60,000 - $139,999"},
  {value:"$140,000 or more", label: "$140,000 or more"},
  {value:"Prefer not to say", label: "Prefer not to say"},
]

export const stateOptionsFull = [
  {label: 'Alabama', value: 'Alabama'},
  {label: 'Alaska', value: 'Alaska'},
  {label: 'Arizona', value: 'Arizona'},
  {label: 'Arkansas', value: 'Arkansas' },
  {label: 'California', value: 'California'},
  {label: 'Colorado', value: 'Colorado'},
  {label: 'Connecticut', value: 'Connecticut'},
  {label: 'Delaware', value: 'Delaware'},
  {label: 'Florida', value: 'Florida'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Hawaii', value: 'Hawaii'},
  {label: 'Idaho', value: 'Idaho'},
  {label: 'Illinois', value: 'Illinois'},
  {label: 'Indiana', value: 'Indiana'},
  {label: 'Kansas', value: 'Kansas'},
  {label: 'Louisiana', value: 'Louisiana'},
  {label: 'Maine', value: 'Maine'},
  {label: 'Maryland', value: 'Maryland'},
  {label: 'Massachusetts', value: 'Massachusetts'},
  {label: 'Michigan', value: 'Michigan'},
  {label: 'Minnesota', value: 'Minnesota'},
  {label: 'Mississippi', value: 'Mississippi'},
  {label: 'Missouri', value: 'Missouri'},
  {label: 'Montana', value: 'Montana'},
  {label: 'Nebraska', value: 'Nebraska'},
  {label: 'Nevada', value: 'Nevada'},
  {label: 'New Hampshire', value: 'New Hampshire'},
  {label: 'New Jersey', value: 'New Jersey'},
  {label: 'New Mexico', value: 'New Mexico'},
  {label: 'New York', value: 'New York'},
  {label: 'North Carolina', value: 'North Carolina'},
  {label: 'North Dakota', value: 'North Dakota'},
  {label: 'Ohio', value: 'Ohio'},
  {label: 'Oklahoma', value: 'Oklahoma'},
  {label: 'Oregon', value: 'Oregon'},
  {label: 'Pennsylvania', value: 'Pennsylvania'},
  {label: 'Rhode Island', value: 'Rhode Island'},
  {label: 'South Carolina', value: 'South Carolina'},
  {label: 'South Dakota', value: 'South Dakota'},
  {label: 'Tennessee', value: 'Tennessee'},
  {label: 'Texas', value: 'Texas'},
  {label: 'Utah', value: 'Utah'},
  {label: 'Vermont', value: 'Vermont'},
  {label: 'Virginia', value: 'Virginia'},
  {label: 'Washington', value: 'Washington'},
  {label: 'West Virginia', value: 'West Virginia'},
  {label: 'Wisconsin', value: 'Wisconsin'},
  {label: 'Wyoming', value: 'Wyoming'},
  {label: 'American Samoa', value: 'American Samoa'},
  {label: 'District of Columbia', value: 'District of Columbia'},
  {label: 'Federated States of Micronesia', value: 'Federated States of Micronesia'},
  {label: 'Guam', value: 'Guam'},
  {label: 'Marshall Islands', value: 'Marshall Islands'},
  {label: 'Northern Mariana Islands', value: 'Northern Mariana Islands'},
  {label: 'Palau', value: 'Palau'},
  {label: 'Puerto Rico', value: 'Puerto Rico'},
  {label: 'Virgin Islands', value: 'Virgin Islands'}
];

export const citizenshipOptions = [
  {value:'Yes', label:'Yes'},
  {value:'No', label:'No'},
  {value:'Decline to answer', label:'Decline to answer'},
]

export const CURRENTLY_HAS_ID = "I have a current driver's license or state ID";
export const PREVIOUSLY_HAD_ID = "I previously had state issued identification but I do not know the number";
export const idOptions = [
  {value:CURRENTLY_HAS_ID, label:CURRENTLY_HAS_ID},
  {value:PREVIOUSLY_HAD_ID, label:PREVIOUSLY_HAD_ID},
  {value:"I have never had any form of state issued identification", label:"I have never had any form of state issued identification"},
]

export const maritalStatusOptions = [
  {value:'Single', label:'Single'},
  {value:'Married', label:'Married'},
  {value:'Divorced', label:'Divorced'},
  {value:'Widowed', label:'Widowed'}
]

export const employmentStatusOptions = [
  {value:'Full Time Employed', label:'Full Time Employed'},
  {value:'Part Time Employed', label:'Part Time Employed'},
  {value:'Unemployed', label:'Unemployed'},
  {value:'Disabled', label:'Disabled'},
  {value:'Retired', label:'Retired'},
  {value:'Full Time Student', label:'Full Time Student'}
]

export const yesNoOptions = [
  {value: true, label: "Yes"},
  {value: false, label: "No"}
]