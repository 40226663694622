import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { ParagraphTextField } from '../general/FormFields';
import { SettingsHeader } from './SettingsEditor';

const Description = styled.p`
  margin-top: 10px;
`

export const ConfirmationEditor = () => {
  const { errors } = useFormikContext();
  return (
    <div>
      <SettingsHeader id="confirmation">Confirmation Text</SettingsHeader>
      <Description>
        Text shown to the user after their application is submitted.
      </Description>
      <ParagraphTextField error={errors.confirmationText} name="confirmationText" />
    </div>
  )
}