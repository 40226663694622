import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import { useParams } from 'react-router-dom';
import { TEXT_PRIMARY, MAX_DESKTOP_WIDTH } from '../../cssVars';
import { useColors } from '../../hooks/useColors';

const OuterWrapper = styled.div`
  width: 240px;
  min-width: 240px;
  margin-top: 20px;
  margin-right: 30px;
  min-height: 200px;
  display: block;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: none;
  } 
`

const Wrapper = styled.div`
  border: 1px solid grey;
  border-radius: 6px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: ${({ stick }) => stick ? '35px' : '0px'};
  position: ${({ stick }) => stick ? 'fixed' : 'relative'};
`

const HIGHLIGHT_WIDTH = 5;

const Entry = styled(Link)`
  border-bottom: 1px solid lightgrey;
  border-left: ${({hightlight, primary}) => hightlight ? `${HIGHLIGHT_WIDTH}px solid ${primary}` : 'none'};
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  padding-left: ${({hightlight}) => hightlight ? 15 - HIGHLIGHT_WIDTH : 15}px;
  text-decoration: none;
  color: ${TEXT_PRIMARY};
`

const EntryHeader = styled(Entry)`
  font-weight: 700;
  color: ${TEXT_PRIMARY};
`

const NestedEntry = styled(Entry)`
  padding-left: ${({hightlight}) => hightlight ? 30 - HIGHLIGHT_WIDTH : 30}px;
`

const POSITION_TO_STICK_SIDENAV = 60;

const getTopOffset = id => document.getElementById(id) ? document.getElementById(id).getBoundingClientRect().top - 1 : 1;

const sectionsInOrder = [
  'terms-and-conditions', 'confirmation', 'expenses-page', 
  'income-assets-page', 'personal-info-page',
  'app-questions', 'general'];

const getActiveSection = () => {
  const lowestSectionInView = sectionsInOrder.find(id => getTopOffset(id) < 0);
  return lowestSectionInView || 'general';
}

export const SettingsSidebar = () => {
  const { primary } = useColors();
  const { orgSlug } = useParams();
  const [position, setPosition] = useState(0);
  const handleScroll = () => {
    const topPosition = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    setPosition(topPosition);
  };
  const hightlightSection = getActiveSection();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <OuterWrapper>
      <div />
      <Wrapper stick={position > POSITION_TO_STICK_SIDENAV}>
        <EntryHeader to={`/${orgSlug}/admin/settings#top`}>
          Application
        </EntryHeader>
        <Entry hightlight={hightlightSection === 'general'?1:0} primary={primary} to={`/${orgSlug}/admin/settings#general`}>
          General Settings
        </Entry>
        <Entry hightlight={hightlightSection === 'app-questions'?1:0} primary={primary} to={`/${orgSlug}/admin/settings#app-questions`}>
          Application Questions
        </Entry>
        <NestedEntry hightlight={hightlightSection === 'personal-info-page'?1:0} primary={primary} 
                     to={`/${orgSlug}/admin/settings#personal-info-page`}>
          Personal Info Page
        </NestedEntry>
        <NestedEntry hightlight={hightlightSection === 'income-assets-page'?1:0} primary={primary} 
                     to={`/${orgSlug}/admin/settings#income-assets-page`}>
          Income & Assets Page
        </NestedEntry>
        <NestedEntry hightlight={hightlightSection === 'expenses-page'?1:0} primary={primary} 
                     to={`/${orgSlug}/admin/settings#expenses-page`}>
          Expenses Page
        </NestedEntry>
        <Entry hightlight={hightlightSection === 'confirmation'?1:0} primary={primary}  to={`/${orgSlug}/admin/settings#confirmation`}>
          Confirmation Text
        </Entry>
        <Entry hightlight={hightlightSection === 'terms-and-conditions'?1:0} primary={primary}  
               style={{ borderBottom: 'none' }} to={`/${orgSlug}/admin/settings#terms-and-conditions`}>
          Terms and Conditions
        </Entry>
      </Wrapper>
    </OuterWrapper>
  )
}