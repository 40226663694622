import React from 'react';
import styled from 'styled-components';
import { MAIN_CONTENT_WIDTH, MAX_DESKTOP_WIDTH, SHADOW_CRISP, SHADOW_DARK, SHADOW_LIGHT } from '../../cssVars.js';
import { Link, useParams } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout.js';
import { useColors } from '../../hooks/useColors.js';
import { orgInfoSelector } from '../../selectors/entities.js';
import { useDispatch, useSelector } from 'react-redux';
import ctrhcLogoSmall from '../../assets/blackSheep/smallCTRHC.png';
import { setHelpModal } from '../../actions/modals.js';

const Wrapper = styled.div`
  height: 5px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    height: 80px;
  }
`;

const OrgLogo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 200px;
  max-height: 100px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    max-width: 120px;
    max-height: 70px;
  }
`;

const QuestionButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 100%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: grey;
  font-weight: 800;
  font-size: 24px;
  box-shadow: ${SHADOW_LIGHT};
  transition: all 0.2s ease;
  &:hover {
    box-shadow: ${SHADOW_DARK};
  }
`

// Header for application, including org and CTRHC logos
export const Header = () => {
  const orgInfo = useSelector(orgInfoSelector);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      {orgInfo.logoUrl && <OrgLogo src={orgInfo.logoUrl} alt="organization's logo" />}
      <QuestionButton onClick={() => dispatch(setHelpModal(true))}>
        ?
      </QuestionButton>
    </Wrapper>
  )
}

const BoldLink = styled(Link)`
  color: #fff;
  font-weight: 800;
  margin-left: 20px;
  text-decoration: none;
  border-bottom: ${({ active }) => active ? `2px solid white` : 'none'};
  margin-bottom: ${({ active }) => active ? -2 : 0}px;
  border-radius: 0px 0px 1px 1px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 15px;
    margin-left: 10px;
  }
`

const AdminWrapper = styled.div`
  width: 100%;
  padding: 15px 0px;
  box-shadow: ${SHADOW_CRISP};
  background-color: #05263A;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LogoutButton = styled.button`
  color: #fff;
  font-weight: 800;
  border: 0px;
  margin-right: 20px;
  background-color: transparent;
  margin-left: auto;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 15px;
    margin-right: 10px;
  }
`;

const SmallLogo = styled.img`
  max-width: 45px;
  max-height: 45px;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: -10px;
  margin-bottom: -10px;
  display: block;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    display: none;
  } 
`;

export const AdminHeader = () => {
  const { primaryDark } = useColors();
  const { orgSlug, routeId } = useParams();
  const logout = useLogout();
  const exportActive = routeId === 'export' ? 1 : 0;
  const settingsActive = routeId === 'settings' ? 1 : 0;
  const usersActive = routeId === 'users' ? 1 : 0;
  const lookupActive = routeId === 'quick-lookup' ? 1 : 0;
  const lookupBySPNActive = routeId === 'quick-lookup-spn' ? 1 : 0;

  return (
    <AdminWrapper primaryDark={primaryDark}>
      <SmallLogo src={ctrhcLogoSmall} alt="Clear Their Record Harris County" />
      <BoldLink to={`/${orgSlug}/admin/settings`} active={settingsActive}>
        Application
      </BoldLink>
      <BoldLink to={`/${orgSlug}/admin/users`} active={usersActive}>
        Users
      </BoldLink>
      <BoldLink to={`/${orgSlug}/admin/quick-lookup`} active={lookupActive}>
        Name/DOB Lookup
      </BoldLink>
      <BoldLink to={`/${orgSlug}/admin/quick-lookup-spn`} active={lookupBySPNActive}>
        SPN Lookup
      </BoldLink>
      <BoldLink to={`/${orgSlug}/admin/export`} active={exportActive}>
        Export
      </BoldLink>
      <LogoutButton onClick={logout}>Log out</LogoutButton>
    </AdminWrapper>
  )
}
