export const SET_CASE_MODAL = 'SET_CASE_MODAL';
export const SET_FOLLOWUP_CASE_MODAL = 'SET_FOLLOWUP_CASE_MODAL';
export const SET_INELIGIBLE_CASES_MODAL = 'SET_INELIGIBLE_CASES_MODAL';
export const SET_DISCLAIMER_MODAL = 'SET_DISCLAIMER_MODAL';
export const SET_ELIGIBILITY_DISCLAIMER_MODAL = 'SET_ELIGIBILITY_DISCLAIMER_MODAL';
export const SET_EMAIL_RESULTS_MODAL = 'SET_EMAIL_RESULTS_MODAL';
export const SET_EMAIL_PETITIONS_MODAL = 'SET_EMAIL_PETITIONS_MODAL';
export const SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL = 'SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL';
export const SET_OTHER_NAMES_MODAL = 'SET_OTHER_NAMES_MODAL';
export const SET_SURVEY_MODAL = 'SET_SURVEY_MODAL';
export const SET_VETS_REEMPLOYMENT_MODAL = 'SET_VETS_REEMPLOYMENT_MODAL';
export const SET_VETS_TREATMENT_CRT_MODAL = 'SET_VETS_TREATMENT_CRT_MODAL';
export const SET_HELP_MODAL = 'SET_HELP_MODAL';

export const SET_NEW_USER_MODAL = 'SET_NEW_USER_MODAL';
export const SET_EXPORT_MODAL = 'SET_EXPORT_MODAL';

// Shows case details modal for `selectedCase` if non-null
export const setCase = (selectedCase) => {
  return { type: SET_CASE_MODAL, payload: selectedCase };
}

// Shows "ineligible cases" modal
export const setIneligibleCasesModal = (showIneligibleCases) => {
  return { type: SET_INELIGIBLE_CASES_MODAL, payload: showIneligibleCases };
}

export const setEligibilityDisclaimerModal = (showDisclaimer) => {
  return { type: SET_ELIGIBILITY_DISCLAIMER_MODAL, payload: showDisclaimer };
}

export const setDisclaimerModal = (showDisclaimer) => {
  return { type: SET_DISCLAIMER_MODAL, payload: showDisclaimer };
}

export const setEmailResultsModal = (showEmailResultsModal) => {
  return { type: SET_EMAIL_RESULTS_MODAL, payload: showEmailResultsModal };
}

export const setEmailKnowYourRightsModal = (setEmailKnowYourRightsModal) => {
  return { type: SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL, payload: setEmailKnowYourRightsModal };
}

export const setSurveyModal = (showSurvey) => {
  return { type: SET_SURVEY_MODAL, payload: showSurvey };
}

export const setVeteransReemploymentPrgModal = (showModal) => {
  return { type: SET_VETS_REEMPLOYMENT_MODAL, payload: showModal };
}

export const setVeteransTreatmentCrtModal = (showModal) => {
  return { type: SET_VETS_TREATMENT_CRT_MODAL, payload: showModal };
}

export const setNewUserModal = (showModal) => {
  return { type: SET_NEW_USER_MODAL, payload: showModal };
}

export const setFollowupCase = (caseToShow) => {
  return { type: SET_FOLLOWUP_CASE_MODAL, payload: caseToShow };
}

export const setExportModal = (exportType) => {
  return { type: SET_EXPORT_MODAL, payload: exportType };
}

export const setHelpModal = (showModal) => {
  return { type: SET_HELP_MODAL, payload: showModal };
}