import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { TextField } from '../general/FormFields.js';
import { Button, StyledHR } from '../general/Common';
import { validateSPNPage } from '../../utils/validators.js';
import { useQuickLookupBySPN } from '../../hooks/editors.js';
import { Header, Subheader } from '../general/BeigeTile.js';
import { useDispatch, useSelector } from 'react-redux';
import { EditBar, EditBarContainer } from './SettingsEditor.js';
import { allCasesSelector } from '../../selectors/entities.js';
import { QuickLookupCaseCard } from '../general/CaseCard.js';
import { setFollowupCase } from '../../actions/modals.js';
import { NoCasesFoundInitContent, SearchRangeNote } from '../application/NoCasesFound';
import retryImg from '../../assets/retry.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

const Text = styled.p`
  margin: 10px 0px;
`;

const RetryIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 9px;
`

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const LookupBySPNPage = () => {
  const dispatch = useDispatch();
  const validate = validateSPNPage;
  const allCases = useSelector(allCasesSelector);
  const [searchComplete, setSearchComplete] = useState(false);
  const [submit, submitting] = useQuickLookupBySPN((casesFound) => {
    setSearchComplete(true);
  });

  return (
      <Formik
        validate={validate}
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          dob: null,
          aliasFirstNames: [],
          aliasLastNames: [],
        }}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          // return error if field is touched
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <Wrapper>
                <div style={{ width: '1000px'}}>
                  <Header>
                    SPN Lookup
                  </Header>

                  <Text>
                    Use this search to get an initial estimate of nondisclosure / expunction eligibility for a client's Harris County cases, using the client's SPN number.
                  </Text>

                  <StyledHR />

                  {!searchComplete ? 
                    <div>
                      <Subheader>SPN</Subheader>
                      <TextField placeholder="client's SPN number" name="spn" error={getError('spn')} />
                    </div>
                  : allCases && allCases.length > 0 ?
                    <>
                      <Subheader>Case Results (SPN: {allCases[0].def_spn})</Subheader>
                        {allCases.map((currCase, index) => {
                          const clickCase = () => dispatch(setFollowupCase(currCase));
                          return (
                            <QuickLookupCaseCard followups={true} onClick={clickCase} key={index} caseInfo={currCase} />
                          )
                        })}
                    </>
                  : <div>
                      We weren't able to find any cases matching the SPN <b>{values.spn}</b>. <SearchRangeNote />
                    </div>
                  }
                  </div>
                </Wrapper>

                <EditBar>
                    <EditBarContainer width={1000}>
                      {searchComplete 
                        ? <Button style={{minWidth: '250px', marginBottom: '0px'}} type="button" onClick={() => setSearchComplete(false)}>
                            Reset search
                            <RetryIcon src={retryImg} alt="circular retry arrow" /> 
                          </Button>
                        : <Button fillOnMobile={true} type="submit" isLoading={submitting} onClick={handleSubmit}>Search for cases</Button>
                      }
                    </EditBarContainer>
                </EditBar>
            </>
          )}}
    </Formik> 
  )
}
