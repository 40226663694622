require('dotenv').config()

export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const ENV = process.env.REACT_APP_ENV;

export const LOOKUP_START_DATE = 'January 1st, 1990';

// only used for development, for all other environments server URL is the same as site URL
export const SERVER_URL = ENV === 'development' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SITE_URL;

// For exports
export const EXPORT_NEW = 'new'
export const EXPORT_PREV = 'prev'
export const EXPORT_ALL = 'all'

// Session expiration time (for localStorage)
export const SESSION_EXP_STORAGE_KEY = 'ctyhc_session_expiration_time'