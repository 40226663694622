import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_WIDTH, RADIUS, BG_PRIMARY, SHADOW_DARK } from '../../cssVars.js';

const Tile = styled.div`
  width: ${({ width }) => width || 800}px;
  width: min(${({ width }) => width || 800}px, 100%);
  min-height: calc(100vh - 30px);
  position: relative;
  z-index: 1;
  margin: 0px auto;
  margin-top: 30px;
  border-radius: ${RADIUS} ${RADIUS} 0px 0px;
  background-color: ${BG_PRIMARY};
  box-shadow: ${SHADOW_DARK};
  text-align: left;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 100%;
    width: min(${({ width }) => width || 800}px, 100%);
    margin-top: 15px;
    min-height: calc(100vh - 30px + 15px);
  } 
`

const Content = styled.div`
  padding: 40px 60px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding: 30px;
  }
`

export const Header = styled.h2`
  font-weight: 600;
  font-size: 30px;
  margin: 20px 0px;
  margin-bottom: 24px;
`;

export const Subheader = styled.h3`
  font-weight: 700;
  font-size: 18px;
  margin: 0px;
  margin-top: 30px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin-bottom: 0px;
  }
`;

export const Text = styled.p`
  margin: 20px 0px;
`;

// Styled wrapper (looks like a ... beige tile) that is used 
// for the Eligibility Checker and Steps to File flows
export const BeigeTile = ({ children, style, width }) => {
  return (
    <Tile width={width}>
      <Content style={style}>
        {children}
      </Content>
    </Tile>
  )
}
