import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { ParagraphTextField } from '../general/FormFields';
import { SettingsHeader } from './SettingsEditor';

const Description = styled.p`
  margin-top: 10px;
`

export const TermsAndConditionsEditor = () => {
  const { errors } = useFormikContext();
  return (
    <div style={{ height: '95vh'}}>
      <SettingsHeader id="terms-and-conditions">Terms and Conditions</SettingsHeader>
      <Description>
        Text displayed to the user, that they must agree to before viewing the application. 
        If left blank, no terms and conditions screen will show.
      </Description>
      <ParagraphTextField error={errors.termsAndConditions} name="termsAndConditions" />
    </div>
  )
}