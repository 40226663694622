import React from 'react';
import styled from 'styled-components'
import { KnowYourRightsContent } from '../application/KnowYourRights.js'

const Wrapper = styled.div`
  padding: 30px;
`

// Render page to show know your rights information, using shared Know Your Rights component
export const KnowYourRightsEmail = () => {
  return (
    <Wrapper>
      <KnowYourRightsContent />
    </Wrapper>
  )
}