import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components'
import { Formik } from 'formik';
import { ParagraphTextField, TextField, SelectField, OptionsList, CheckboxGroup } from './FormFields.js';
import { setSurveyModal } from '../../actions/modals';
import { showSurveySelector } from '../../selectors/modals';
import { 
  timeToCompleteFormsOptions, 
  didYouCompleteTheFormsOptions, 
  petitionFormOptions,
  howUserHeardAboutSiteOptions,
  genderOptions,
  raceOptions,
  helpfulnessOptions,
  didYouFindWhatYouNeededOptions,
  howEasyWasInfoToFindOptions,
  whereDidYouAccessWebsiteOptions,
  additionalInfoOptions,
  educationLevelOptions,
  incomeRangeOptions,
  ageOptions
} from '../../constants/formOptions'
import { Modal } from './Modal';
import { Button, StyledHR } from './Common.js';
import { useFeedbackSurvey } from '../../hooks/editors.js';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
  margin-bottom: 5px;
`

export const SurveyModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // If we're showing someone this survey on page transition, use "Before you go ..." title.
  // Otherwise, show generic "Feedback Survey" title.
  const [showBeforeYouGo, setShowBeforeYouGo] = useState(false);

  const onClose = () => {
    // When closing the modal, also remove any query strings
    history.push({ search: '' });
    setShowBeforeYouGo(false);
    dispatch(setSurveyModal(false));
  };

  const [submit, submitting] = useFeedbackSurvey(onClose);

  // Show the survey when set to show in state
  const showModal = useSelector(showSurveySelector);

  // If 'modal=survey' query string is set, update the redux state to show survey modal
  // This way the modal can be easily shown when we navigate to a page, e.g. return to the homepage after finishing a flow
  useEffect(() => {
    if (!showModal && location.search.includes('modal=survey')) {
      setShowBeforeYouGo(true);
      dispatch(setSurveyModal(true));
    }
    // eslint-disable-next-line
  }, [location.search]);


  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>{showBeforeYouGo ? 'Before you go ...' : 'Feedback Survey'}</Title>
      <Text>
        Please answer the following optional questions to help us improve this tool for others.
      </Text>
      <Formik
        initialValues={{
          howHelpfulWasWebsite: '',
          didUserCompleteForms: '',
          petitionFormsUsed: '',
          timeToCompleteForms: '',
          didYouFindWhatYouNeeded: '',
          howEasyWasInfoToFind: '',
          incomeRange: '',
          educationLevel: '',
          howUserHeardAboutSiteOtherInput: '',
          howUserHeardAboutSite: '',
          additionalFeedback: '',
          applicantAge: '',
          applicantGender: '',
          applicantGenderSelfDescribeInput: '',
          applicantRaceEthnicity:[],
          applicantRaceEthnicityOtherInput: '',
        }}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, values, errors, touched, submitCount }) => {
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <Text>
                How helpful was this website?
              </Text>
              <SelectField name="howHelpfulWasWebsite" error={getError("howHelpfulWasWebsite")} >
                <OptionsList options={helpfulnessOptions} name="howHelpfulWasWebsite" />
              </SelectField>
              
              <Text>
                Did you complete the forms to file for nondisclosure?
              </Text>
              <SelectField name="didUserCompleteForms" error={getError("didUserCompleteForms")} >
                <OptionsList options={didYouCompleteTheFormsOptions} name="didUserCompleteForms" />
              </SelectField>
              {values.didUserCompleteForms === 'Yes' && (
                <div>
                  <Text>
                    Which petition forms did you complete?
                  </Text>
                  <SelectField name="petitionFormsUsed" error={getError("petitionFormsUsed")} >
                    <OptionsList options={petitionFormOptions} name="petitionFormsUsed" />
                  </SelectField>

                  <Text>
                    How long did it take you to complete the form?
                  </Text>
                  <SelectField name="timeToCompleteForms" error={getError("timeToCompleteForms")} >
                    <OptionsList options={timeToCompleteFormsOptions} name="timeToCompleteForms" />
                  </SelectField>
                </div>
              )}

              <Text>
                Did you find what you needed?
              </Text>
              <SelectField name="didYouFindWhatYouNeeded" error={getError("didYouFindWhatYouNeeded")} >
                <OptionsList options={didYouFindWhatYouNeededOptions} name="didYouFindWhatYouNeeded" />
              </SelectField>

              <Text>
                If you found what you were looking for, how easy was it to find?
              </Text>
              <SelectField name="howEasyWasInfoToFind" error={getError("howEasyWasInfoToFind")} >
                <OptionsList options={howEasyWasInfoToFindOptions} name="howEasyWasInfoToFind" />
              </SelectField>

              <StyledHR />

              <Text>
                Your age
              </Text>
              <SelectField name="applicantAge" error={getError("applicantAge")} >
                <OptionsList options={ageOptions} name="applicantAge" />
              </SelectField>

              <Text>
                Your gender
              </Text>
              <SelectField name="applicantGender" error={getError("applicantGender")} >
                <OptionsList options={genderOptions} name="applicantGender" />
              </SelectField>
              {values.applicantGender === 'Prefer to self-describe' && <TextField name="applicantGenderSelfDescribeInput" placeholder="Please specify" />}

              <Text>
                Your race-ethnicity (select all that apply)
              </Text>
              <CheckboxGroup name="applicantRaceEthnicity" labelId="applicantRaceEthnicity" 
                            error={getError('applicantRaceEthnicity')} checkboxOptions={raceOptions}
                            style={{ marginLeft: '20px' }} checkboxStyle={{ marginTop: '15px'}} />
               {values.applicantRaceEthnicity.includes("Other") && <TextField name="applicantRaceEthnicityOtherInput" placeholder="Please specify"/>}

              <Text style={{marginTop: '30px'}}>
               What is your highest level of schooling?
              </Text>
              <SelectField name="educationLevel" error={getError("educationLevel")} >
                <OptionsList options={educationLevelOptions} name="educationLevel" />
              </SelectField>

              <Text>
                What is your total annual household income?
              </Text>
              <SelectField name="incomeRange" error={getError("incomeRange")} >
                <OptionsList options={incomeRangeOptions} name="incomeRange" />
              </SelectField>

              <Text>
                We'd like to know a little bit more about you. Please check any of the following that apply to you.
              </Text>
              <CheckboxGroup name="additionalInfo" labelId="additionalInfo" 
                            error={getError('additionalInfo')} checkboxOptions={additionalInfoOptions}
                            style={{ marginLeft: '20px'}} checkboxStyle={{ marginTop: '15px'}} /> 

              <StyledHR />

              <Text>
                Where did you access this website today?
              </Text>
              <SelectField name="whereDidYouAccessWebsite" error={getError("whereDidYouAccessWebsite")} >
                <OptionsList options={whereDidYouAccessWebsiteOptions} name="whereDidYouAccessWebsite" />
              </SelectField>

              <Text>
                How did you hear about this site?
              </Text>
              <SelectField name="howUserHeardAboutSite" error={getError("howUserHeardAboutSite")} >
                <OptionsList options={howUserHeardAboutSiteOptions} name="howUserHeardAboutSite" />
              </SelectField>
              {values.howUserHeardAboutSite === "Other" && <TextField name="howUserHeardAboutSiteOtherInput" placeholder="Please specify" />}

              <Text>
                How can we improve this site?
              </Text>
              <ParagraphTextField name="additionalFeedback" />

              <Button isLoading={submitting} wrapperStyle={{ marginTop: '10px' }} fillOnMobile={true} onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}}
    </Formik> 
    </Modal>
  )
}