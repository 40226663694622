import React from 'react';
import styled from 'styled-components';
import {
  Button,
  InvertedButton,
  LinkLikeButton,
  StyledHR
} from '../general/Common';
import { BeigeTile, Header, Subheader, Text } from '../general/BeigeTile.js';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  eligibleCasesSelector,
  expunctionEligibleCasesSelector,
  ineligibleCasesSelector,
  purelyIneligibleCasesSelector,
  nondisclosureEligibleCasesSelector,
  hasCrimeOutsideTexasSelector,
  hasCrimeBefore1990Selector
} from '../../selectors/entities.js';
import { CaseCard } from '../general/CaseCard';
import {
  setCase,
  setEligibilityDisclaimerModal,
  setEmailKnowYourRightsModal,
  setEmailResultsModal,
  setIneligibleCasesModal
} from '../../actions/modals';
import { NoCasesFound, NoCasesFoundInitContent } from './NoCasesFound';
import { KnowYourRightsContent } from './KnowYourRights';
import { useAdjacentSteps } from '../../hooks/useSteps';
import { TileBackArrow } from '../general/TileBackArrow';
import { SHADOW_TINY } from '../../cssVars';

const ImportantTile = styled.div`
  border-radius: 5px;
  padding: 10px 13px;
  background-color: #ecd893;
  font-size: 17px;
  box-shadow: ${SHADOW_TINY};
`;

// No cases were found content
export const NoResults = () => {
  const { orgSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { prevStep } = useAdjacentSteps('eligibility');

  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push(prevStep)} />
        No cases found
      </Header>
      <NoCasesFoundInitContent />
      <StyledHR margin={'30px 0px'} />
      <KnowYourRightsContent />
      <InvertedButton
        style={{ marginTop: '20px' }}
        onClick={() => dispatch(setEmailKnowYourRightsModal(true))}
      >
        Email this page to me
      </InvertedButton>
      <StyledHR margin={'30px 0px'} />
      <Button onClick={() => history.push(`/${orgSlug}/app/review`)}>
        Next
      </Button>
    </BeigeTile>
  );
};

// Helper, gives "# case" or "# cases" depending on length of array (optional descriptor, e.g. "1 cool case")
const getCasesText = (caseArray, extraDescriptor) => {
  return `${caseArray.length} ${extraDescriptor || ''} case${
    caseArray.length > 1 ? 's' : ''
  }`;
};

// Link-like text that displays the number of ineligible cases & pops open a modal to view details when clicked
const IneligibleCasesLink = () => {
  const purelyIneligibleCases = useSelector(purelyIneligibleCasesSelector);
  const dispatch = useDispatch();

  return (
    <LinkLikeButton
      style={{ fontWeight: 500 }}
      onClick={() => dispatch(setIneligibleCasesModal(true))}
    >
      {getCasesText(purelyIneligibleCases, 'ineligible')}
    </LinkLikeButton>
  );
};

// View used when there's at least one eligible case in results
export const EligibleCaseExistsResults = () => {
  const { orgSlug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { prevStep } = useAdjacentSteps('eligibility');
  const allEligibleCases = useSelector(eligibleCasesSelector);
  const nondisclosureEligCases = useSelector(
    nondisclosureEligibleCasesSelector
  );
  const expunctionEligibleCases = useSelector(expunctionEligibleCasesSelector);
  const hasCrimeOutsideHC = useSelector(hasCrimeOutsideTexasSelector);
  const hasCrimeBefore1990 = useSelector(hasCrimeBefore1990Selector);
  // has an unknown dps case that could potentially affect nondisclosure eligiblity
  // dps_unknown_offense is marked for all cases on a spn, so it would show up on all cases; dps_unknown_disposition_or_charge_lvl is marked for the relevant nondisclosure case
  const hasPossibleDPSIneligibility = nondisclosureEligCases.some(
    caseObj =>
      caseObj.dps_unknown_offense ||
      caseObj.dps_unknown_disposition_or_charge_lvl
  );

  const purelyIneligibleCases = useSelector(purelyIneligibleCasesSelector);

  const otherCasesExist = purelyIneligibleCases.length > 0;
  const clickCase = currCase => () => dispatch(setCase(currCase));

  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push(prevStep)} />
        {`${getCasesText(allEligibleCases, 'potentially eligible')} found!`}
      </Header>
      <Text>
        Based on Harris County records and the information provided, you have
        {` `}
        {nondisclosureEligCases.length > 0 && (
          <>
            {getCasesText(nondisclosureEligCases)} that may be eligible for
            nondisclosure
          </>
        )}
        {nondisclosureEligCases.length > 0 &&
          expunctionEligibleCases.length > 0 &&
          ' and '}
        {expunctionEligibleCases.length > 0 && (
          <>
            {getCasesText(expunctionEligibleCases)} that may be eligible for
            expunction
          </>
        )}
        .
      </Text>
      {/* Show summary of ineligible and expunction eligible cases when present */}
      {otherCasesExist && (
        <Text>
          The checker also found{' '}
          {purelyIneligibleCases.length > 0 && <IneligibleCasesLink />}.
        </Text>
      )}
      <Subheader>Likely Eligible Cases*</Subheader>
      {allEligibleCases.map((currCase, idx) => (
        <CaseCard
          onClick={clickCase(currCase)}
          key={`case${idx}`}
          caseInfo={currCase}
        />
      ))}
      <Text style={{ fontSize: '17px' }}>
        <i>
          *These results do not guarantee eligibility.{' '}
          <LinkLikeButton
            style={{ fontStyle: 'italic' }}
            onClick={() => dispatch(setEligibilityDisclaimerModal(true))}
          >
            Learn more
          </LinkLikeButton>
        </i>
      </Text>
      {(hasCrimeOutsideHC || hasCrimeBefore1990) && (
        <ImportantTile>
          NOTE: You responded "yes" to
          {hasCrimeOutsideHC && ' having charges outside of Harris County'}
          {hasCrimeOutsideHC && hasCrimeBefore1990 && ' and '}
          {hasCrimeBefore1990 && ' having charges before 1990'}. If these
          charges resulted in a conviction or deferred adjudication, this may
          affect your nondisclosure eligibility.
        </ImportantTile>
      )}
      {!(hasCrimeOutsideHC || hasCrimeBefore1990) &&
        hasPossibleDPSIneligibility && (
          <ImportantTile>
            NOTE: Our records indicate you have charges outside of Harris
            County. If these charges resulted in a conviction or deferred
            adjudication, this may affect your nondisclosure eligibility.
          </ImportantTile>
        )}
      <br />
      <InvertedButton onClick={() => dispatch(setEmailResultsModal(true))}>
        Email my case information to me
      </InvertedButton>
      <StyledHR margin={'30px 0px'} />
      <Button onClick={() => history.push(`/${orgSlug}/app/review`)}>
        Next
      </Button>
    </BeigeTile>
  );
};

// View used when no eligible cases exist in results (only expunction eligible or ineligible)
export const NoEligibleCaseExistsResults = () => {
  const purelyIneligibleCases = useSelector(purelyIneligibleCasesSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgSlug } = useParams();
  const { prevStep } = useAdjacentSteps('eligibility');
  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push(prevStep)} />
        No currently eligible cases found
      </Header>
      <Text>
        Unfortunately, given the information provided, the eligibility checker
        was not able to identify any cases of yours that are currently eligible
        to be sealed or expunged from your Harris County criminal record at this
        time.
      </Text>
      <Text>Here is the full breakdown of what was found:</Text>
      <ul>
        {purelyIneligibleCases.length > 0 && (
          <li>
            <IneligibleCasesLink />
          </li>
        )}
      </ul>
      <Text style={{ marginBottom: '40px' }}>
        Ineligibility now may not be forever. The Texas State Legislature meets
        every two years and may pass new legislation that could benefit you.
        They next meet in the spring of 2023.
      </Text>
      <StyledHR margin={'30px 0px'} />
      <KnowYourRightsContent />
      <InvertedButton
        style={{ marginTop: '20px' }}
        onClick={() => dispatch(setEmailKnowYourRightsModal(true))}
      >
        Email this page to me
      </InvertedButton>
      <StyledHR margin={'30px 0px'} />
      <Button onClick={() => history.push(`/${orgSlug}/app/review`)}>
        Next
      </Button>
    </BeigeTile>
  );
};

export const Results = () => {
  const eligibleCases = useSelector(eligibleCasesSelector);
  const ineligibleCases = useSelector(ineligibleCasesSelector);

  if (eligibleCases.length === 0 && ineligibleCases.length === 0) {
    return <NoResults />;
  }
  if (eligibleCases.length > 0) {
    return <EligibleCaseExistsResults />;
  }
  return <NoEligibleCaseExistsResults />;
};
