// Nondisclosure form case properties. Pulling them out into variables since I'm currently guessing on naming
export const FINAL_POT_ELIG_411_0727 = "final_pot_elig_411_0727"
export const FINAL_POT_ELIG_411_0728 = "final_pot_elig_411_0728"
export const FINAL_POT_ELIG_411_0729 = "final_pot_elig_411_0729"
export const FINAL_POT_ELIG_411_0731 = "final_pot_elig_411_0731"
export const FINAL_POT_ELIG_411_0736 = "final_pot_elig_411_0736"
export const PENDING_POT_ELIG_411_0727 = "pending_pot_elig_411_0727"
export const PENDING_POT_ELIG_411_0728 = "pending_pot_elig_411_0728"
export const PENDING_POT_ELIG_411_0729 = "pending_pot_elig_411_0729"
export const NDO_FORM = "ndo_form"
export const DAYS_SINCE_SENTENCE_COMPLETION = "days_since_sentence_completion"
export const DAYS_SINCE_PROBATION_COMPLETION = "days_since_probation_completion"

// Nondisclosure Form IDs
export const FORM_ID_411_072 = "411.072"
export const FORM_ID_411_0725 = "411.0725"
export const FORM_ID_411_0726 = "411.0726" // Needs followup questions
export const FORM_ID_411_0727 = "411.0727" // Needs followup questions
export const FORM_ID_411_0728 = "411.0728" // Needs followup questions
export const FORM_ID_411_0729 = "411.0729" // Needs followup questions
export const FORM_ID_411_073 = "411.073"
export const FORM_ID_411_0731 = "411.0731" // Needs followup questions
export const FORM_ID_411_0735 = "411.0735"
export const FORM_ID_411_0736 = "411.0736" // Needs followup questions

// Followup Field Ids
export const COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE = "completed_vet_reemployment_prg_for_case" // Veteran's reemployemt program (411.0729)
export const COMPLETED_VET_TREATMENT_COURT_FOR_CASE = "completed_vet_treatment_court_for_case" // Veteran's treatment court (411.0727)
export const BAC_UNDER_015 = "bac_under_015" // DWI (411.0726, 411.0731, 411.0736)
export const HAD_COMMERCIAL_LICENSE_OR_PERMIT = "had_commercial_license_or_permit" // DWI - deferred adjudication (411.0726)
export const USED_IGNITION_INTERLOCK = "used_ignition_interlock_device" // DWI - conviction (411.0731, 411.0736)
export const COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM = "committed_solely_as_trafficking_victim" // Human Trafficking (411.0728)
export const COMMITTED_AFTER_SUBMITTING_PETITION = "commited_after_submitting_petition" // Human Trafficking (411.0728)

// Initial fields object for formik for each NDO form type requiring followup questions
export const INIT_FOLLOWUP_FIELDS_BY_FORM = {
  [FORM_ID_411_0726]: { [BAC_UNDER_015]: null, [HAD_COMMERCIAL_LICENSE_OR_PERMIT]: null },
  [FORM_ID_411_0727]: { [COMPLETED_VET_TREATMENT_COURT_FOR_CASE]: null },
  [FORM_ID_411_0728]: { [COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM]: null, [COMMITTED_AFTER_SUBMITTING_PETITION]: null },
  [FORM_ID_411_0729]: { [COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE]: null },
  [FORM_ID_411_0731]: { [BAC_UNDER_015]: null, [USED_IGNITION_INTERLOCK]: null },
  [FORM_ID_411_0736]: { [BAC_UNDER_015]: null, [USED_IGNITION_INTERLOCK]: null },
}

// Texas Legal Services page links for each NDO form type
export const TLS_LINKS_BY_FORM_ID = {
  [FORM_ID_411_072]: "https://texaslawhelp.org/article/nondisclosure-deferred-adjudication-community-supervision-certain-nonviolent-misdemeanors-under#toc-3",
  // NOTE: 0725 is terrible, maybe shouls just set to null and show other form
  [FORM_ID_411_0725]: "https://texaslawhelp.org/article/nondisclosure-procedure-deferred-adjudication-felonies-and-certain-misdemeanors-under-section#toc-2",
  // NOTE: 0726 has "in detail" wording, instead of "who is eligible"
  [FORM_ID_411_0726]: "https://texaslawhelp.org/article/nondisclosure-procedure-certain-dwi-deferred-adjudications-under-section-4110726#toc-2",
  // NOTE: No texas law help page for 411.0727
  [FORM_ID_411_0727]: null,
  [FORM_ID_411_0728]: "https://texaslawhelp.org/article/nondisclosure-procedure-certain-victims-trafficking-persons-under-section-4110728#toc-3",
  // NOTE: 0729 has "in detail" wording, instead of "who is eligible"
  [FORM_ID_411_0729]: "https://texaslawhelp.org/article/nondisclosure-procedure-veterans-reemployment-program-under-section-4110729#toc-2",
  [FORM_ID_411_073]: "https://texaslawhelp.org/article/nondisclosure-procedure-community-supervision-following-conviction-certain-misdemeanors-under#toc-3",
  [FORM_ID_411_0731]: "https://texaslawhelp.org/article/nondisclosure-procedure-community-supervision-following-conviction-certain-driving-while-intoxicated#toc-3",
  [FORM_ID_411_0735]: "https://texaslawhelp.org/article/nondisclosure-procedure-conviction-certain-misdemeanors-under-section-4110735#toc-3",
  [FORM_ID_411_0736]: "https://texaslawhelp.org/article/nondisclosure-procedure-conviction-certain-driving-while-intoxicated-convictions-under-section#toc-3"
}

// There's a separate boating petition for 411.0726, variable to specify with
export const BOATING_PETITION_ID = "411.0726_boating"

// Texas Legal Services fillable petition links for each NDO form
export const PETITION_LINKS_BY_FORM_ID = {
  [FORM_ID_411_072]: "https://texaslawhelp.org/sites/default/files/section-411-072-2020_combined_jan_2020-fillable_3_2021.pdf",
  [FORM_ID_411_0725]: "https://texaslawhelp.org/sites/default/files/section-411-0725-final_combined_jan2020-fillable_3_2021.pdf",
  [FORM_ID_411_0726]: "https://texaslawhelp.org/sites/default/files/section-4110726-2020_combineddwi_jan2020-fillable_4_2021.pdf",
  [FORM_ID_411_0727]: "https://texaslawhelp.org/sites/default/files/section-4110727_combined_jan2020-fillable_4_2021.pdf",
  [FORM_ID_411_0728]: "https://texaslawhelp.org/sites/default/files/section-411-0728-2020_combined_jan2020-fillable_3_2021.pdf",
  [FORM_ID_411_0729]: "https://texaslawhelp.org/sites/default/files/section-4110729_combined_jan2020-fillable_4_2021.pdf",
  [FORM_ID_411_073]: "https://texaslawhelp.org/sites/default/files/section-411-073-2017_combined_jan2020-fillable_3_2021.pdf",
  [FORM_ID_411_0731]: "https://texaslawhelp.org/sites/default/files/section-4110731_combined_jan2020-fillable_4_2021.pdf",
  [FORM_ID_411_0735]: "https://texaslawhelp.org/sites/default/files/section-411-0735-2017_combined_jan2020-fillable_3_2021.pdf",
  [FORM_ID_411_0736]: "https://texaslawhelp.org/sites/default/files/section-411-0736_combined_jan2020-fillable_3_2021.pdf",
  [BOATING_PETITION_ID]: "https://texaslawhelp.org/sites/default/files/section-4110726-2020_combinedbwi_jan2020-fillable_3_2021.pdf",
}
