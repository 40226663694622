import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components'
import { setEligibilityDisclaimerModal } from '../../actions/modals';
import { showEligibilityDisclaimerSelector } from '../../selectors/modals';
import { Modal } from './Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
`

// Disclaimer specific to receiving potentially eligible case results
export const EligibilityDisclaimerModal = () => {

  const showDisclaimer = useSelector(showEligibilityDisclaimerSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Allows this modal to be triggered by including a search param in the url,
  // so it can be accessed by link (we include a link to disclaimer in the results email PDF)
  useEffect(() => {
    if (!showDisclaimer && location.search.includes('modal=eligibility_disclaimer')) {
      dispatch(setEligibilityDisclaimerModal(true));
    }
    // eslint-disable-next-line
  }, [location.search]);

  const onClose = () => {
    history.push({ search: '' });
    dispatch(setEligibilityDisclaimerModal(false));
  }

  if (!showDisclaimer) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>Eligibility Disclaimer</Title>
      <Text>
        Due to the complex nature of nondisclosure law in Texas, we identify potentially eligible cases, 
        but do not provide further explanation for such potential eligibility. 
      </Text>
      <Text>
        It is ultimately up to a court of law to decide whether or not to grant you a nondisclosure or expunction.
      </Text>
      <Text>
        Additionally, since this site utilizes Harris County criminal records for criminal charges above a Class C misdemeanor, there may be cases outside of Harris County or previous to 1990 that affect your eligibility.    
      </Text>
    </Modal>
  )
}