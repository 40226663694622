import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setCase } from '../../actions/modals';
import { selectedCaseSelector } from '../../selectors/modals';
import { FullCaseContent } from './FullCaseContent';
import { Modal } from './Modal';
import { CouldNotDetermineTile, EligibleTile, ExpunctionEligibleTile, IneligibleTile, NotYetEligibleTile } from './StatusTiles';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

// Shows full details about a case
export const CaseModal = () => {
  const selectedCase = useSelector(selectedCaseSelector);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setCase(null));
  }

  if (selectedCase == null) {
    return null;
  }

  const { final_eligibility, hideStatus } = selectedCase;

  let statusTile = <EligibleTile large={true} />
  if (final_eligibility === 'ineligible') {
    statusTile = <IneligibleTile large={true} />
  } else if (final_eligibility === 'expunction') {
    statusTile = <ExpunctionEligibleTile large={true} />
  } else if (final_eligibility === 'not_yet_eligible') {
    statusTile = <NotYetEligibleTile large={true} />
  } else if (final_eligibility === 'could_not_determine') {
    statusTile = <CouldNotDetermineTile large={true} />
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)', zIndex: 10000 }}>
      <Title>Full Case Information</Title>
      <FullCaseContent caseInfo={selectedCase} hideBorderLines={true} />
      {!hideStatus && statusTile}
    </Modal>
  )
}