import React from 'react';
import styled from 'styled-components'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react'
import { TextLink } from '../general/Common';
import { Header, Subheader, Text } from '../general/BeigeTile.js';
import { SITE_URL } from '../../constants/general';
import { eligibleCasesSelector, expunctionEligibleCasesSelector, purelyIneligibleCasesSelector } from '../../selectors/entities.js';
import { getCasesByEmailToken } from '../../actions/queries';
import { LargeCaseCard } from '../general/LargeCaseCard.js';

const Wrapper = styled.div`
  padding: 30px;
`

// Render page to show case results
export const ResultsEmail = () => {
  const { emailToken } = useParams();
  useRequest(getCasesByEmailToken(emailToken));
  
  const eligibleCases = useSelector(eligibleCasesSelector);
  const purelyIneligibleCases = useSelector(purelyIneligibleCasesSelector);
  const expunctionEligibleCases = useSelector(expunctionEligibleCasesSelector);

  return (
    <Wrapper>
        <Header>
          Your Case Results
        </Header>
        <Text>
          Based on our records and the information provided, you have:
        </Text>
        <ul>
          <li>{eligibleCases.length} case{eligibleCases.length !== 1 && 's'} that may be eligible for nondisclosure</li>
          <li>{purelyIneligibleCases.length} case{purelyIneligibleCases.length !== 1 && 's'}  that are likely ineligible for nondisclosure</li>
          <li>{expunctionEligibleCases.length} case{expunctionEligibleCases.length !== 1 && 's'}  that may be eligible for expunction</li>
        </ul>
        <Text>
          We've listed out your estimated eligibility and case information for each case below.
        </Text>
        {eligibleCases.length > 0 && <div>
          <Subheader>
            Likely Eligible Cases ({eligibleCases.length})
          </Subheader>
          <Text>
            Below are the cases that may be eligible for nondisclosure. Continue through our application to determine your recommended forms and learn how to file. Please note that these results do not guarantee eligibility (<TextLink to={`${SITE_URL}/?modal=eligibility_disclaimer`}>see full disclaimer</TextLink>).
          </Text>
          {eligibleCases.map((currCase, idx) => <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />)}
        </div>}
        {purelyIneligibleCases.length > 0 && <div>
          <Subheader>
            Likely Ineligible Cases ({purelyIneligibleCases.length})
          </Subheader>
          <Text>
            Below are the cases that are likely ineligible for nondisclosure. <TextLink to={`${SITE_URL}/check/know-your-rights`}>Know your rights.</TextLink>
          </Text>
          {purelyIneligibleCases.map((currCase, idx) => <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />)}
        </div>}
        {expunctionEligibleCases.length > 0 && <div>
          <Subheader>
            Likely Expunction Eligible Cases ({expunctionEligibleCases.length})
          </Subheader>
          <Text>
          Below are the cases we found in your Harris County records that may be eligible for expunction. However, this website does not provide any further information on how to
            expunge eligible records. Please learn more about expunctions at <TextLink to="https://texaslawhelp.org/resources/expunction-forms-clear-arrest-record">Texas Law Help</TextLink>.
          </Text>
          {expunctionEligibleCases.map((currCase, idx) => <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />)}
        </div>}
    </Wrapper>
  )
}