import React from 'react';
import styled from 'styled-components';
import { Button, InvertedButton, TextLink } from '../general/Common';
import { BeigeTile, Header, Text } from '../general/BeigeTile.js';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchValuesSelector } from '../../selectors/entities.js';
import { BG_DARKER } from '../../cssVars';
import retryImg from '../../assets/retry.svg'
import { getMonthFromIndex } from '../../utils/helpers';
import { useColors } from '../../hooks/useColors';
import { useAdjacentSteps } from '../../hooks/useSteps';
import { HCDC_LINK } from '../../constants/links';
import { SERVER_URL } from '../../constants/general';
import useDates from '../../hooks/useDates';

const SearchQuery = styled.div`
  background-color: ${({bgcolor}) => bgcolor || BG_DARKER};
  padding: 15px;
  margin: 0px -15px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #403C24;
  > div {
    margin: 2px 0px;
    > b {
      font-weight: 700;
      margin-left: 5px;
    }
  }
`

const RetryIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 9px;
`

export const SearchRangeNote = () => {
  const { startDate, endDate } = useDates();
  return <>
    Please note that we are searching only Harris County cases which have occured 
    between <b>{startDate}</b> and <b>{endDate}</b>.
  </>
}

export const SearchQuerySection = () => {
  const { bgColor } = useColors();
  const searchValues = useSelector(searchValuesSelector);
  const dob = new Date(searchValues.dob);

  return (
    <SearchQuery bgcolor={bgColor}>
      <div>
        Name: <b>{searchValues.firstName} {searchValues.middleName || ''} {searchValues.lastName} {searchValues.suffix || ''}</b>
      </div>
      {/* Display aliases if they were included in the search */}
      {searchValues.aliasFirstNames.length > 0 && 
        <div>
          Other first names: <b>{searchValues.aliasFirstNames.join(', ')}</b>
        </div>}
      {searchValues.aliasLastNames.length > 0 && 
        <div>
          Other last names: <b>{searchValues.aliasLastNames.join(', ')}</b>
        </div>}
      <div>
        Date of Birth: <b>{`${getMonthFromIndex(dob.getUTCMonth())} ${dob.getUTCDate()}, ${dob.getUTCFullYear()}`}</b>
      </div>
    </SearchQuery>
  )
}

export const NoCasesFoundInitContent = ({other}) => {
  const { bgColor } = useColors();
  const searchValues = useSelector(searchValuesSelector);
  const dob = new Date(searchValues.dob);
  
  return (
    <>
      <Text>
        We weren’t able to find any {other ? 'other' : null} Harris County cases for:
      </Text>
      <SearchQuery bgcolor={bgColor}>
        <div>
          Name: <b>{searchValues.firstName} {searchValues.middleName || ''} {searchValues.lastName} {searchValues.suffix || ''}</b>
        </div>
        {/* Display aliases if they were included in the search */}
        {searchValues.aliasFirstNames.length > 0 && 
          <div>
            Other first names: <b>{searchValues.aliasFirstNames.join(', ')}</b>
          </div>}
        {searchValues.aliasLastNames.length > 0 && 
          <div>
            Other last names: <b>{searchValues.aliasLastNames.join(', ')}</b>
          </div>}
        <div>
          Date of Birth: <b>{`${getMonthFromIndex(dob.getUTCMonth())} ${dob.getUTCDate()}, ${dob.getUTCFullYear()}`}</b>
        </div>
      </SearchQuery>
      <SearchRangeNote />
    </>
  )
}

// Content when no cases for a given search was found.
// Restates the name(s) & DOB used in search & gives prompt to retry.
export const NoCasesFound = ({ other }) => {
  const history = useHistory();
  const { orgSlug } = useParams();
  const { nextStep } = useAdjacentSteps('personalInfo');

  return (
    <>
      <NoCasesFoundInitContent other={other} />
      <Text>
        Try searching again using the button below. Double check your spelling and date of birth to make sure they are correct. If you go by any other names, or have gone by any other names in the past that may be on your record, please add them under the "Other Names" section. If you are unsure if your records are under a different name, you can check by going to the <TextLink to={HCDC_LINK}>Harris County District Clerk's website</TextLink> and searching for your records - the results may appear under a different name.  Click <TextLink to={`${SERVER_URL}/pdfs/HCDC_accessing_records.pdf`}>here</TextLink> for a guide on how to look up your records through Harris County District Clerk.
      </Text>
      <InvertedButton type="button" style={{ minWidth: '250px', marginBottom: '20px', position: 'relative' }} // display: 'flex', alignItems: 'center', justifyContent: 'center'
              onClick={() => history.push(`/${orgSlug}/app/personalInfo`)}>
        Search again
      </InvertedButton>
      <Text>
        If you are sure you entered your information correctly and want to finish the application, click 'Next'.
      </Text>

      <Button style={{ minWidth: '250px' }} type="button" onClick={() => history.push(nextStep)}>Next</Button>
    </>
  )
}

export const NoCasesFoundPage = () => (
  <BeigeTile>
    <Header>
      No cases found
    </Header>
    <NoCasesFound />
  </BeigeTile>
)
