import React from 'react';
import styled from 'styled-components';
import closeIcon from '../../assets/close.svg'
import { MAX_DESKTOP_WIDTH, PRIMARY, RADIUS } from '../../cssVars';
import FocusTrap from 'focus-trap-react';
import { useColors } from '../../hooks/useColors';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100,100,100,0.45);
`;

const ModalWrapper = styled.div`
  position: relative;
  margin: 10px;  
  background-color: white;
  border-radius: ${RADIUS};
  box-shadow: 0 2px 10px rgba(0,0,0,.20);
  padding: 30px;
  width: 550px;
  width: min(550px, 95%);
  max-height: 85%;
  overflow-x: auto;
  overflow-y: scroll;
  @media only screen and (max-width: 600px) {
    width: 95%;
  }
`;

const Icon = styled.svg.attrs({ 
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const Svg = styled(Icon)` 
  width: 18px; 
  width: 18px; 
  margin-left: 10px;
`

const CloseIcon = () => {
  const { primary } = useColors();
  return (
    <Svg viewBox="0 0 115 115">
      <path d="M3.40633124,3.40633124 C7.11631201,-0.303649532 13.1313793,-0.303649532 16.8413601,3.40633124 L16.8413601,3.40633124 L57.4998457,44.0648457 L98.1586399,3.40633124 C101.868621,-0.303649532 107.883688,-0.303649532 111.593669,3.40633124 C115.30365,7.11631201 115.30365,13.1313793 111.593669,16.8413601 L70.9348457,57.4998457 L111.593669,98.1586399 C115.23365,101.798621 115.302329,107.657525 111.799705,111.381239 L111.593669,111.593669 C107.883688,115.30365 101.868621,115.30365 98.1586399,111.593669 L98.1586399,111.593669 L57.4998457,70.9348457 L16.8413601,111.593669 C13.1313793,115.30365 7.11631201,115.30365 3.40633124,111.593669 C-0.303649532,107.883688 -0.303649532,101.868621 3.40633124,98.1586399 L44.0648457,57.4998457 L3.40633124,16.8413601 C-0.233649895,13.2013789 -0.302328785,7.34247508 3.20029457,3.61876101 Z" 
      id="close" fill={primary}></path>
    </Svg>
  )
}

const CloseButton = styled.img`
  margin-left: 10px;
  height: 18px;
  width: 18px;
`

const CloseWrapper = styled.button`
  color: ${({color}) => color || PRIMARY};
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: 12px;
    right: 12px;
  }
  &:focus {
    box-shadow: none !important;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${({isRTL}) => isRTL ? 'flex-start' : 'flex-end'};
  margin-top: 20px;
  > div {
    margin-left: 10px;
  }
  > a {
    margin-left: 10px;
  }
`

export const ModalHeader = styled.div`
  font-weight: 500;
  color: #454545;
  font-size: 24px;
  border-bottom: 1px solid #dedede;
  direction: ${({isRTL}) => isRTL ? 'rtl' : 'ltr'};
  padding-bottom: 10px;
  > div {
    display: inline-block;
  }
`

export const Modal = ({ children, onClose, style, wrapperStyle, closeOnOuterClick }) => {
  const { primary } = useColors();
  return (
  <OuterWrapper style={wrapperStyle} onClick={() => closeOnOuterClick ? onClose() : null}>
    <FocusTrap>
      <ModalWrapper style={style} onClick={(e) => e.stopPropagation()}>
        <CloseWrapper color={primary} type="button" onClick={onClose}>
          close
          <CloseIcon tabIndex="0" src={closeIcon} alt="" />
        </CloseWrapper>
        {children}
      </ModalWrapper>
    </FocusTrap>
  </OuterWrapper>
)};
