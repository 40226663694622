import React from 'react';
import styled from 'styled-components';
import { Button } from '../general/Common';
import { PRIMARY, PRIMARY_DARK, SECONDARY } from '../../cssVars.js';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField } from '../general/FormFields';
import { useRequest } from 'redux-query-react';
import { getConfigQuery } from '../../actions/queries';
import { PageLoader } from '../general/Loaders';
import ctrhcLogo from '../../assets/blackSheep/CTRHC.png';
import { validateResetPassword } from '../../utils/validators';
import { useResetPasswordQuery } from '../../hooks/editors';
import { CTRHCLogo, IntroText, MainContent, Tile } from './Login';

const Wrapper = styled.div`
  width: 100%;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

export const ResetPassword = () => {
  const history = useHistory();
  const { orgSlug, resetPasswordToken } = useParams();
  const [{ isFinished }] = useRequest(getConfigQuery(orgSlug));

  const [submit, submitting] = useResetPasswordQuery(() => {
    history.push(`/${orgSlug}/login`);
  }, resetPasswordToken) 

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <Wrapper>
      <CTRHCLogo src={ctrhcLogo} alt="Clear Their Record Harris County" />
      <MainContent>
        <Tile borderColor={SECONDARY}>
          <Formik
            validate={validateResetPassword}
            initialValues={{ password: '' }}
            onSubmit={submit}>
            {({ handleSubmit, errors, touched, values, submitCount }) => {
              const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
              return (
                <>
                  <IntroText color={PRIMARY_DARK}>
                    Reset Password
                  </IntroText>
                  <p style={{margin: '0px 0px 10px 0px', padding: '0px', fontSize: '17px'}}>
                    Please enter your new password below.
                  </p>
                  <TextField label="New password" error={getError('password')}  style={{ margin: '5px 0px '}} type="password" name="password" />
                  <TextField label="Confirm new password" error={getError('passwordConfirmation')}  style={{ margin: '5px 0px '}} type="password" name="passwordConfirmation" />
                  <ButtonWrapper>
                    <Button style={{ backgroundColor: PRIMARY }} onClick={handleSubmit} isLoading={submitting}>
                      Save new password
                    </Button>
                  </ButtonWrapper>
                </>
            )}}
          </Formik>
        </Tile>
      </MainContent>
    </Wrapper>
  )
}