import { 
  SET_CASE_MODAL, 
  SET_DISCLAIMER_MODAL, 
  SET_INELIGIBLE_CASES_MODAL, 
  SET_EMAIL_RESULTS_MODAL, 
  SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL,
  SET_ELIGIBILITY_DISCLAIMER_MODAL, 
  SET_SURVEY_MODAL,
  SET_VETS_REEMPLOYMENT_MODAL,
  SET_VETS_TREATMENT_CRT_MODAL, 
  SET_NEW_USER_MODAL,
  SET_FOLLOWUP_CASE_MODAL,
  SET_EXPORT_MODAL,
  SET_HELP_MODAL
} from '../actions/modals';

const defaultState = {
  selectedCase: null, 
  showIneligibleCases: false,
  showDisclaimer: false, 
  showEligibilityDisclaimer: false,
  showEmailResults: false,
  showEmailKnowYourRights: false,
  showSurvey: false,
  showVetsReemploymentPrg: false,
  showVetsTreatmentCrt: false,
  showNewUser: false,
  selectedFollowupCase: null,
  showExport: null,
  showHelp: false,
}

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CASE_MODAL:
      return {
        ...state,
        selectedCase: action.payload,
      };
    case SET_INELIGIBLE_CASES_MODAL:
      return {
        ...state,
        showIneligibleCases: action.payload,
      }
    case SET_DISCLAIMER_MODAL:
      return {
        ...state,
        showDisclaimer: action.payload,
      };
    case SET_ELIGIBILITY_DISCLAIMER_MODAL:
      return {
        ...state,
        showEligibilityDisclaimer: action.payload
      }
    case SET_EMAIL_RESULTS_MODAL:
      return {
        ...state,
        showEmailResults: action.payload
      }
    case SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL: 
      return {
        ...state,
        showEmailKnowYourRights: action.payload
      }
    case SET_SURVEY_MODAL:
      return {
        ...state,
        showSurvey: action.payload
      }
    case SET_VETS_REEMPLOYMENT_MODAL:
      return {
        ...state,
        showVetsReemploymentPrg: action.payload
      }
    case SET_VETS_TREATMENT_CRT_MODAL:
      return {
        ...state,
        showVetsTreatmentCrt: action.payload
      }
    case SET_NEW_USER_MODAL:
      return {
        ...state,
        showNewUser: action.payload
      }
    case SET_FOLLOWUP_CASE_MODAL:
      return {
        ...state,
        selectedFollowupCase: action.payload
      }
    case SET_EXPORT_MODAL:
      return {
        ...state,
        showExport: action.payload
      }
    case SET_HELP_MODAL:
      return {
        ...state,
        showHelp: action.payload
      }
    default:
      return state;
  }
};

export default modalsReducer;