import React from 'react';
import styled from 'styled-components';
import { BackArrowButton, Button, InvertedButton, TextLink, StyledHR } from '../general/Common';
import {  Header, Subheader, BeigeTile } from '../general/BeigeTile.js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allCasesSelector, eligibleCasesSelector } from '../../selectors/entities';
import { setEmailKnowYourRightsModal } from '../../actions/modals';

const SpacedUL = styled.ul`
  > li {
    margin: 15px 0px;
  }
`;

// Shared content for "Know Your Rights" app page and email page
export const KnowYourRightsContent = () => (
  <div>
    <Header style={{ marginBottom: '10px' }}>Know Your Rights</Header>
    <Subheader style={{ marginTop: '20px' }}>Navigating Housing and Job Markets with Criminal History</Subheader>
    <SpacedUL>
      <li>
        <b>Know your history</b> so you can make sure it was reported accurately
      </li>
      <li style={{ marginLeft: '40px' }}>
          You can obtain a copy of your Texas criminal history report from the Department of Public Safety at <TextLink style={{ textWrap: 'word-break', maxWidth: '100%'}} to="https://publicsite.dps.texas.gov/DpsWebsite/CriminalHistory/">https://publicsite.dps.texas.gov/DpsWebsite/CriminalHistory/</TextLink>
      </li>
      <li>
        Be able to <b>accurately describe</b> your convictions (dates and offenses)
      </li>
      <li>
        <b>Explain</b> the circumstances and take responsibility
      </li>
      <li>
        Have <b>evidence of rehabilitation</b> (certificates, letters, volunteer work)
      </li>
      <li>
        <b>Honesty</b> is always the best policy
      </li>
    </SpacedUL>
    <Subheader>Title 7 of the Civil Rights Act ...</Subheader>
    <SpacedUL>
      <li>
        Protects you from discrimination based solely on having criminal history
      </li>
      <li>
        Employers and landlords cannot have a policy of “no criminal history” or “no felonies”
      </li>
      <li>
        They must make individual evaluations of each application
      </li>
      <li>
        If denied, you may request the reason for denial and their policy regarding criminal history
      </li>
      <li>
        Report Employment Discrimination:
      </li>
      <li style={{ marginLeft: '40px' }}>
        Equal Employment Opportunity Commission <TextLink to="tel:800-669-4000">800-669-4000</TextLink>
      </li>
      <li style={{ marginLeft: '40px' }}>
        Greater Houston Fair Housing Center at <TextLink to="tel:713-641-3247">713-641-3247</TextLink>
      </li>
    </SpacedUL>
  </div>
)