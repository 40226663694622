import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from './BeigeTile';
import { appConfigSelector } from '../../selectors/entities';
import { useColors } from '../../hooks/useColors';
import { LinkLikeButton } from './Common';
import { getConfigQuery } from '../../actions/queries';
import { useRequest } from 'redux-query-react';
import { PageLoader } from './Loaders';

const CenteredContent = styled.div`
  display: flex;
  background-color: ${({color}) => color};
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  > div {
    width: 500px;
    max-width: 95%;
    padding-bottom: 100px;
  }
`

export const ConfirmationPage = () => {
  const { bgColor } = useColors();
  const history = useHistory();
  const { orgSlug } = useParams();
  const appConfig = useSelector(appConfigSelector);
  const [{ isFinished }] = useRequest(getConfigQuery(orgSlug));

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
      <CenteredContent color={bgColor}>
        <div>
          <Header style={{ textAlign: 'center'}}>
            Application Successfully Submitted!
          </Header>
          {appConfig.confirmationText}
          <div style={{ textAlign: 'center', marginTop: '20px'}}>
            <LinkLikeButton onClick={() => history.push(`/${orgSlug}/app/terms`)}>
              Submit another application
            </LinkLikeButton>
          </div>
        </div>
      </CenteredContent>
  )
}