import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MainContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoOrgFound = () => {
  return (
    <MainContent>
      <h1 style={{marginTop: '0px'}}>
        Sorry, we couldn't find this page!
      </h1>
      <h3 style={{marginTop: '0px', fontWeight: '500'}}>
        Please double check to make sure you have the right URL.
      </h3>
    </MainContent>
  )
}
