import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_WIDTH, SECONDARY } from '../../cssVars.js';
import { Section } from '../general/Section';
import { TextLink } from '../general/Common';
import blLogo from '../../assets/blackSheep/beacon_logo.png'
import jaLogo from '../../assets/ja-logo-horizontal.svg'
import { BEACON_LINK, JA_LINK, TAJF_LINK } from '../../constants/links';

const BeaconLawLogo = styled.img`
  width: 200px;
  height: 60px;
  margin-bottom: 0px;
`

const FooterSection = styled.div`
  font-size: 16px;
  color: white;
  > div {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    text-align: center;
    > a, button {
      text-align: center;
      font-size: 18px !important;
    }
  } 
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 18px;
  } 
`

const JASection = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  > a > img {
    width: 114px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
  }
`

export const Footer = () => {  
  return (
    <div style={{position: 'relative', zIndex: '0'}}>
      <Section templateColumns="30% 70%" color={SECONDARY} padding="40px 100px 40px 100px" mobilePadding="20px 10px 20px 10px" splitMargin="0px">
        <FooterSection>
          <TextLink to={BEACON_LINK}>
            <BeaconLawLogo src={blLogo} alt="Beacon Law Logo" />
          </TextLink>
          <JASection>
            Developed by
            <TextLink to={JA_LINK}>
              <img src={jaLogo} alt="January Advisors Logo" />
            </TextLink>
          </JASection>
        </FooterSection>
        <FooterSection style={{padding: '40px 0px'}}>
          Clear Their Record Harris County is brought to you by <TextLink style={{ color: "#DC7CBE" }} to={BEACON_LINK}>The Beacon</TextLink>, a nonprofit organization whose mission is to provide essential and next-step services to restore hope and help end homelessness in Houston.  
          Clear Their Record Harris County was made possible by funding from the <TextLink style={{ color: "#DC7CBE" }} to={TAJF_LINK}>Texas Access to Justice Foundation</TextLink>. 
        </FooterSection>
      </Section> 
    </div>
  )
}