import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setDisclaimerModal } from '../../actions/modals';
import { showDisclaimerSelector } from '../../selectors/modals';
import { TextLink } from './Common';
import { Modal } from './Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
`

// General disclaimer for the website
export const DisclaimerModal = () => {

  const showDisclaimer = useSelector(showDisclaimerSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setDisclaimerModal(false));
  }

  if (!showDisclaimer) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>Disclaimer</Title>
      <Text>
        This website utilizes an algorithm that helps determine
        nondisclosure eligibility based on Texas Law and Harris
        County District Clerk data. It is provided as a free resource
        and does not guarantee that a court will grant
        nondisclosure for cases identified as eligible on this
        website. Nothing provided on this website constitutes legal
        advice or establishes an attorney/client relationship with
        any legal organizations that helped create this website.
      </Text>
      <Text>
        Please note that this website is only for individuals with
        criminal records in Harris County, Texas for criminal
        charges above a <b>Class C misdemeanor after December
        31, 1989</b>. If you believe you have criminal records in
        another Texas county, please head to 
        the <TextLink to="https://georgetown.neotalogic.com/a/TexasFreshStartV2">Fresh Start App</TextLink> for further assistance.
      </Text>
      <Text>
        This website is also not able to provide any expunction eligibility information or assistance for bond forfeiture cases.
        To find out more about a bond forfeiture case, please contact the Harris County District Clerk's Office.
      </Text>
    </Modal>
  )
}