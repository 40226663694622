import React from 'react';
// remove after DPS testing
import { useLocation } from 'react-router';
import styled from 'styled-components';
import {
  FORM_ID_411_072,
  FORM_ID_411_0735,
  FORM_ID_411_0736
} from '../../constants/cases';
import { BG_DARKER, BG_PRIMARY, MAX_DESKTOP_WIDTH } from '../../cssVars';

const Wrapper = styled.div`
  > div: {
    border-top: ${({ hideBorderLines }) =>
      hideBorderLines ? 'none' : '1px solid #EBEAE4'};
  }
  > div:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  > div:nth-child(odd) {
    background-color: ${BG_PRIMARY};
  }
  > div:nth-child(even) {
    background-color: ${BG_DARKER};
  }
  > div:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    font-weight: 600;
  }
  padding: 8px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
  }
`;

// The `waiting_period_start` field means different things depending on the case
const WaitingPeriodStartDate = ({ caseInfo }) => {
  const {
    waiting_period_start,
    ndo_form,
    final_eligibility,
    final_pot_elig_411_0736,
    final_pot_elig_411_0727
  } = caseInfo;

  // Only show if waiting_period_start is nonnull & the case is eligible
  if (waiting_period_start == null || final_eligibility !== 'nondisclosure') {
    return null;
  }

  // Show as date of sentence completion for 411.0735 and 411.0736
  if (
    ndo_form === FORM_ID_411_0735 ||
    ndo_form === FORM_ID_411_0736 || final_pot_elig_411_0736
  ) {
    return (
      <LineItem>
        <div>Date of sentence completion</div>
        <div>{waiting_period_start}</div>
      </LineItem>
    );
  }

  // Show as discharge and dismissal for 411.072 and 411.0727
  if (ndo_form === FORM_ID_411_072 || final_pot_elig_411_0727) {
    return (
      <LineItem>
        <div>Date of discharge and dismissal</div>
        <div>{waiting_period_start}</div>
      </LineItem>
    );
  }

  return null;
};

// Displays full case information
export const FullCaseContent = ({ caseInfo, hideBorderLines }) => {
  const {
    casID,
    current_offense_name,
    current_charge_level,
    conviction_date,
    sentence,
    fine,
    full_name,
    fda,
    crt,
    da_start,
    da_end,
    probation_start,
    probation_end,
    // remove after DPS testing
    dps_ineligible_offense,
    dps_unknown_offense,
    dps_ineligible,
    dps_unknown_disposition_or_charge_lvl
  } = caseInfo;

  // remove after DPS testing
  const { pathname } = useLocation();

  return (
    <Wrapper hideBorderLines={hideBorderLines}>
      <LineItem>
        <div>Cause No.</div>
        <div>{casID}</div>
      </LineItem>
      <LineItem>
        <div>Offense name</div>
        <div>{current_offense_name}</div>
      </LineItem>
      <LineItem>
        <div>Charge level</div>
        <div>{current_charge_level}</div>
      </LineItem>
      <LineItem>
        <div>Defendant name</div>
        <div>{full_name}</div>
      </LineItem>
      {sentence && (
        <LineItem>
          <div>Sentence</div>
          <div>{sentence}</div>
        </LineItem>
      )}
      {fine && (
        <LineItem>
          <div>Fine</div>
          <div>{fine}</div>
        </LineItem>
      )}
      <LineItem>
        <div>Filing date</div>
        <div>{fda}</div>
      </LineItem>
      {conviction_date && (
        <LineItem>
          <div>Conviction date</div>
          <div>{conviction_date}</div>
        </LineItem>
      )}
      {da_start && (
        <LineItem>
          <div>Start of deferred adjudication</div>
          <div>{da_start}</div>
        </LineItem>
      )}
      {da_end && (
        <LineItem>
          <div>End of deferred adjudication</div>
          <div>{da_end}</div>
        </LineItem>
      )}
      {probation_start && (
        <LineItem>
          <div>Start of probation</div>
          <div>{probation_start}</div>
        </LineItem>
      )}
      {probation_end && (
        <LineItem>
          <div>End of probation</div>
          <div>{probation_end}</div>
        </LineItem>
      )}
      <WaitingPeriodStartDate caseInfo={caseInfo} />
      <LineItem style={{ marginBottom: '15px' }}>
        <div>Court number</div>
        <div>{crt}</div>
      </LineItem>
    </Wrapper>
  );
};
