import { 
  BAC_UNDER_015, 
  COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM, 
  COMMITTED_AFTER_SUBMITTING_PETITION, 
  HAD_COMMERCIAL_LICENSE_OR_PERMIT, 
  USED_IGNITION_INTERLOCK,
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
} from "../constants/cases";
import { CURRENTLY_HAS_ID, PREVIOUSLY_HAD_ID } from "../constants/formOptions";
import { contrast, isValidHexcode } from "./helpers";

const isValidEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const isValidPhone = (phone) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
const isValidZipcode = (zipcode) => /^\d{5}(?:[-\s]\d{4})?$/.test(zipcode);
const isValidSsnFour = (ssnFour) => /^\d{4}$/.test(ssnFour);
const isOnlyNumbers = (str) => /^[0-9]*$/.test(str);
const isValidMonetaryInput = (amt) => /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d+)?$/.test(amt);
const isEmptyStringOrNull = (val) => val == null || val === "";

export const validateInitialForm = (values) => {
  const errors = {};

  // required string fields
  const requiredFields = ['firstName', 'lastName', 'dob'];

  requiredFields.forEach(fieldName => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required'
    }
  })

  if (values.agreeToTerms === false) {
    errors.agreeToTerms = 'Must agree to terms to continue'
  }


  return errors;
};

export const validateSPNPage = (values) => {
  const errors = {};

  if (isEmptyStringOrNull(values.spn)) {
    errors.spn = 'Required'
  }


  return errors;
};

export const validatePersonalInfo = (personalInfoConfig) => (values) => {
  const errors = {};
  const allFields = personalInfoConfig && personalInfoConfig.fields ? personalInfoConfig.fields : null;
  const fieldIsVisible = field => allFields && allFields[field] && allFields[field].visible;

  if (fieldIsVisible('phone') && !isValidPhone(values.phone)) {
    errors.phone = 'Invalid formatting (xxx-xxx-xxxx)'
  }

  if (fieldIsVisible('caseManagerContactPhone') && !isEmptyStringOrNull(values.caseManagerContactPhone) && !isValidPhone(values.caseManagerContactPhone)) {
    errors.caseManagerContactPhone = 'Invalid formatting (xxx-xxx-xxxx)'
  }

  if (fieldIsVisible('caseManagerContactEmail') && !isEmptyStringOrNull(values.caseManagerContactEmail) && !isValidEmail(values.caseManagerContactEmail)) {
    errors.caseManagerContactEmail = 'Invalid formatting'
  }

  if (fieldIsVisible('email') && !isValidEmail(values.email)) {
    errors.email = 'Invalid formatting'
  }

  if (fieldIsVisible('zipcode') && !isValidZipcode(values.zipcode)) {
    errors.zipcode = 'Invalid formatting'
  }

  if (fieldIsVisible('ssnFour') && !isValidSsnFour(values.ssnFour)) {
    errors.ssnFour = 'Must be 4 numbers'
  }

  const setRequiredIfNull = (fieldId) => {
    if (isEmptyStringOrNull(values[fieldId])) {
      errors[fieldId] = 'Required'
    }
  }

  // First and last names are always required, replaces 'legalName'
  const reqNameFields = ['firstName', 'lastName'];
  reqNameFields.forEach(setRequiredIfNull)

  // Address info fields
  if (allFields && allFields.addressInfo.visible) {
    const reqAddressFields = ['streetAddress', 'city', 'state', 'zipcode'];
    reqAddressFields.forEach(setRequiredIfNull)
  }

  if (allFields && allFields.driversLicense.visible) {
    if (!values.idStatus) {
      errors.idStatus = 'Required'
    } else if (!values.idState && (values.idStatus === PREVIOUSLY_HAD_ID || values.idStatus === CURRENTLY_HAS_ID)) {
      errors.idState = 'Required'
    } else if (!values.idNumber && values.idStatus === CURRENTLY_HAS_ID) {
      errors.idNumber = 'Required'
    }
  }
  
  // fields that are never required, or can be required but have different error fields
  const nonRequiredFields = [
    'legalName', 'additionalNames', 'addressInfo', 'driversLicense', 'applicationAssistant', 
    'caseManagerContactName', 'caseManagerContactEmail', 'caseManagerContactPhone' ];

  // If any visible question is not filled out, return a required message
  if (allFields) {
    Object.keys(allFields).forEach(fieldId => {
      if (!nonRequiredFields.includes(fieldId) && allFields[fieldId].visible && isEmptyStringOrNull(values[fieldId])) {
        errors[fieldId] = 'Required'
      }
    })
  }

  return errors;
};

export const validateIncomeAndAssets = (incomeAndAssetsConfig) => (values) => {
  const errors = {};
  const allFields = incomeAndAssetsConfig && incomeAndAssetsConfig.fields ? incomeAndAssetsConfig.fields : null;
  const fieldIsVisible = field => allFields && allFields[field] && allFields[field].visible;

  const monetaryFields = ['wages', 'wagesHousehold', 'unemployment', 'snap', 'retirement', 
  'publicHousingAssistance', 'childSpousalSupport', 'ssi'];
  monetaryFields.forEach((field) => {
    if (fieldIsVisible(field) && !isValidMonetaryInput(values[field])) {
      errors[field] = 'Must be valid monetary amount';
    }
  })

  // check dependents array
  values.dependents.forEach((dep, index) => {
    if (dep.name || dep.age || dep.relationship) {
      if (!dep.name) {
        errors[`dependents.${index}.name`] = 'Required'
      }
      if (!dep.age) {
        errors[`dependents.${index}.age`] = 'Required'
      } else if (!isOnlyNumbers(dep.age)) {
        errors[`dependents.${index}.age`] = 'Must be a numeric value'
      }
      if (!dep.relationship) {
        errors[`dependents.${index}.relationship`] = 'Required'
      }
    }
  })

  if (allFields.additionalHome.visible) {
    if (isEmptyStringOrNull(values.hasAdditionalHome)) {
      errors.hasAdditionalHome = 'Required'
    } else if (values.hasAdditionalHome) {
      if (!values.homeValue) {
        errors.homeValue = 'Required'
      } else if (!isValidMonetaryInput(values.homeValue)) {
        errors.homeValue = 'Must be valid monetary amount'
      }
    }
  }

  if (allFields.additionalVehicle.visible) {
    if (isEmptyStringOrNull(values.hasAdditionalVehicle)) {
      errors.hasAdditionalVehicle = 'Required'
    } else if (values.hasAdditionalVehicle) {
      if (!values.vehicleValue) {
        errors.vehicleValue = 'Required'
      } else if (!isValidMonetaryInput(values.vehicleValue)) {
        errors.vehicleValue = 'Must be valid monetary amount'
      }
    }
  }

  if (allFields.otherAssets.visible) {
    if (isEmptyStringOrNull(values.hasOtherAssets)) {
      errors.hasOtherAssets = 'Required'
    } else if (values.hasOtherAssets) {
      if (!values.otherAssetsValue) {
        errors.otherAssetsValue = 'Required'
      } else if (!isValidMonetaryInput(values.otherAssetsValue)) {
        errors.otherAssetsValue = 'Must be valid monetary amount'
      }
    }
  }
  
  // // fields that are never required, or can be required but have different error fields
  const nonRequiredFields = ['dependents', 'additionalHome', 'additionalVehicle', 'otherAssets'];

  // If any visible question is not filled out, return a required message
  if (allFields) {
    Object.keys(allFields).forEach(fieldId => {
      if (!nonRequiredFields.includes(fieldId) && allFields[fieldId].visible && isEmptyStringOrNull(values[fieldId])) {
        if (monetaryFields.includes(fieldId)) {
          errors[fieldId] = 'Required, please enter 0 if this field does not apply to you'
        } else {
          errors[fieldId] = 'Required'
        }
      }
    })
  }

  return errors;
};

export const validateExpenses = (expensesConfig) => (values) => {
  const errors = {};
  const allFields = expensesConfig && expensesConfig.fields ? expensesConfig.fields : null;
  const fieldIsVisible = field => allFields && allFields[field] && allFields[field].visible;

  const monetaryFields = ['rent', 'utilities', 'food', 'childCare', 'childSpousalSupport', 'automobileExpenses',
    'transportationExpenses', 'attorneyFees', 'insurance', 'medical', 'laundry', 'misc'];
  monetaryFields.forEach((field) => {
    if (fieldIsVisible(field) && !isValidMonetaryInput(values[field])) {
      errors[field] = 'Must be valid monetary amount';
    }
  })


  // fields that are never required, or can be required but have different error fields
  const nonRequiredFields = ['otherExpensesInfo', 'housingInfo'];

  // If any visible question is not filled out, return a required message
  if (allFields) {
    Object.keys(allFields).forEach(fieldId => {
      if (!nonRequiredFields.includes(fieldId) && allFields[fieldId].visible && isEmptyStringOrNull(values[fieldId])) {
        if (monetaryFields.includes(fieldId)) {
          errors[fieldId] = 'Required, please enter 0 if this field does not apply to you'
        } else {
          errors[fieldId] = 'Required'
        }
      }
    })
  }

  return errors;
};

export const validateConfirmCases = (values) => {
  const errors = {};

  if (values.areTheseYourCases == null) {
    errors.areTheseYourCases = 'Required'

  }

  return errors;
}

export const validateFollowupQuestions = (currCase) => (values) => {
  
  // Loop through all values
  const errors = Object.keys(values).reduce((errorsObj, valueId) => {

    // Initially set all values that are "null" to have "Required" error
    if (values[valueId] == null) {
        errorsObj[valueId] = "Required";
    }

    // We only want to ask about the ignition interlock if they are
    // flagged as potentially eligible (if the timing is between the shortened and full waiting period) and have a qualifying BAC
    const skipIgnitionInterlock = values[BAC_UNDER_015] === false || (!currCase[FINAL_POT_ELIG_411_0731] && !currCase[FINAL_POT_ELIG_411_0736]);

    // If the value is a child (we only show it if a parent is true)
    // and the parent is false, remove error for it
    if ( (valueId === HAD_COMMERCIAL_LICENSE_OR_PERMIT && values[BAC_UNDER_015] === false)
      || (valueId === USED_IGNITION_INTERLOCK && skipIgnitionInterlock)
      || (valueId === COMMITTED_AFTER_SUBMITTING_PETITION && values[COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM] === false)) {
      delete errorsObj[valueId];
    } 

    return errorsObj;
  }, {});

  return errors;
};

export const validateEmails = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateLogin = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required'
  }
  return errors;
}

export const validateForgotPassword = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateResetPassword = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Invalid password, must be 8 characters or more';
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = "Passwords must match"
  }
  return errors;
}

export const validateSettings = (values) => {
  const errors = {};
  if (!isValidHexcode(values.themeColor)) {
    errors.themeColor = 'Hexcode is not valid format';
  } else if (contrast(values.themeColor, '#FFFFFF') < 3) {
    errors.themeColor = 'Please choose a darker color to provide enough contrast for white text';
  }
  if (!values.confirmationText) {
    errors.confirmationText = 'Required'
  }
  return errors;
}

export const validateAddUser = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  return errors;
}
