import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setExportModal } from '../../actions/modals';
import { EXPORT_ALL, EXPORT_NEW } from '../../constants/general';
import { useMarkAsExported } from '../../hooks/editors';
import { showExportSelector } from '../../selectors/modals';
import { Button, InvertedButton } from './Common';
import { Modal } from './Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const Text = styled.p`
  margin: 20px 0px;
`

export const ExportModal = () => {

  const exportType = useSelector(showExportSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setExportModal(null));
  }

  const [submit, submitting] = useMarkAsExported(onClose);

  if (exportType == null) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>Success! Mark as exported?</Title>
      <Text>Please note: if you're not seeing anything download, your browser may be prompting you for a confirmation message.</Text>
      <Text style={{ marginBottom: '30px'}}>
        Do you want to mark {exportType === EXPORT_ALL ? 'all' : 'these'} applications and cases as exported? 
        {exportType === EXPORT_NEW && <>
          {` `}If so, they will be shifted from the <b>new applications and cases</b> category to the <b>previously exported</b> category.
        </>}
      </Text>
      <InvertedButton onClick={onClose}>No, don't mark</InvertedButton>
      <Button onClick={submit} isLoading={submitting}>Yes, mark as exported</Button>
    </Modal>
  )
}