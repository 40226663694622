import React from 'react';
import styled from 'styled-components';
import { TEXT_PRIMARY, PRIMARY, PRIMARY_DARK } from '../../cssVars.js';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { BeigeTile } from '../general/BeigeTile';
import { ApplicationQuestionsEditor } from './ApplicationQuestionsEditor';
import { useColors } from '../../hooks/useColors.js';
import { GeneralEditor } from './GeneralEditor.js';

const Wrapper = styled.div`
  width: 100%;
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 25%;
  text-align: center;
  padding: 5px 0px;
  font-weight: ${({ selected }) => selected ? 800 : 400};
  color: ${({ selected, colors }) => selected ? (colors.primaryDark || PRIMARY_DARK) : TEXT_PRIMARY};
  border-bottom: 3px solid  ${({ selected, colors }) => selected ? (colors.primary || PRIMARY) : 'grey'}; 
  &:focus {
    box-shadow: none;
  }
`

export const TermsAndConditionsEditor = () => {
  return <div>TANDC</div>
}

export const ConfigEditorNav = () => {
  const colors = useColors();
  const { orgSlug, configPageId } = useParams();
  return (
    <Nav>
      <StyledLink colors={colors} to={`/${orgSlug}/admin/configs/general`} selected={configPageId === "general"}>
        General
      </StyledLink>
      <StyledLink colors={colors} to={`/${orgSlug}/admin/configs/applicationQuestions`} selected={configPageId === "applicationQuestions"}>
        Application Questions
      </StyledLink>
      <StyledLink colors={colors} to={`/${orgSlug}/admin/configs/termsAndConditions`} selected={configPageId === "termsAndConditions"}>
        Terms and Conditions
      </StyledLink>
    </Nav>
  )
}

export const ConfigEditor = () => {
  return (
    <Wrapper>   
      <BeigeTile width={1000}>
        <Route path={"/:orgSlug/admin/configs/:configPageId"} component={ConfigEditorNav} />
        <Switch>
          <Route path={"/:orgSlug/admin/configs/termsAndConditions"} component={TermsAndConditionsEditor} />
          <Route path={"/:orgSlug/admin/configs/applicationQuestions"} component={ApplicationQuestionsEditor} />
          <Route path={"/:orgSlug/admin/configs/general"} component={GeneralEditor} />
          <Redirect to={"/:orgSlug/admin/configs/general"} />
        </Switch>
      </BeigeTile>  
    </Wrapper>
  )
}