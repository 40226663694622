import { AGREE_TO_TERMS } from '../actions/general';

const defaultState = {
  agreeToTerms: false, 
}

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AGREE_TO_TERMS:
      return {
        ...state,
        agreeToTerms: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;