import React from 'react';
import styled from 'styled-components';
import { SHADOW_TINY } from '../../cssVars';
import eligibleIcon from '../../assets/eligible.svg';
import ineligibleIcon from '../../assets/ineligible.svg';
import notYetEligibleIcon from '../../assets/not_yet_eligible.svg';
import couldNotDetermineIcon from '../../assets/could_not_determine.svg';
import { TextLink } from '../general/Common';
import { SITE_URL } from '../../constants/general';

const EligibilityTile = styled.div`
  border-radius: 5px;
  padding: ${({ large }) => (large ? '10px 13px' : '8px')};
  margin-bottom: 5px;
  background-color: #9edfc4;
  box-shadow: ${SHADOW_TINY};
`;

const SecondaryText = styled.div`
  margin-top: 5px;
  font-size: 17px;
`;

const PrimaryText = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ large }) => (large ? 600 : 500)};
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 10px;
`;
/*
  Description for components below:
    These are colorful divs used on case cards & modals to display eligibility status. 
    If `large` prop is true, additional text is shown.
*/

// Tile for eligible cases, with green coloring.
export const EligibleTile = ({ large, noExtraText, followupBlurb }) => (
  <EligibilityTile
    large={large}
    style={{
      backgroundColor: '#9EDFC4',
      color: '#0A4439',
      border: '2px solid #9EDFC4'
    }}
  >
    <PrimaryText large={large}>
      Likely eligible for nondisclosure
      <Icon src={eligibleIcon} alt="green checkmark" />
    </PrimaryText>
    {large && !noExtraText && (
      <SecondaryText>
        This case may be able to be sealed from your record.
      </SecondaryText>
    )}
    {followupBlurb && <SecondaryText>{followupBlurb}</SecondaryText>}
  </EligibilityTile>
);

// Tile for ineligible cases, with red coloring.
export const IneligibleTile = ({ large, noExtraText, followupBlurb }) => (
  <EligibilityTile
    large={large}
    style={{
      backgroundColor: '#E6BCB5',
      color: '#6E1100',
      border: '2px solid #E6BCB5'
    }}
  >
    <PrimaryText large={large}>
      Likely ineligible
      <Icon src={ineligibleIcon} alt="" />
    </PrimaryText>
    {large && !noExtraText && (
      <SecondaryText>
        {`Based on the information provided and our current records, this offense is likely ineligible for nondisclosure or expunction. `}
        <TextLink
          style={{ color: '#6E1100', fontWeight: 600 }}
          to="https://texaslawhelp.org/article/what-nondisclosure-order-and-how-do-i-get-one#toc-5"
        >
          Learn more about eligibility requirements
        </TextLink>
      </SecondaryText>
    )}
    {followupBlurb && <SecondaryText>{followupBlurb}</SecondaryText>}
  </EligibilityTile>
);

// Tile for expunction eligible cases, with yellow coloring.
export const ExpunctionEligibleTile = ({
  large,
  noExtraText,
  followupBlurb
}) => (
  <EligibilityTile
    large={large}
    style={{
      backgroundColor: '#9EDFC4',
      color: '#0A4439',
      border: '2px solid #9EDFC4'
    }}
  >
    <PrimaryText large={large}>
      Likely eligible for expunction
      <Icon src={eligibleIcon} alt="green checkmark" />
    </PrimaryText>
    {large && !noExtraText && (
      <SecondaryText>
        {`Based on the information provided and our current records, this offense may be eligible for expunction.`}
        site.{' '}
        <TextLink
          style={{ color: '#0A4439', fontWeight: 600 }}
          to={`${SITE_URL}/info/nondisclosure-expunction`}
        >
          Learn more about the difference between nondisclosure and expunction.
        </TextLink>
      </SecondaryText>
    )}
    {followupBlurb && <SecondaryText>{followupBlurb}</SecondaryText>}
  </EligibilityTile>
);

// Tile for not-yet-eligible cases, with yellow coloring (CURRENTLY NOT USED).
export const NotYetEligibleTile = ({ large, followupBlurb }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A' }}
  >
    <PrimaryText large={large}>
      Not yet eligible for nondisclosure. May become eligible on [TODO]
      <Icon src={notYetEligibleIcon} alt="" />
    </PrimaryText>
    {followupBlurb && <SecondaryText>{followupBlurb}</SecondaryText>}
  </EligibilityTile>
);

// Tile for cases that we couldn't fully determine the eligibility for, with yellow coloring
export const CouldNotDetermineTile = ({ large, followupBlurb }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A' }}
  >
    <PrimaryText large={large}>
      Ineligible unless special conditions are met.
      <Icon src={notYetEligibleIcon} alt="" />
    </PrimaryText>
    {followupBlurb && <SecondaryText>{followupBlurb}</SecondaryText>}
  </EligibilityTile>
);

// Tile for cases that are eligible for nondisclosure but have a DPS case that may affect eligiblity but we don't have enough information about
export const DPSEligibilityUnknownTile = ({ large }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A' }}
  >
    <PrimaryText large={large}>
      This applicant has DPS case(s) that may affect the eligiblity of this
      case.
      <Icon src={notYetEligibleIcon} alt="" />
    </PrimaryText>
    <SecondaryText>
      DPS case(s) were found, but did not contain enough information to
      determine eligibility.
    </SecondaryText>
  </EligibilityTile>
);
