import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setCase, setIneligibleCasesModal } from '../../actions/modals';
import { MAX_DESKTOP_WIDTH } from '../../cssVars';
import { purelyIneligibleCasesSelector } from '../../selectors/entities';
import { showIneligibleCasesSelector } from '../../selectors/modals';
import { CaseCard } from './CaseCard';
import { StyledHR } from './Common';
import { Modal } from './Modal';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

const CaseWrapper = styled.div`
  padding: 0px 15px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding: 0px;
  }
`

export const IneligibleCasesModal = () => {

  const showIneligibleCases = useSelector(showIneligibleCasesSelector);
  const ineligibleCases = useSelector(purelyIneligibleCasesSelector);
  const dispatch = useDispatch();

  const onClose = () => dispatch(setIneligibleCasesModal(false));
  const clickCase = (currCase) => () => dispatch(setCase(currCase));

  if (!showIneligibleCases) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 650px)' }}>
      <Title>Likely Ineligible Cases ({ineligibleCases.length})</Title>
      These are the cases found in Harris County records that are likely ineligible for nondisclosure or expunction.
      <StyledHR style={{ marginBottom: '0px' }} />
      <CaseWrapper>
        {ineligibleCases.map((currCase, idx) => (
          <CaseCard onClick={clickCase(currCase)} key={`case${idx}`} caseInfo={currCase} />
        ))}
      </CaseWrapper>
    </Modal>
  )
}