export const labelById = {
  personalInfo: 'Personal info',
  incomeAndAssets: 'Income',
  expenses: 'Expenses',
  criminalHistory: 'History',
  review: 'Review',
  eligibility: 'Eligibility',
}

export const shortLabelById = {
  personalInfo: 'Personal info',
  incomeAndAssets: 'Income',
  expenses: 'Expenses',
  criminalHistory: 'History',
  review: 'Review',
  eligibility: 'Eligibility',
}

export const longLabelById = {
  personalInfo: 'Overview of the nondisclosure process',
  eligibility: 'Fill out petition',
  incomeAndAssets: 'Income and assets',
  expenses: 'Expenses',
  review: 'Review',
}
