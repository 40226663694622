import React, { useState } from 'react';
import styled from 'styled-components';
import { TEXT_PRIMARY, PRIMARY } from '../../cssVars.js';
import { FieldArray, useFormikContext } from 'formik';
import { CheckboxField, VisibilityField } from '../general/FormFields';
import crossIcon from '../../assets/cross.svg'
import chevronIcon from '../../assets/chevron.svg'
import { useColors } from '../../hooks/useColors';
import { SettingsHeader } from './SettingsEditor';


const PageTile = styled.div`
  padding: 15px 20px;
  margin: 15px 0px;
  border: 1px solid ${({ visible }) => visible ? 'grey' : 'lightgrey'};
  background-color:  ${({ visible }) => visible ? '#FFF' : '#F4F4F4'};
  color:  ${({ visible }) => visible ? TEXT_PRIMARY : 'grey'};
  border-radius: 4px;
  width: 750px;
  @media only screen and (max-width: ${1200}px) {
    width: 95%;
  }
`

const PageHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${({hideBorder}) => hideBorder ? '0px' : '15px'};
  padding-bottom: ${({hideBorder}) => hideBorder ? '0px' : '15px'};
  border-bottom: ${({hideBorder}) => hideBorder ? 'none' : '1px solid lightgrey'};

  > div:first-child {
    margin-right: auto;
    display: flex;
    align-items: center;
    > div:first-child {
      font-size: 20px;
      margin-right: 15px;
    }
  }
`

const QuestionTile = styled.div`
  padding: 10px 15px;
  margin: 10px 0px;
  border: 1px solid ${({ visible }) => visible ? '#BEBEBE' : 'lightgrey'};
  background-color:  ${({ visible }) => visible ? '#FFF' : '#F4F4F4'};
  color:  ${({ visible }) => visible ? TEXT_PRIMARY : 'grey'};
  border-radius: 4px;
  > div:nth-child(2) {
    color: ${({ visible }) => visible ? 'grey' : '#BEBEBE'};
    font-size: 17px;
  }
`

const QuestionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  > div:first-child {
    margin-right: auto;
    font-weight: 600;
  }
`

const Break = styled.div`
  border-top: 1px solid lightgrey;
  margin: 10px 0px;
  margin-bottom: 15px;
`

const AdditionalConfigurationWrapper = styled.div`
  font-size: 17px;
  opacity: ${({ visible }) => visible ? 1 : 0.5};
`

const OptionsContainer = styled.div`
  padding: 10px;
  background-color: #F1F1F1;
  margin: 8px 0px;
  border-radius: 6px;
`;

const OptionBubble = styled.div`
  border-radius: 20px;
  font-weight: 600;
  color: #fff;
  background-color: ${({primary}) => primary || PRIMARY};
  padding: 4px 12px;
  padding-right: 6px;
  margin-right: 5px;
  margin-top: 4px;
  display: inline-flex;
  align-items: center;
`

const OptionInput = styled.input`
  background-color: transparent;
  border: none;
  min-width: 100px;
  width: ${({width}) => width || 100}px;
  &:focus{
    border: none !important;
    box-shadow: none;
  }
  &:active{
    border: none !important;
    box-shadow: none;
  }
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 4px;
  margin: 0px;
  margin-left: 10px;
  > img {
    width: 17px;
    height: 17px;
    transform: rotate(${({collapsed}) => collapsed ? -90 : 0}deg);
  }
`

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  margin-left: 4px;
`

const CrossIcon = styled.img`
  width: 11px;
  height: 11px;
`

const SillyHashPadding = styled.div`
  padding-top: 30px;
  margin-top: -30px;
`

const EditQuestion = ({ fieldId, fieldPrefix, title, description, children, hidingDisabled }) => {
  const {values} = useFormikContext();
  const getFieldValue = (fieldId, fieldType) => {
    if (!values[fieldPrefix] || !values[fieldPrefix].fields[fieldId]) {
      return false;
    }
    return values[fieldPrefix] && values[fieldPrefix].fields[fieldId][fieldType];
  }

  return (
    <QuestionTile visible={getFieldValue(fieldId, 'visible')}>
      <QuestionHeader>
        <div>{title}</div>
        <VisibilityField disabled={hidingDisabled} pageOrQ="question" name={`${fieldPrefix}.fields.${fieldId}.visible`} />
      </QuestionHeader>
      <div>{description}</div>
      {children}
    </QuestionTile>
  )
}

const BubbleOptions = ({ fieldId, fieldPrefix, skipOther }) => {
  const { primary } = useColors();
  const {values} = useFormikContext();
  const [bubbleInput, setBubbleInput] = useState('');
  const bubbleInputId = `bubbleInput_${fieldId}`
  const getFieldName = (fieldId, fieldType) => `${fieldPrefix}.fields.${fieldId}.${fieldType}`;
  const getFieldValue = (fieldId, fieldType) => values[fieldPrefix] && values[fieldPrefix].fields[fieldId][fieldType];

  const focusOnInput = (id) => {
    document.getElementById(id).focus();
    document.getElementById(id).select();
  }
  const handleEnterPress = (e, callback) => {
    // enter key is pressed
    if (e.keyCode === 13) {
      callback();
    }
  }

  return (
    <AdditionalConfigurationWrapper visible={getFieldValue(fieldId, 'visible')} >
      <OptionsContainer>
        <FieldArray
          name={getFieldName(fieldId, 'options')}
          render={arrayHelpers => {
            const addCurrentOption = () => {
              arrayHelpers.push(bubbleInput);
              setBubbleInput('');
            };
            return (
              <div onClick={() => focusOnInput(bubbleInputId)}>
                {getFieldValue(fieldId, 'options').map((opt, index) => (
                    <OptionBubble primary={primary} key={`${opt}-${fieldId}`} onClick={(e) => e.stopPropagation()}>
                      {opt}
                      <RemoveButton type="button" onClick={() => { arrayHelpers.remove(index);}}>
                        <CrossIcon src={crossIcon} alt="white x mark" />
                      </RemoveButton>
                    </OptionBubble>
                  ))
                }
                <OptionInput 
                  id={bubbleInputId}
                  value={bubbleInput}
                  width={bubbleInput.length * 10} 
                  onKeyUp={e => handleEnterPress(e, addCurrentOption)}
                  onBlur={() => bubbleInput.length > 0 && addCurrentOption()}
                  onChange={(e) => setBubbleInput(e.target.value)} />
              </div>)
            }
          }
        />
      </OptionsContainer>
      {fieldId === 'gender' 
        ? <CheckboxField name={getFieldName(fieldId, 'includePreferToSelfDescribe')}>
            <label style={{ marginLeft: '6px' }}>
            Include "Prefer to self-describe" option with free-text input
            </label>
          </CheckboxField>
        : skipOther
        ? null
        : <CheckboxField name={getFieldName(fieldId, 'includeOther')}>
            <label style={{ marginLeft: '6px' }}>
            Include "Other" option with free-text input
            </label>
          </CheckboxField>}
    </AdditionalConfigurationWrapper>
  )
}

const PageIsHiddenBlurb = ({hide}) => hide ? null : <div style={{justifySelf: 'flex-start'}}>
  <i>Page is hidden and will not be shown on application.</i>
</div>

const PageIsHiddenQuestionBlurb = () => <div>
  Questions are not shown while page is hidden. Click the eye icon to reveal the page.
</div>

const PersonalInfoQuestions = () => {
  const { values } = useFormikContext();
  const [collapsed, setCollapsed] = useState(true);
  const pageIsVisible = values.personalInfo && values.personalInfo.visible;
  return (
    <PageTile visible={pageIsVisible}>
      <PageHeader hideBorder={collapsed}>
        <div>
          <SillyHashPadding style={{ fontWeight: 700 }} id="personal-info-page">Personal Info Page</SillyHashPadding>
          <PageIsHiddenBlurb hide={pageIsVisible} />
        </div>
        <VisibilityField disabled pageOrQ="page" name="personalInfo.visible" />
        <ToggleButton collapsed={collapsed} type="button" onClick={() => setCollapsed(!collapsed)}>
          <img src={chevronIcon} alt={`black chevron pointing ${collapsed ? 'right' : 'down'}`} />
        </ToggleButton>
      </PageHeader>
        {!collapsed &&
          (pageIsVisible ? 
        <div>
          <div style={{marginBottom: '15px'}}>Primary Questions</div>
          <EditQuestion hidingDisabled fieldId="legalName" fieldPrefix="personalInfo" title="Legal Name" 
                        description="Asks for first, middle (optional), last name, and suffix (optional)" />
          <EditQuestion fieldId="additionalNames" fieldPrefix="personalInfo" title="Other Names" 
                        description="Asks for other first and last names the applicant has used." />
          <EditQuestion hidingDisabled fieldId="dob" fieldPrefix="personalInfo" title="Date of Birth" 
                        description="Asks for date of birth (month, date, and year)" />
          <div style={{margin: '15px 0px'}}>Contact Info</div>
          <EditQuestion fieldId="phone" fieldPrefix="personalInfo" title="Phone" 
                        description="Asks for user's phone number" />
          <EditQuestion fieldId="email" fieldPrefix="personalInfo" title="Email" 
                        description="Asks for user's email address" />
          <EditQuestion fieldId="addressInfo" fieldPrefix="personalInfo" title="Address" 
                        description="Asks for city, street, zipcode for user" />
          <EditQuestion fieldId="ssnFour" fieldPrefix="personalInfo" title="SSN (last 4 digits)" 
                        description="Asks for last 4 digits of user's social security number (SSN)." />
          <EditQuestion fieldId="driversLicense" fieldPrefix="personalInfo" title="Driver's License/ID" 
                        description="Asks for user's driver's license or state ID number." />
          <div style={{margin: '15px 0px'}}>Additional Info</div>
          <EditQuestion fieldId="gender" fieldPrefix="personalInfo" title="Your Gender" 
                        description="Asks applicant to select gender from a list.">
            <Break />
            <BubbleOptions fieldId="gender" fieldPrefix="personalInfo" />
          </EditQuestion>
          <EditQuestion fieldId="race" fieldPrefix="personalInfo" title="Your Race/Ethnicity" 
                        description="Asks applicant to select race/ethnicity from a list (allows multiple to be selected).">
            <Break />
            <BubbleOptions fieldId="race" fieldPrefix="personalInfo" />
          </EditQuestion>
          <EditQuestion fieldId="citizenship" fieldPrefix="personalInfo" title="United States Citizen" 
                        description="Asks whether or not user is a citizen of the United States." />
          <EditQuestion fieldId="specialCategories" fieldPrefix="personalInfo" title="Special categories" 
                        description='Asks "Do any of the following apply to you?" as an optional question.'>
            <Break />
            <BubbleOptions fieldId="specialCategories" fieldPrefix="personalInfo" skipOther={true} />
          </EditQuestion>
          <EditQuestion fieldId="maritalStatus" fieldPrefix="personalInfo" title="Marital Status" 
                        description="Asks whether or not user is married, divorced, single, or widowed." />
          <EditQuestion fieldId="isVeteran" fieldPrefix="personalInfo" title="Veteran" 
                        description="Asks whether or not user or an immediate family member is a veteran." />
          <EditQuestion fieldId="referralSource" fieldPrefix="personalInfo" title="How did you hear about us?" 
                        description="Asks applicant to select how they heard about this site">
            <Break />
            <BubbleOptions fieldId="referralSource" fieldPrefix="personalInfo" />
          </EditQuestion>
          <div style={{margin: '15px 0px'}}>Application Assistant</div>
          <EditQuestion fieldId="applicationAssistant" fieldPrefix="personalInfo" title="Assistant name" 
                        description='Optional field, asks "Are you filling out this application for someone else? If so, please enter your name below."' />

          <div style={{margin: '15px 0px'}}>Case Manager Contact Information</div>
          <EditQuestion fieldId="caseManagerContactName" fieldPrefix="personalInfo" title="Case manager's name" 
                        description="Optional field for adding the case manager's name" />
          <EditQuestion fieldId="caseManagerContactPhone" fieldPrefix="personalInfo" title="Case manager's phone" 
                        description="Optional field for adding the case manager's phone" />
          <EditQuestion fieldId="caseManagerContactEmail" fieldPrefix="personalInfo" title="Case manager's email" 
                        description="Optional field for adding the case manager's email" />
        </div> : <PageIsHiddenQuestionBlurb />)
      }
    </PageTile>
  )
}

const IncomeAndAssetsQuestions = () => {
  const { values } = useFormikContext();
  const [collapsed, setCollapsed] = useState(true);
  const pageIsVisible = values.incomeAndAssets && values.incomeAndAssets.visible;
  return (
    <PageTile visible={pageIsVisible}>
      <PageHeader hideBorder={collapsed}>
        <div>
          <SillyHashPadding style={{ fontWeight: 700 }} id="income-assets-page">Income and Assets</SillyHashPadding>
          <PageIsHiddenBlurb hide={pageIsVisible} />
        </div>
        <VisibilityField pageOrQ="page" name="incomeAndAssets.visible" />
        <ToggleButton collapsed={collapsed} type="button" onClick={() => setCollapsed(!collapsed)}>
          <img src={chevronIcon} alt={`black chevron pointing ${collapsed ? 'right' : 'down'}`} />
        </ToggleButton>
      </PageHeader>
        {!collapsed && (pageIsVisible 
        ? <div>
            <EditQuestion fieldId="dependents" fieldPrefix="incomeAndAssets" title="Dependents" 
                          description="Asks user to list name, age, and relationship of all dependents." />

            <div style={{margin: '15px 0px'}}>Monthly Income Questions</div>
            <EditQuestion fieldId="employmentStatus" fieldPrefix="incomeAndAssets" title="Employment status" 
                          description="Asks for user's employment status." />
            <EditQuestion fieldId="wages" fieldPrefix="incomeAndAssets" title="Monthly wages" 
                          description="Asks for user's total monthly work wages" />
            <EditQuestion fieldId="wagesHousehold" fieldPrefix="incomeAndAssets" title="Total monthly household wages" 
                          description="Asks for monthly work wages of any additional household members listed as dependents." />
            <EditQuestion fieldId="unemployment" fieldPrefix="incomeAndAssets" title="Unemployment" 
                          description="Asks user to list monthly total of unemployment benefits received by any/all members of the household." />
            <EditQuestion fieldId="snap" fieldPrefix="incomeAndAssets" title="Food Stamps / SNAP" 
                          description="Asks for user's to list any monthly benefits from Food Stamps / SNAP" />
            <EditQuestion fieldId="retirement" fieldPrefix="incomeAndAssets" title="Retirement / Pension" 
                          description="Asks user to list any monthly retirement / pension income." />
            <EditQuestion fieldId="publicHousingAssistance" fieldPrefix="incomeAndAssets" title="Public Housing Assistance" 
                          description="Asks user to list monthly voucher amount for Public Housing Assistance." />
            <EditQuestion fieldId="childSpousalSupport" fieldPrefix="incomeAndAssets" title="Child/Spousal Support" 
                          description="Asks user to list any child or spousal support that received on a monthly basis." />
            <EditQuestion fieldId="ssi" fieldPrefix="incomeAndAssets" title="SSI/SSDI/VA Disability" 
                          description="Asks user to list any monthly SI/SSDI/VA Disability benefits." />

            <div style={{margin: '15px 0px'}}>Assets</div>
            <EditQuestion fieldId="additionalHome" fieldPrefix="incomeAndAssets" title="Additional home" 
                          description="Asks user if they have an additional home, and for the value of that home." />
            <EditQuestion fieldId="additionalVehicle" fieldPrefix="incomeAndAssets" title="Additional vehicle" 
                          description="Asks user if they have an additional vehicle, and for the value of that vehicle." />
            <EditQuestion fieldId="otherAssets" fieldPrefix="incomeAndAssets" title="Other assets" 
                          description="Asks user if they have other assets (bank accounts, stocks, bonds, other property), and for the value of those assets." />
          </div> : <PageIsHiddenQuestionBlurb />)}
    </PageTile>
  )
}

const ExpensesQuestions = () => {
  const { values } = useFormikContext();
  const [collapsed, setCollapsed] = useState(true);
  const pageIsVisible = values.expenses && values.expenses.visible;
  return (
    <PageTile visible={pageIsVisible}>
      <PageHeader hideBorder={collapsed}>
        <div>
          <SillyHashPadding style={{ fontWeight: 700 }} id="expenses-page">Expenses</SillyHashPadding>
          <PageIsHiddenBlurb hide={pageIsVisible} />
        </div>
        <VisibilityField pageOrQ="page" name="expenses.visible" />
        <ToggleButton collapsed={collapsed} type="button" onClick={() => setCollapsed(!collapsed)}>
          <img src={chevronIcon} alt={`black chevron pointing ${collapsed ? 'right' : 'down'}`} />
        </ToggleButton>
      </PageHeader>
        {!collapsed && (pageIsVisible 
        ? <div>
            <div style={{margin: '15px 0px'}}>Monthly Expenses Questions</div>
            <EditQuestion fieldId="rent" fieldPrefix="expenses" title="Mortgage/Rent" 
                          description="Asks user to list their monthly mortgage/rent payments, including housing vouchers." />
            <EditQuestion fieldId="housingInfo" fieldPrefix="expenses" title="Additional Housing Information" 
                description="Asks user to specify any special circumstances about their housing situation." />
            <EditQuestion fieldId="utilities" fieldPrefix="expenses" title="Utilities" 
                description="Asks user to list total monthly phone, internet, cable, water, gas, and electricity payments." />
            <EditQuestion fieldId="food" fieldPrefix="expenses" title="Food" 
                description="Asks user to list total monthly food payments, including SNAP benefits." />
            <EditQuestion fieldId="childCare" fieldPrefix="expenses" title="Child Care" 
                description="Asks user to list monthly child care costs." />
            <EditQuestion fieldId="childSpousalSupport" fieldPrefix="expenses" title="Child/Spousal Support" 
                description="Asks user to list any monthly child / spousal support payments." />
            <EditQuestion fieldId="automobileExpenses" fieldPrefix="expenses" title="Car Payment/Auto Insurance" 
                description="Asks user to list monthly car / auto insurance expenses." />
            <EditQuestion fieldId="transportationExpenses" fieldPrefix="expenses" title="Gas/Bus Fare/Tolls" 
                description="Asks user to list monthly transportation expenses." />
            <EditQuestion fieldId="attorneyFees" fieldPrefix="expenses" title="Attoney's Fees" 
                description="Asks user to list any monthly attorney's fees." />
            <EditQuestion fieldId="insurance" fieldPrefix="expenses" title="Health/Life Insurance Premiums" 
                description="Asks user to list any monthly out of pocket premiums paid on a monthly basis." />
            <EditQuestion fieldId="medical" fieldPrefix="expenses" title="Medical Expenses" 
                description="Asks user to list monthly co-pays, prescriptions, ongoing out of pocket expenses, etc." />
            <EditQuestion fieldId="laundry" fieldPrefix="expenses" title="Clothing/Laundry" 
                description="Asks user to list monthly laundry expenses" />
            <EditQuestion fieldId="misc" fieldPrefix="expenses" title="Other Misc. Personal Expenses" 
                description="Asks user to list any other monthly any other expenses for user or user's household." />
            <EditQuestion fieldId="otherExpensesInfo" fieldPrefix="expenses" title="Explanation for expenses exceeding income" 
                description="Asks user to explain how, if their expenses exceed your income, they make ends meet." />

          </div> : <PageIsHiddenQuestionBlurb />)}
    </PageTile>
  )
}

export const ApplicationQuestionsEditor = () => {

  return (
    <div>
      <SettingsHeader id="app-questions">Application Questions</SettingsHeader>
      <p style={{marginBottom: '30px'}}>Configure which application questions and pages to show, and adjust question descriptions.</p>
      <PersonalInfoQuestions />
      <IncomeAndAssetsQuestions />
      <ExpensesQuestions />
    </div>
  )
}