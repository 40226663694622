const API_ROOT = '/api';

// Submits the initial form inputs (name(s) and DOB) to search HCDC cases
// to receive the user's current eligibility info & token
export const checkEligibilityQuery = (values, jwtToken, orgSlug) => ({
  url: `${API_ROOT}/application/case-lookup?orgSlug=${orgSlug}`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo,
    token: resp.token,
    tokenExpirationTime: resp.tokenExpirationTime, 
    personalInfo: resp.personalInfo,
    demographicInfo: resp.demographicInfo,
  }),
  update: {
    personalInfo: (prev, updated) => updated,
    demographicInfo: (prev, updated) => updated,
    eligibilityInfo: (prev, updated) => updated,
    token: (prev, updated) => updated,
    tokenExpirationTime: (prev, updated) => updated,
    searchValues: () => values,
    email: () => null // Clear the email state when a new app is created 
  },
});

// Submits the user's confirmation of a given set of cases by spn
export const confirmCasesQuery = (spn, jwtToken) => ({
  url: `${API_ROOT}/application/cont/confirm-cases`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: { spn },
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

// Submits the user's disqualifying history and preliminary followup question responses
export const additionalInfoQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/eligibility-preliminary-questions`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo,
    // Saving the cases returned by the "Additional Info" query so we can reference them as we go though the case verifications
    postPreliminaryCases: resp.eligibilityInfo ? resp.eligibilityInfo.cases : [],
    otherCriminalHistory: resp.otherCriminalHistory,
    hasCrimeOutsideTexas: values.hasCrimeOutsideTexas,
    hasCrimeBefore1990: values.hasCrimeBefore1990,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
    otherCriminalHistory: (prev, updated) => updated,
    hasCrimeOutsideTexas: (prev, updated) => updated,
    hasCrimeBefore1990: (prev, updated) => updated,
    postPreliminaryCases: (prev, updated) => updated
  },
});

// Submits the user's case-specific followup question responses
export const caseFollowupQuery = (formValuesWithId, jwtToken) => ({
  url: `${API_ROOT}/application/cont/case-followup-questions`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: formValuesWithId,
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

// Submits the user's feedback survey responses
export const feedbackSurveyQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/survey/submit-feedback`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
});

// Submits request to send an email to the user with their case results
export const sendCaseResultsEmailQuery = (email, jwtToken, orgSlug) => ({
  url: `${API_ROOT}/application/email/send-case-results-email?orgSlug=${orgSlug}`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: { email },
  update: { email: () => email },
});

// Submits request to send an email to the user with the "Know Your Rights" page
export const sendKnowYourRightsQuery = (email, jwtToken, orgSlug) => ({
  url: `${API_ROOT}/application/email/send-know-your-rights-email?orgSlug=${orgSlug}`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: { email },
  update: { email: () => email },
});

// Requests the cases associated with the given temporary token for email PDF generation
export const getCasesByEmailToken = (emailToken) => ({
  url: `${API_ROOT}/application/pdf/case-info`,
  options: { method: 'GET',
    headers: { Authorization: `Bearer ${emailToken}` },  
  },
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

export const updateIncomeAndAssetsQuery  = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/income-and-assets`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
  transform: (resp) => ({
    incomeAndAssets: resp.incomeAndAssets,
  }),
  update: {
    incomeAndAssets: (prev, updated) => updated,
  },
});

export const updateExpensesQuery  = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/expenses`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
  transform: (resp) => ({
    expenses: resp.expenses,
  }),
  update: {
    expenses: (prev, updated) => updated,
  },
});

export const submitApplicationQuery  = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/submit-reviewed-app`,
  options: { method: 'POST',
    headers: { Authorization: `Bearer ${jwtToken}` },  
  },
  body: values,
});

// Get lookup end date from backend environment variable
export const getLookupEndDateQuery  = () => ({
  url: `${API_ROOT}/application/lookup-end-date`,
  options: { method: 'GET' },
  transform: (resp) => ({
    lookupEndDate: resp.lookupEndDate,
  }),
  update: {
    lookupEndDate: (prev, updated) => updated,
  },
});


// Get application configuration settings for the current org
export const getConfigQuery = (orgSlug) => ({
  url: `${API_ROOT}/application/config`,
  options: { method: 'GET' },
  body: { orgSlug },
  transform: (resp) => ({
    appConfig: resp.appConfig,
    orgInfo: resp.orgInfo,
  }),
  update: {
    appConfig: (prev, updated) => updated,
    orgInfo: (prev, updated) => updated,
  },
});


// ADMIN ROUTES

// Submits the quick lookup form inputs (name(s) and DOB) to search HCDC cases
export const quickLookupQuery = (values, orgSlug) => ({
  url: `${API_ROOT}/admin/case-lookup-attorney?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: values,
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo,
    personalInfo: resp.personalInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
    searchValues: () => values,
  },
});

// Submits the quick lookup by SPN input to search HCDC cases
export const quickLookupBySPNQuery = (values, orgSlug) => ({
  url: `${API_ROOT}/admin/case-lookup-attorney-spn?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: values,
  transform: (resp) => ({
    eligibilityInfo: resp.eligibilityInfo,
    personalInfo: resp.personalInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
    searchValues: () => values,
  },
});

export const getConfigQueryAdmin = (orgSlug) => ({
  url: `${API_ROOT}/admin/config?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
  transform: (resp) => ({
    appConfig: resp.appConfig,
    orgInfo: resp.orgInfo,
  }),
  update: {
    appConfig: (prev, updated) => updated,
    orgInfo: (prev, updated) => updated,
  },
});

//Update application configuration settings for the current org
export const updateConfigQuery = (values, orgSlug) => ({
  url: `${API_ROOT}/admin/update-config?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: values,
  transform: (resp) => ({
    appConfig: resp.appConfig,
  }),
  update: {
    appConfig: (prev, updated) => updated,
  },
});

export const addLogoQuery = (formData, orgSlug) => ({
  url: `${API_ROOT}/admin/image-upload?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: formData,
  transform: (resp) => ({
    orgInfo: resp.orgInfo,
  }),
  update: {
    orgInfo: (prev, updated) => updated,
  },
});

export const loginQuery = (values, orgSlug) => ({
  url: `${API_ROOT}/admin/login?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: values,
  transform: (resp) => ({
    sessionExpirationTime: resp.expirationTime, 
  }),
  update: {
    sessionExpirationTime: (prev, updated) => updated,
  },
});

export const logoutQuery = () => ({
  url: `${API_ROOT}/admin/logout`,
  options: { method: 'POST' }
});

export const getUsersQuery = (orgSlug) => ({
  url: `${API_ROOT}/admin/users?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
  transform: (resp) => ({
    users: resp.users,
  }),
  update: {
    users: (prev, updated) => updated,
  },
});

export const deleteUserQuery = (userId, orgSlug) => ({
  url: `${API_ROOT}/admin/deactivate-user?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: { userId },
  transform: (resp) => ({
    users: resp.user,
  }),
  update: {
    users: (prev) => {
      if (Array.isArray(prev)) {
        return prev.filter((user) => user.id != userId)
      }
      return []
    },
  },
});

export const addUserQuery = (values, orgSlug) => ({
  url: `${API_ROOT}/admin/add-user?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: values,
  transform: (resp) => ({
    users: resp.user,
  }),
  update: {
    users: (prev, updated) => {
      if (Array.isArray(prev)) {
        return [...prev, updated]
      } else {
        return [updated]
      }
    },
  },
});

export const forgotPasswordQuery = (username, orgSlug) => ({
  url: `${API_ROOT}/admin/forgot-password?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: { username }
});

export const resetPasswordQuery = (password, token, orgSlug) => ({
  url: `${API_ROOT}/admin/reset-password?orgSlug=${orgSlug}`,
  options: { method: 'POST' },
  body: { password, token }
});

export const getExportAllQuery = (orgSlug) => ({
  url: `${API_ROOT}/admin/export/all?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
});

export const getExportPrevQuery = (orgSlug) => ({
  url: `${API_ROOT}/admin/export/prev?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
});

export const getExportNewQuery = (orgSlug) => ({
  url: `${API_ROOT}/admin/export/new?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
});

export const markAsExportedQuery = (orgSlug) => ({
  url: `${API_ROOT}/admin/export/mark-exported?orgSlug=${orgSlug}`,
  options: { method: 'GET' },
});
