import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setCase, setFollowupCase } from '../../actions/modals';
import { selectedCaseSelector, selectedFollowupCaseSelector } from '../../selectors/modals';
import { getFormsNeededForFollowUp } from '../../utils/cases';
import { FormFollowupInfo } from './CaseCard';
import { FullCaseContent } from './FullCaseContent';
import { Modal } from './Modal';
import { CouldNotDetermineTile, EligibleTile, ExpunctionEligibleTile, IneligibleTile, NotYetEligibleTile } from './StatusTiles';

const Title = styled.h3`
  font-weight: 800;
  font-size: 21px;
  margin-top: 10px;
`

// Shows full details about a case
export const FollowupCaseModal = () => {
  const selectedCase = useSelector(selectedFollowupCaseSelector);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setFollowupCase(null));
  }

  if (selectedCase == null) {
    return null;
  }

  const { final_eligibility, hideStatus } = selectedCase;

  const followups = getFormsNeededForFollowUp(selectedCase);
  const followupBlurb = <FormFollowupInfo caseInfo={selectedCase} large={true} noExtraText={true} />;
  let statusTile = <EligibleTile followupBlurb={followupBlurb} large={true} noExtraText={true} />
  if (followups.length > 0) {
    statusTile = <CouldNotDetermineTile followupBlurb={followupBlurb} large={true} noExtraText={true}  />
  } else if (final_eligibility === 'ineligible') {
    statusTile = <IneligibleTile followupBlurb={followupBlurb} large={true} noExtraText={true} />
  } else if (final_eligibility === 'expunction') {
    statusTile = <ExpunctionEligibleTile followupBlurb={followupBlurb} large={true} noExtraText={true} />
  }


  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)', zIndex: 10000 }}>
      <Title>Full Case Information</Title>
      <FullCaseContent caseInfo={selectedCase} hideBorderLines={true} />
      {!hideStatus && statusTile}
    </Modal>
  )
}