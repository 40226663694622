import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { usersSelector } from '../../selectors/entities';
import { getUsersQuery } from '../../actions/queries';
import { getMonthFromIndex, statusIsGood } from '../../utils/helpers';
import { useColors } from '../../hooks/useColors';
import { Button, InvertedButton } from '../general/Common';
import { useDeleteUser } from '../../hooks/editors';
import { setNewUserModal } from '../../actions/modals';
import { Header } from '../general/BeigeTile';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.p`
  margin-top: 10px;
`

const UsersTable = styled.div`
  border: 2px solid ${({ color }) => color};
  border-radius: 6px;
  margin: 20px 0px;
`

const UsersTableHeader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 6px 6px 0px 0px;
  > div:first-child {
    width: 20%;
  }
  > div {
    width: 30%;
    text-align: center;
  }
  > div:last-child {
    width: 20%;
  }
`

const UsersList = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  border-radius: 6px;
  > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    > div:first-child {
      width: 20%;
    }
    > div {
      width: 30%;
      text-align: center;
    }
    > div:last-child {
      width: 20%;
    }
  }
  > li:last-child {
    border-bottom: none;
  }
`

export const UsersPage = () => {
  const { orgSlug } = useParams();
  const [{ isFinished, status }] = useRequest(getUsersQuery(orgSlug));
  const users = useSelector(usersSelector);
  const { bgColor } = useColors();
  const dispatch = useDispatch();
  const [deleteUser] = useDeleteUser();

  const handleDeleteUser = (user) => {
    const confirmedDelete = window.confirm(
      `Are you sure you want to deactivate ${user.firstName} ${user.lastName} (${user.email})?`
    );
    if (confirmedDelete) {
      deleteUser(user.id);
    }
  }
  
  return (
    <Wrapper>
      <div style={{ width: '1000px'}}>
        <Header>
          Users
        </Header>
        <Text>
          All users can review and export applications, edit the application configuration, and add and delete other users.
        </Text>
        <UsersTable color={bgColor}>
          { isFinished && statusIsGood(status) ?
            <UsersTableHeader bgcolor={bgColor}>
              <div style={{ fontWeight: 700 }}>
                Name
              </div>
              <div style={{ fontWeight: 700 }}>
                Email
              </div>
              <div style={{ fontWeight: 700 }}>
                Date added
              </div>
              <Button style={{ minWidth: '100%' }} onClick={() => dispatch(setNewUserModal(true))}>
                Add new user +
              </Button>
              {/* <div /> */}
            </UsersTableHeader>
            : <UsersTableHeader bgcolor={bgColor}>
                Loading ...
              </UsersTableHeader>
          }
          <UsersList>
            {users.map((user) => {
              const dateAdded = new Date(user.dateCreated)
              return <li key={user.id}>
                  <div>{user.firstName} {user.lastName}</div>
                  <div>{user.email}</div>
                  <div>{
                    `${getMonthFromIndex(dateAdded.getUTCMonth())} ${dateAdded.getUTCDate()}, ${dateAdded.getUTCFullYear()}`
                  }</div>
                  <InvertedButton style={{ minWidth: '100%', marginRight: '0px', marginBottom: '0px' }} onClick={() => handleDeleteUser(user)}>
                    Delete user
                  </InvertedButton>
              </li>})}
          </UsersList>
        </UsersTable>
      </div>
    </Wrapper>
  )
}