import React from 'react';
import styled from 'styled-components';
import { Button } from '../general/Common';
import { Formik } from 'formik';
import { TextField } from '../general/FormFields';
import { validateForgotPassword } from '../../utils/validators';
import { useColors } from '../../hooks/useColors';
import { useForgotPasswordQuery } from '../../hooks/editors';

const IntroText = styled.h1`
  color: ${({color}) => color || '#315161'};
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0px;
  font-size: 30px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const BackToLoginBtn = styled.button`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 16px;
  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
`;

export const ForgotPasswordContent = ({ setShowForgotPassword }) => {
  const { primaryDark, primary } = useColors();
  const [resetSubmit, resetSubmitting] = useForgotPasswordQuery();

  return (
    <Formik
      validate={validateForgotPassword}
      initialValues={{ email: '' }}
      onSubmit={resetSubmit}>
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <IntroText color={primaryDark}>
              Forgot Password
            </IntroText>
            <p style={{margin: '0px 0px 10px 0px', padding: '0px', fontSize: '17px'}}>
              Please enter your account email below. We'll send you an email with instructions on how to reset your password.
            </p>
            <TextField label="Email" error={getError('email')} style={{ margin: '5px 0px '}} type="email" name="email" />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={resetSubmitting}>
                Send Reset Instructions
              </Button>
            </ButtonWrapper>
            <BackToLoginBtn color={primary} onClick={() => setShowForgotPassword(false)}>
              Back to login
            </BackToLoginBtn>
          </>
      )}}
    </Formik>
  )
}