import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { OptionsList, SelectField, TextField, MultiFieldLine, MDYField, YesNoField, YesNoSelectField, CheckboxGroup } from '../general/FormFields.js';
import { Button } from '../general/Common';
import { validatePersonalInfo } from '../../utils/validators.js';
import { useInitialForm } from '../../hooks/editors.js';
import { BeigeTile, Header, Subheader } from '../general/BeigeTile.js';
import { GREY_LIGHT, MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import { 
  citizenshipOptions, CURRENTLY_HAS_ID, idOptions, maritalStatusOptions, PREVIOUSLY_HAD_ID, 
  stateOptionsFull, suffixOptions, yesNoOptions,
} from '../../constants/formOptions.js';
import { useHistory, useParams } from 'react-router-dom';
import { TileBackArrow } from '../general/TileBackArrow.js';
import { useSelector } from 'react-redux';
import { NameArrayEditor } from '../general/OtherNamesFields.js';
import { appConfigSelector, demographicInfoSelector, personalInfoSelector } from '../../selectors/entities.js';
import { submitWithErrorMsg } from '../../utils/helpers.js';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  & > div:second-child {
    font-size: 14px;
    font-style: italic;
    margin-right: 10px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    > div {
      margin: 10px 0px;
      max-width: 400px;
    }
    > div:last-child {
      align-self: stretch;
    }
  } 
`;

const Text = styled.p`
  margin: 10px 0px;
`;

// Values to be used if no previous values are provided
const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  dob: null,
  aliasFirstNames: [],
  aliasLastNames: [],
  phone: '',
  email: '',
  streetAddress: '',
  city: '',
  state: '',
  zipcode: '',
  ssnFour: '',
  idStatus: '',
  idState: '',
  idNumber: '',
  gender: '',
  genderSelfDescribeInput: '',
  otherRaceInput: '',
  race: '',
  citizenship: '',
  maritalStatus: '',
  specialCategories: [],
  isVeteran: null,
  referralSource: '',
  referralSourceOtherInput: '',
}

// Groups first, middle, last name, & suffix into one form line (on desktop, breaks into 4 lines on mobile)
const NameField = ({ namePrefix, onDelete, getError, noRequired }) => {
  const getName = name => namePrefix ? `${namePrefix}${name}` : name;
  return (
    <MultiFieldLine columns={`25% 25% 25% auto ${onDelete ? '30px' : ''}`}>
      <TextField name={getName("firstName")} label="First name" field={{ required: !noRequired }} error={getError("firstName")} />
      <TextField name={getName("middleName")} label="Middle name (optional)" field={{ required: false }} error={getError("middleName")}  />
      <TextField name={getName("lastName")} label="Last name" field={{ required: !noRequired }} error={getError("lastName")}  />
      <SelectField name={getName("suffix")} label="Suffix (optional)" field={{ required: false }} error={getError("suffix")} >
        <OptionsList options={suffixOptions} name={getName('suffix')} hideSelectOne={true} />
      </SelectField>
      {onDelete && <button type="button" style={{ height: '30px', position: 'relative', top: '40px'}} onClick={onDelete}>-</button>}
    </MultiFieldLine>
  )
}

const AddressField = ({ getError, noRequired }) => {
  return (
    <MultiFieldLine columns={`25% 25% 25% auto`}>
      <TextField name="streetAddress" label="Street address" field={{ required: !noRequired }} error={getError("streetAddress")} />
      <TextField name="city" label="City" field={{ required: !noRequired }} error={getError("city")}  />
      <SelectField name="state" label="State" field={{ required: !noRequired }} error={getError("state")} >
        <OptionsList options={stateOptionsFull} name="state" />
      </SelectField>
      <TextField name="zipcode" label="Zipcode" field={{ required: !noRequired }} error={getError("zipcode")}  />
    </MultiFieldLine>
  )
}

const DriversLicenseField = ({ getError, idStatusValue }) => {
  return (
    <>
      <SelectField name="idStatus" label="Driver's License/ID*" error={getError("idStatus")} >
        <OptionsList options={idOptions} name="idStatus" />
      </SelectField>
      {idStatusValue === PREVIOUSLY_HAD_ID && <SelectField name="idState" label="Driver's License/ID State of Issue*" error={getError("idState")} >
          <OptionsList options={stateOptionsFull} name="idState" />
        </SelectField>}
      {idStatusValue === CURRENTLY_HAS_ID && <MultiFieldLine columns={`50% 50%`}>
        <SelectField name="idState" label="Driver's License/ID State of Issue*" error={getError("idState")} >
          <OptionsList options={stateOptionsFull} name="idState" />
        </SelectField>
        <TextField name="idNumber" label="Driver's License/ID Number*" error={getError("idNumber")}  />
      </MultiFieldLine>}
    </>
  )
}

const ContactInfoField = ({ getError, noRequired }) => {
  return (
    <MultiFieldLine columns={`50% 50%`}>
      <TextField name="phone" label="Phone number" field={{ required: !noRequired }} error={getError("phone")} />
      <TextField name="email" label="Email address" field={{ required: !noRequired }} error={getError("email")}  />
    </MultiFieldLine>
  )
}

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const PersonalInfo = () => {
  const history = useHistory();
  const { orgSlug } = useParams();
  const appConfig = useSelector(appConfigSelector);
  const personalInfoState = useSelector(personalInfoSelector);
  const demographicInfoState = useSelector(demographicInfoSelector);
  const personalInfoConfig = appConfig.personalInfo;
  const validate = validatePersonalInfo(personalInfoConfig);

  const [submit, submitting] = useInitialForm((casesFound) => {
    if (casesFound) {
      history.push(`/${orgSlug}/app/personalInfo/confirmCases`);
    } else {
      history.push(`/${orgSlug}/app/personalInfo/noCasesFound`);
      // dispatch(setNoCasesModal(true));
    }
  });

  const getFieldValue = (fieldId, fieldType) => {
    if (!personalInfoConfig || !personalInfoConfig.fields[fieldId]) {
      return false;
    }
    return personalInfoConfig && personalInfoConfig.fields[fieldId][fieldType];
  }
  let genderOptions = [...(getFieldValue('gender', 'options') || [])];
  if (getFieldValue('gender', 'includePreferToSelfDescribe')) {
    genderOptions.push('Prefer to self-describe');
  }
  const genderSelectOptions = genderOptions.map((opt) => ({ label: opt, value: opt }));

  let referralOptions = [...(getFieldValue('referralSource', 'options') || [])];
  if (getFieldValue('referralSource', 'includeOther')) {
    referralOptions.push('Other');
  }
  const referralSelectOptions = referralOptions.map((opt) => ({ label: opt, value: opt }));

  let raceOptions = [...(getFieldValue('race', 'options') || [])];
  if (getFieldValue('race', 'includeOther')) {
    raceOptions.push('Other');
  }
  const raceSelectOptions = raceOptions.map((opt) => ({ label: opt, value: opt }));

  let specialCategoriesOptions = [...(getFieldValue('specialCategories', 'options') || [])];
  if (getFieldValue('specialCategories', 'includeOther')) {
    specialCategoriesOptions.push('Other');
  }
  const specialCategoriesSelectOptions = specialCategoriesOptions.map((opt) => ({ label: opt, value: opt }));

  const anyFieldInListIsActive = (fieldList) => fieldList.some(fieldId => getFieldValue(fieldId, 'visible')); 

  // Override the default values with existing personal ino & demographic info state if available.
  const initValues = {
    ...defaultValues,
    ...(personalInfoState || {}),
    ...(demographicInfoState || {}),
  }

  return (
      <Formik
        validate={validate}
        initialValues={initValues}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          // return error if field is touched
          const getError = (name) => (touched[name] || submitCount >= 1) ? errors[name] : null;
          return (
            <>
              <BeigeTile>
                <Header>
                  <TileBackArrow onClick={() => history.push(`/${orgSlug}/app/terms`)} />
                  Personal Info
                </Header>
                
                <Text>This check relies on the data you enter, so please double check spelling and dates.</Text>

                {getFieldValue('legalName', 'visible') && <>
                  <Subheader>Legal Name</Subheader>
                  <NameField getError={getError} />
                </>}

                {getFieldValue('additionalNames', 'visible') && <>
                  <Subheader>Other Names</Subheader>
                  <Text>
                    Do you use <b>any other names</b>, or have you used other names in the past? If so, please add them below.
                  </Text>

                  <NameArrayEditor nameType="first" values={values} fieldName="aliasFirstNames" />
                  <NameArrayEditor nameType="last" values={values} fieldName="aliasLastNames" />
                </>}

                {getFieldValue('dob', 'visible') && <>
                  <Subheader>Date of Birth</Subheader>
                  <MDYField name="dob" label="Date of birth" field={{ required: true }} error={getError('dob')} />
                </>}

                {anyFieldInListIsActive(['phone', 'email', 'addressInfo', 'ssnFour', 'driversLicense']) && <Subheader>
                  Contact Info
                </Subheader>}

                {getFieldValue('phone', 'visible') && getFieldValue('email', 'visible') && <ContactInfoField getError={getError} />}
                {getFieldValue('phone', 'visible') && !getFieldValue('email', 'visible') && 
                  <TextField name="phone" label="Phone number" field={{ required: true }} error={getError("phone")} />}
                {getFieldValue('email', 'visible') && !getFieldValue('phone', 'visible') && 
                  <TextField name="email" label="Email address" field={{ required: true }} error={getError("email")}  />}

                {getFieldValue('addressInfo', 'visible') && <>
                  <AddressField getError={getError} />
                </>}

                {getFieldValue('ssnFour', 'visible') && <>
                  <TextField label="Last 4 Digits of Social Security Number* (if you do not have a SSN, enter 0000)" name="ssnFour" error={getError("ssnFour")}/>
                </>}

                {getFieldValue('driversLicense', 'visible') && <>
                  <DriversLicenseField getError={getError} idStatusValue={values.idStatus} />
                </>}

                {anyFieldInListIsActive(['gender', 'citizenship', 'maritalStatus', 'isVeteran', 'referralSource']) && <Subheader>
                  Additional Info
                </Subheader>}

                {getFieldValue('gender', 'visible') && <>
                  <SelectField label="Your gender*" name="gender" error={getError("gender")} >
                    <OptionsList options={genderSelectOptions} name="gender" />
                  </SelectField>
                  {values.gender === 'Prefer to self-describe' && <TextField name="genderSelfDescribeInput" placeholder="Please specify" />}
                </>}

                {getFieldValue('race', 'visible') && <>
                  <SelectField label="Your race/ethnicity*" name="race" error={getError("race")} >
                    <OptionsList options={raceSelectOptions} name="race" />
                  </SelectField>
                  {values.race === 'Other' && <TextField name="otherRaceInput" placeholder="Please specify" />}
                </>}

                {getFieldValue('citizenship', 'visible') && <>
                  <SelectField label="United States Citizen*" name="citizenship" error={getError("citizenship")} >
                    <OptionsList options={citizenshipOptions} name="citizenship" />
                  </SelectField>
                </>}

                {getFieldValue('specialCategories', 'visible') && <>
                  <CheckboxGroup  name="specialCategories" label="Do any of the following apply to you?" 
                                  error={getError('specialCategories')} checkboxOptions={specialCategoriesSelectOptions}
                                  style={{ marginBottom: '20px' }} checkboxStyle={{ marginTop: '10px', marginLeft: '5px'}} />
                </>}

                {getFieldValue('maritalStatus', 'visible') && <>
                  <SelectField label="Marital status*" name="maritalStatus" error={getError("maritalStatus")} >
                    <OptionsList options={maritalStatusOptions} name="maritalStatus" />
                  </SelectField>
                </>}

                {getFieldValue('isVeteran', 'visible') && <>
                  <YesNoSelectField label="Veteran (yourself or an immediate family member)*" name="isVeteran" error={getError("isVeteran")} />
                </>}

                {getFieldValue('referralSource', 'visible') && <>
                  <SelectField label="How did you hear about us?*" name="referralSource" error={getError("referralSource")} >
                    <OptionsList options={referralSelectOptions} name="referralSource" />
                  </SelectField>
                  {values.referralSource === 'Other' && <TextField name="referralSourceOtherInput" placeholder="Please specify" />}
                </>}

                {getFieldValue('applicationAssistant', 'visible') && <>
                  <Subheader style={{ marginTop: '50px'}}>Application Assistant</Subheader>
                  <Text>Are you filling out this application for someone else? If so, please enter your name below.</Text>
                  <TextField short={true} name="applicationAssistant" label="Assistant name" field={{ required: false }} error={getError("applicationAssistant")} />
                </>}

                {(getFieldValue('caseManagerContactName', 'visible') || getFieldValue('caseManagerContactPhone', 'visible') || getFieldValue('caseManagerContactEmail', 'visible')) && <>
                  <Subheader style={{ marginTop: '50px'}}>Case Manager Contact Information</Subheader>
                  <Text>Do you have a case manager? If so, please enter their contact information below.</Text>
                </>}

                {getFieldValue('caseManagerContactName', 'visible') && <>
                  <TextField short={true} name="caseManagerContactName" label="Case manager's name" field={{ required: false }} error={getError("caseManagerContactName")} />
                </>}

                {getFieldValue('caseManagerContactPhone', 'visible') && <>
                  <TextField short={true} name="caseManagerContactPhone" label="Case manager's phone" field={{ required: false }} error={getError("caseManagerContactPhone")} />
                </>}

                {getFieldValue('caseManagerContactEmail', 'visible') && <>
                  <TextField short={true} name="caseManagerContactEmail" label="Case manager's email" field={{ required: false }} error={getError("caseManagerContactEmail")} />
                </>}

                <div style={{ marginTop: '20px', borderTop: `1px solid ${GREY_LIGHT}`, paddingTop: '20px', fontSize: '16px'}}>* These fields are mandatory</div>

                <Footer>
                  <Button fillOnMobile={true} type="submit" isLoading={submitting} onClick={submitWithErrorMsg(errors, handleSubmit)}>Next</Button>
                </Footer>
              </BeigeTile>
            </>
          )}}
    </Formik> 
  )
}
