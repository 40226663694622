import React, { useEffect } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Redirect, Route, Switch, useParams, useHistory } from 'react-router-dom';
import { Header } from '../general/Header';
import { BubbleNavigation } from './BubbleNavigation';
import { Review } from './Review';
import { useSelector } from 'react-redux';
import { searchValuesSelector, tokenSelector } from '../../selectors/entities';
import { PersonalInfo } from './PersonalInfo';
import { useColors } from '../../hooks/useColors';
import { IncomeAndAssets } from './IncomeAndAssets';
import { Expenses } from './Expenses';
import { PrelimQuestions } from './CriminalHistory';
import { CaseFollowupRoutes } from './CaseFollowups';
import { Results } from './Results';
import { TermsAndConditions } from './TermsAndConditions';
import { agreeToTermsSelector } from '../../selectors/general';
import { ConfirmCases } from './ConfirmCases';
import { NoCasesFoundPage } from './NoCasesFound';
import TokenExpirationTimer from '../general/TokenExpirationTimer';
import { getConfigQuery } from '../../actions/queries';
import { useRequest } from 'redux-query-react';
import { PageLoader } from '../general/Loaders';
import { statusIsGood } from '../../utils/helpers';

// Root component for all of the "Next steps" pages
export const ApplicationRoot = () => {
  const { bgColor } = useColors();
  const { orgSlug } = useParams();
  const history = useHistory();
  const [{ isFinished, status }] = useRequest(getConfigQuery(orgSlug));
  const appHasBeenStarted = useSelector(searchValuesSelector) != null;
  const agreeToTerms = useSelector(agreeToTermsSelector);
  // Want to show refresh warning if there's an active token in state while on any of the next-steps pages
  const showRefreshWarning = useSelector(tokenSelector) != null; 

  useEffect(() => {
    // Redirect to the 404 page if no org is found
    if (isFinished && !statusIsGood(status)) {
      history.push('/')
    }
  }, [isFinished, status])

  if (!isFinished) {
    return <PageLoader />;
  }
  
  return (
    <div style={{  minHeight: '100vh', textAlign: "center" }}>
      {showRefreshWarning && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}
      <Header title="Application" />
      <Route path='/:orgSlug/app/:id' render={() => <BubbleNavigation root="app" />} />
      <Switch>
        <Route path="/:orgSlug/app/terms" component={TermsAndConditions} />
        {agreeToTerms && <>
          <Route exact={true} path="/:orgSlug/app/personalInfo" component={PersonalInfo} />
          {appHasBeenStarted && <>
            <Route path="/:orgSlug/app/personalInfo/confirmCases" component={ConfirmCases} />
            <Route path="/:orgSlug/app/personalInfo/noCasesFound" component={NoCasesFoundPage} />
            <Route path="/:orgSlug/app/incomeAndAssets" component={IncomeAndAssets} />
            <Route path="/:orgSlug/app/expenses" component={Expenses} />
            <Route path="/:orgSlug/app/criminalHistory/followups" component={CaseFollowupRoutes} />
            <Route exact={true} path="/:orgSlug/app/criminalHistory" component={PrelimQuestions} />
            <Route path="/:orgSlug/app/eligibility" component={Results} />
            <Route path="/:orgSlug/app/review" component={Review} />
          </>}
        </>}
        <Redirect to="/:orgSlug/app/terms" />
      </Switch>
      <TokenExpirationTimer />
    </div>
  )
}
