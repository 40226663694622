import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../general/Common';
import { BG_LIGHTER, MAX_DESKTOP_WIDTH, PRIMARY, SECONDARY, SHADOW_MEDIUM } from '../../cssVars.js';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField } from '../general/FormFields';
import { validateLogin } from '../../utils/validators';
import { useLoginQuery } from '../../hooks/editors';
import { useRequest } from 'redux-query-react';
import { getConfigQuery } from '../../actions/queries';
import { PageLoader } from '../general/Loaders';
import { ForgotPasswordContent } from './ForgotPasswordContent';
import { Footer } from '../general/Footer';
import ctrhcLogo from '../../assets/blackSheep/CTRHC.png';

const Wrapper = styled.div`
  width: 100%;
`

export const MainContent = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Tile = styled.div`
  padding: 30px 20px;
  background-color: ${BG_LIGHTER};
  box-shadow: ${SHADOW_MEDIUM};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  width: 350px;
`;

export const IntroText = styled.h1`
  color: #6b1c52;
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0px;
  font-size: 30px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const ForgotPasswordBtn = styled.button`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: none;
  color: ${PRIMARY};
  font-weight: 700;
  font-size: 16px;
  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
`;

export const CTRHCLogo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 200px;
  max-height: 80px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    max-width: 150px;
    max-height: 70px;
  }
`;

export const Login = () => {
  const history = useHistory();
  const { orgSlug } = useParams();
  const [{ isFinished }] = useRequest(getConfigQuery(orgSlug));
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [submit, submitting] = useLoginQuery(() => {
    history.push(`/${orgSlug}/admin`);
  }) 

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <Wrapper>
      <CTRHCLogo src={ctrhcLogo} alt="Clear Their Record Harris County" />
      <MainContent>
        <Tile borderColor={SECONDARY}>
          {showForgotPassword ?
              <ForgotPasswordContent setShowForgotPassword={setShowForgotPassword} />
            :
              <Formik
                validate={validateLogin}
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={submit}>
                {({ handleSubmit, errors, touched, values, submitCount }) => {
                  const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
                  return (
                    <>

                      <IntroText>
                        Admin Login
                      </IntroText>
                      <TextField label="Email" error={getError('email')} style={{ margin: '5px 0px '}} type="email" name="email" />
                      <TextField label="Password" error={getError('password')}  style={{ margin: '5px 0px '}} type="password" name="password" />
                      <ButtonWrapper>
                        <Button onClick={handleSubmit} isLoading={submitting}>
                          Log in
                        </Button>
                      </ButtonWrapper>
                      <ForgotPasswordBtn onClick={() => setShowForgotPassword(true)}>
                        Forgot password
                      </ForgotPasswordBtn>
                    </>
                )}}
              </Formik>
          }
        </Tile>
      </MainContent>
      <Footer />
    </Wrapper>
  )
}