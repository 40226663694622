import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { appConfigSelector } from '../selectors/entities';

const configHasAtLeastOneVisibleField = (config) => {
  if (!config || !config.fields) {
    return false;
  }
  return Object.keys(config.fields).some(fieldId => config.fields[fieldId].visible)
}


// Returns the list of stepIds that are currently configured to show in the application
export const useSteps = () => {
  const appConfig = useSelector(appConfigSelector);

  if (Object.keys(appConfig).length === 0) {
    return { stepIds: [] };
  }

  const stepIds = ['personalInfo'];
  if (appConfig.incomeAndAssets.visible && configHasAtLeastOneVisibleField(appConfig.incomeAndAssets)) {
    stepIds.push('incomeAndAssets');
  }
  if (appConfig.expenses.visible && configHasAtLeastOneVisibleField(appConfig.expenses)) {
    stepIds.push('expenses');
  }
  stepIds.push('criminalHistory');
  stepIds.push('eligibility');
  stepIds.push('review');

  const checkIfStepExists = id => stepIds.includes(id);

  return {
    stepIds,
    checkIfStepExists
  }
}

// given a step ID, provide the next and previous steps
export const useAdjacentSteps = (stepId) => {
  const { orgSlug } = useParams();
  const { stepIds } = useSteps();
  const stepIdx = stepIds.indexOf(stepId);
  return {
    nextStep: stepIdx < (stepIds.length - 1) ? `/${orgSlug}/app/${stepIds[stepIdx + 1]}` : null,
    prevStep: stepIdx > 0 ? `/${orgSlug}/app/${stepIds[stepIdx - 1]}` : null,
  }
}
