import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { OptionsList, SelectField, TextField, MultiFieldLine, MDYField } from '../general/FormFields.js';
import { Button, InvertedButton, StyledHR } from '../general/Common';
import { validateInitialForm } from '../../utils/validators.js';
import { useInitialForm, useQuickLookup } from '../../hooks/editors.js';
import { Header, Subheader } from '../general/BeigeTile.js';
import { suffixOptions } from '../../constants/formOptions.js';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditBar, EditBarContainer } from './SettingsEditor.js';
import { allCasesSelector, needSPNSelector, spnGroupedCasesSelector } from '../../selectors/entities.js';
import { QuickLookupCaseCard } from '../general/CaseCard.js';
import { setFollowupCase } from '../../actions/modals.js';
import { NoCasesFoundInitContent, SearchQuerySection } from '../application/NoCasesFound';
import retryImg from '../../assets/retry.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

const Text = styled.p`
  margin: 10px 0px;
`;

const RetryIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 9px;
`

const ImportantTile = styled.div`
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0px;
  background-color: #ECD893;
`;

// Groups first, middle, last name, & suffix into one form line (on desktop, breaks into 4 lines on mobile)
const NameField = ({ namePrefix, onDelete, getError, noRequired }) => {
  const getName = name => namePrefix ? `${namePrefix}${name}` : name;
  return (
    <MultiFieldLine columns={`25% 25% 25% auto ${onDelete ? '30px' : ''}`}>
      <TextField name={getName("firstName")} label="First name" field={{ required: !noRequired }} error={getError("firstName")} />
      <TextField name={getName("middleName")} label="Middle name (optional)" field={{ required: false }} error={getError("middleName")}  />
      <TextField name={getName("lastName")} label="Last name" field={{ required: !noRequired }} error={getError("lastName")}  />
      <SelectField name={getName("suffix")} label="Suffix (optional)" field={{ required: false }} error={getError("suffix")} >
        <OptionsList options={suffixOptions} name={getName('suffix')} hideSelectOne={true} />
      </SelectField>
      {onDelete && <button type="button" style={{ height: '30px', position: 'relative', top: '40px'}} onClick={onDelete}>-</button>}
    </MultiFieldLine>
  )
}

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const LookupPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const validate = validateInitialForm;
  const { orgSlug } = useParams();
  const allCases = useSelector(allCasesSelector);
  const needSPN = useSelector(needSPNSelector);
  const spnGroupedCases = useSelector(state => spnGroupedCasesSelector(state, true));
  const [searchComplete, setSearchComplete] = useState(false);
  const [submit, submitting] = useQuickLookup((casesFound) => {
    setSearchComplete(true);
  });

  return (
      <Formik
        validate={validate}
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          dob: null,
          aliasFirstNames: [],
          aliasLastNames: [],
        }}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, errors, touched, values, submitCount }) => {
          // return error if field is touched
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <Wrapper>
                <div style={{ width: '1000px'}}>
                  <Header>
                    Name {'&'} DOB Lookup
                  </Header>

                  <Text>
                    Use this search to get an initial estimate of nondisclosure / expunction eligibility for a client's Harris County cases, using the client's name {'&'} date of birth (DOB).
                  </Text>

                  <StyledHR />

                  {!searchComplete ? 
                    <div>
                      <Subheader>Legal Name</Subheader>
                      <NameField getError={getError} />

                      {/* <Subheader>Other Names</Subheader>
                      <Text>
                        Do you use <b>any other names</b>, or have you used other names in the past? If so, please add them below.
                      </Text>

                      <NameArrayEditor nameType="first" values={values} fieldName="aliasFirstNames" />
                      <NameArrayEditor nameType="last" values={values} fieldName="aliasLastNames" /> */}

                      <Subheader>Date of Birth</Subheader>
                      <MDYField name="dob" label="Date of birth" field={{ required: true }} error={getError('dob')} />
                    </div>
                  : allCases && allCases.length > 0 ?
                    <>
                      <Subheader style={{ fontWeight: 600, marginBottom: '15px'}}>Searched cases for ...</Subheader>
                      <SearchQuerySection />
                      {needSPN 
                        ?
                          <>
                            <StyledHR />
                            <ImportantTile>
                              <b>Found {spnGroupedCases.length} potential matches.</b> Please review each set of cases to ensure you are using the correct match.
                            </ImportantTile>
                            {spnGroupedCases.map((caseArray, arrayIdx) => (
                              <div key={arrayIdx}>
                                <Subheader>Case Results (SPN: {caseArray[0].def_spn})</Subheader>
                                {caseArray.map((currCase, index) => {
                                  const clickCase = () => dispatch(setFollowupCase(currCase));
                                  return (
                                    <QuickLookupCaseCard followups={true} onClick={clickCase} key={index} caseInfo={currCase} />
                                  )
                                })}
                              </div>
                            ))}
                          </>
                        : <div>
                            <ImportantTile>
                              Please carefully review the returned results to ensure the cases belong to the correct individual.
                            </ImportantTile>
                            <Subheader>Case Results (SPN: {allCases[0].def_spn})</Subheader>
                            {allCases.map((currCase, index) => {
                              const clickCase = () => dispatch(setFollowupCase(currCase));
                              return (
                                <QuickLookupCaseCard followups={true} onClick={clickCase} key={index} caseInfo={currCase} />
                              )
                            })}
                          </div>
                      }
                    </>
                  : <NoCasesFoundInitContent />
                  }
                  </div>
                </Wrapper>

                <EditBar>
                    <EditBarContainer width={1000}>
                      {searchComplete 
                        ? <Button style={{minWidth: '250px', marginBottom: '0px'}} type="button" onClick={() => setSearchComplete(false)}>
                            Reset search
                            <RetryIcon src={retryImg} alt="circular retry arrow" /> 
                          </Button>
                        : <Button fillOnMobile={true} type="submit" isLoading={submitting} onClick={handleSubmit}>Search for cases</Button>
                      }
                    </EditBarContainer>
                </EditBar>
            </>
          )}}
    </Formik> 
  )
}
