import React from 'react';
import styled from 'styled-components';
import {
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
  FORM_ID_411_0726,
  FORM_ID_411_0727,
  FORM_ID_411_0728,
  FORM_ID_411_0729,
  FORM_ID_411_0731,
  FORM_ID_411_0736
} from '../../constants/cases.js';
import { BG_LIGHTER, MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import { getFormsNeededForFollowUp } from '../../utils/cases.js';
import {
  EligibleTile,
  ExpunctionEligibleTile,
  CouldNotDetermineTile,
  IneligibleTile,
  DPSEligibilityUnknownTile
} from './StatusTiles.js';

const Card = styled.button`
  background-color: ${BG_LIGHTER};
  border: 1px solid #969381;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  color: #37352b;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  transition: all 0.2s ease;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    border: 1px solid #676455;
  }
  ${({ split }) =>
    !split
      ? ''
      : `
  > div {
    flex-grow: 1;
    flex-basis: 0;
  }
`}
  cursor: pointer;
  margin: 15px 0px;
  ${({ hideStatus }) =>
    hideStatus
      ? ''
      : `
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      flex-direction: column-reverse;
      > div:last-child {
        margin-bottom: 10px;
        width: min(calc(100% - 20px), 400px);
        align-self: flex-end;
      }
    } 
  `}
`;

const MainContent = styled.div`
  margin-right: 10px;
  > div {
    margin: 5px 0px;
  }
`;

const FormFollowup = styled.div`
  > div {
    margin-top: 10px;
    > ul {
      padding: 0px;
      margin: 0px;
      padding-left: 20px;
    }
  }
`;

// Small card-style div that shows basic information about a case
// (just offense name, defendant name, & filing date),
// with prompt to click it and view full details about the case in a modal
export const CaseCard = ({ caseInfo, onClick, style }) => {
  const {
    current_offense_name,
    fda,
    full_name,
    final_eligibility,
    hideStatus
  } = caseInfo;

  let statusTile = <EligibleTile />;
  if (final_eligibility === 'ineligible') {
    statusTile = <IneligibleTile />;
  } else if (final_eligibility === 'expunction') {
    statusTile = <ExpunctionEligibleTile />;
  }

  return (
    <Card type="button" onClick={onClick} style={style} hideStatus={hideStatus}>
      <MainContent>
        <div>{current_offense_name}</div>
        <div>{full_name}</div>
        <div>Filing Date: {fda}</div>
        <div>
          <i>Click to see full case information</i>
        </div>
      </MainContent>
      {!hideStatus && statusTile}
    </Card>
  );
};

// Contains details about what additional requirements need to be met for the case to be eligible
export const QuickLookupCaseCard = ({ caseInfo, onClick, style }) => {
  const {
    current_offense_name,
    fda,
    full_name,
    final_eligibility,
    dps_unknown_offense,
    dps_unknown_disposition_or_charge_lvl,
    hideStatus
  } = caseInfo;

  const followups = getFormsNeededForFollowUp(caseInfo);
  const followupBlurb =
    followups.length > 0 ? (
      <FormFollowupInfo caseInfo={caseInfo} large={true} noExtraText={true} />
    ) : null;
  let statusTile = (
    <EligibleTile
      followupBlurb={followupBlurb}
      large={true}
      noExtraText={true}
    />
  );
  if (followups.length > 0) {
    statusTile = (
      <CouldNotDetermineTile
        followupBlurb={followupBlurb}
        large={true}
        noExtraText={true}
      />
    );
  } else if (final_eligibility === 'ineligible') {
    statusTile = (
      <IneligibleTile
        followupBlurb={followupBlurb}
        large={true}
        noExtraText={true}
      />
    );
  } else if (final_eligibility === 'expunction') {
    statusTile = (
      <ExpunctionEligibleTile
        followupBlurb={followupBlurb}
        large={true}
        noExtraText={true}
      />
    );
  }
  const dpsTile =
    final_eligibility === 'nondisclosure' &&
    (dps_unknown_offense || dps_unknown_disposition_or_charge_lvl) ? (
      <DPSEligibilityUnknownTile large={true} noExtraText={true} />
    ) : null;

  return (
    <Card
      split={true}
      type="button"
      onClick={onClick}
      style={style}
      hideStatus={hideStatus}
    >
      <MainContent>
        <div>{current_offense_name}</div>
        <div>{full_name}</div>
        <div>Filing Date: {fda}</div>
        <div>
          <i>Click to see full case information</i>
        </div>
      </MainContent>
      <div>
        {!hideStatus && statusTile}
        {!hideStatus && dpsTile}
      </div>
    </Card>
  );
};

export const FormFollowupInfo = ({ caseInfo }) => {
  const formIds = getFormsNeededForFollowUp(caseInfo);

  return (
    <FormFollowup>
      {formIds.includes(FORM_ID_411_0728) && (
        <div>
          Eligible under <b>411.0728</b> if ...
          <ul>
            <li>
              Committed this offense solely as a victim of human trafficking or
              compelling prostitution
            </li>
            <li>
              Did not commit this offense after submitting a petition for
              nondisclosure for a related offense
            </li>
          </ul>
        </div>
      )}
      {formIds.includes(FORM_ID_411_0729) && (
        <div>
          Eligible under <b>411.0729</b> if ...
          <ul>
            <li>
              Applicant has completed a <b>Veteran's Reemployment Program</b>{' '}
              for this case
            </li>
          </ul>
        </div>
      )}
      {formIds.includes(FORM_ID_411_0727) && (
        <div>
          Eligible under <b>411.0727</b> if ...
          <ul>
            <li>
              Applicant has completed a <b>Veterans Treatment Court program</b>{' '}
              for this case
            </li>
          </ul>
        </div>
      )}
      {formIds.includes(FORM_ID_411_0731) && (
        <div>
          Eligible under <b>411.0731</b> if ...
          <ul>
            <li>
              Applicant's <b>blood alcohol concentration</b> (BAC) level less
              than <b>0.15</b> at the time of this offense
            </li>
            {caseInfo[FINAL_POT_ELIG_411_0731] && (
              <li>
                Applicant used an ignition interlock device on their vehicle for
                at least 6 months
              </li>
            )}
          </ul>
        </div>
      )}
      {formIds.includes(FORM_ID_411_0736) && (
        <div>
          Eligible under <b>411.0736</b> if ...
          <ul>
            <li>
              Applicant's <b>blood alcohol concentration</b> (BAC) level less
              than <b>0.15</b> at the time of this offense
            </li>
            {caseInfo[FINAL_POT_ELIG_411_0736] && (
              <li>
                Applicant used an ignition interlock device on their vehicle for
                at least 6 months
              </li>
            )}
          </ul>
        </div>
      )}
      {formIds.includes(FORM_ID_411_0726) && (
        <div>
          Eligible under <b>411.0726</b> if ...
          <ul>
            <li>
              Applicant's <b>blood alcohol concentration</b> (BAC) level less
              than <b>0.15</b> at the time of this offense
            </li>
            <li>
              Applicant did NOT hold a <b>commercial</b> driver's license or a{' '}
              <b>commercial</b> learner's permit at the time of this offense
            </li>
          </ul>
        </div>
      )}
    </FormFollowup>
  );
};
